import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {Box, Button, Typography} from "@mui/material";

export default function StripeLoginButton() {
    const [isConnected, setIsConnected] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const stripeClientId = 'ca_R2YnxPkR9Z5cBLdEXLPCeWA1eKYQUDaN'; // Replace with your Stripe client ID
    const backendUrl = `${process.env.REACT_APP_SERVER_URL}`;     // Replace with your backend URL
    const redirectUri = `${process.env.REACT_APP_SERVER_URL}/stripe/callback`; // The same URL you configured in Stripe

    // Step 1: Generate Stripe OAuth URL and Redirect the User
    const connectStripe = () => {
        const connectUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeClientId}&scope=read_write&redirect_uri=${redirectUri}`;
        window.location.href = connectUrl;
    };

    // Step 2: Handle the Redirect and Authorization Code Exchange
    useEffect(() => {
        const code = searchParams.get('code');  // Get the authorization code from the URL

        if (code) {
            // Call your backend to exchange the authorization code for the accountId using fetch
            fetch(`${backendUrl}/stripe/callback?code=${code}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to connect Stripe account');
                    }
                    return response.json();
                })
                .then((data) => {
                    const { stripeAccountId } = data;
                    console.log("Stripe Account ID:", stripeAccountId);
                    setIsConnected(true);
                    // You can also navigate the user to another page or show a success message here
                    navigate('/success-page');  // Optionally navigate after success
                })
                .catch((error) => {
                    console.error('Error connecting Stripe account:', error);
                    setErrorMessage('Failed to connect Stripe account.');
                });
        }
    }, [searchParams, navigate]);

    return (
        <Box>
            {!isConnected ? (
                <Button variant={'contained'} onClick={connectStripe} className="stripe-connect-btn">
                    Connect your Stripe account
                </Button>
            ) : (
                <Typography>Your Stripe account is successfully connected!</Typography>
            )}
            {errorMessage && <p className="error">{errorMessage}</p>}
        </Box>
    );
};
