import * as React from 'react';
import { useNavigate } from "react-router-dom";
import {Paper, Box, Typography} from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import LoginToken from '../../local_storage/LoginToken';
import ErrorMessage from "./ErrorMessage";
import '../../style/Login.css'
import logo from '../../media/Logo.png';

async function loginGoogleUser(credentials) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/login/google`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(data => data.json())
}

async function customLoginGoogleUser(credentials) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/login/google-custom`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(data => data.json()).catch(err => console.error(err))
}

export default function Login({ login_token, setLoginToken } ){
    const navigate = useNavigate();
    const { setToken, isTokenExpired } = LoginToken();
    const [loading, setLoading] = React.useState(false);
    const [error_message, setErrorMessage] = React.useState(null);


    if(login_token){
        window.location.replace("/dashboard");
    }

    const responseMessageGoogle = async (response) => {
        //setGoogleLoading(true)
        //console.log(response)
        await customLoginGoogleUser(response).then( async login_data => {
            if(!login_data?.success) {
                setLoading(false);
                return setErrorMessage("Can't continue with this email. Not registered?")
            }
            let user_data = setToken(login_data);
            setLoginToken(user_data);
            if(!isTokenExpired()) {
                navigate("/dashboard");
            }
        }).catch(err => console.error(err));
    };

    const responseMessage = async (response) => {
        await loginGoogleUser(response).then( async  login_data => {
            //console.log(login_data)
            if(!login_data?.success) {
                setLoading(false);
                return setErrorMessage( login_data?.message ? login_data?.message : "Can't continue with this email. Not registered?")
            }
            let user_data = setToken(login_data);
            //await getNecessaryData(user_data);
            setLoginToken(user_data);
            if(!isTokenExpired()) {
                navigate("/dashboard");
            }
            //console.log(login_data)
        });
    };

    const errorMessage = (error) => {
        console.error(error);
        setErrorMessage('Error on google login.')
    };

    const customGoogleLoginHandle = useGoogleLogin({
        onSuccess: tokenResponse => responseMessageGoogle(tokenResponse),
        onError: tokenResponse => errorMessage(tokenResponse),
    });

    return(
        <Box className="login-background">
            <Paper elevation={3} className="login-wrapper">
                <ErrorMessage message={error_message} />
                <a href={'/'} >
                    <img src={logo} alt={'logo'} style={{marginTop: -80, marginBottom: 20}}/>
                </a>
                <Typography className='login-text' component="h2" variant="h3" fontWeight="bold" fontSize="32px">
                    Login to Calendar
                </Typography>
                <Box sx={{width:1, alignItems:'center', display:'flex', justifyContent:'center'}} pt={3}>
                    <Box sx={{alignItems:'center', display:'flex', flexDirection:'column', gap:2}} className='login-buttons'>
                        <a href={'#'} onClick={customGoogleLoginHandle}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" fill="none" viewBox="0 0 40 41"><rect width="40" height="40" y="0.5" fill="#fff" rx="4"></rect><path fill="#4285F4" d="M31.766 20.776c0-.815-.066-1.635-.207-2.438H20.24v4.621h6.482a5.554 5.554 0 0 1-2.399 3.647v2.998h3.867c2.271-2.09 3.576-5.177 3.576-8.828Z"></path><path fill="#34A853" d="M20.24 32.5c3.237 0 5.966-1.062 7.955-2.896l-3.867-2.998c-1.076.731-2.465 1.146-4.084 1.146-3.13 0-5.784-2.112-6.737-4.952h-3.99v3.091a12.002 12.002 0 0 0 10.723 6.61Z"></path><path fill="#FBBC04" d="M13.503 22.8a7.187 7.187 0 0 1 0-4.594v-3.091H9.517a12.01 12.01 0 0 0 0 10.776l3.986-3.09Z"></path><path fill="#EA4335" d="M20.24 13.25a6.52 6.52 0 0 1 4.603 1.799l3.427-3.426A11.533 11.533 0 0 0 20.24 8.5a11.998 11.998 0 0 0-10.723 6.614l3.986 3.09c.948-2.843 3.607-4.955 6.737-4.955Z"></path><defs><path fill="#fff" d="M8 8.5h24v24H8z"></path></defs></svg>
                            <span style={{marginLeft:'5px'}}>Continue With Google</span>
                        </a>
                        <a href={`${process.env.REACT_APP_SERVER_URL}/microsoft/get_signin_url`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" fill="none" viewBox="0 0 40 41"><rect width="40" height="40" y="0.5" fill="#fff" rx="4"></rect><path fill="#FEBA08" d="M21.023 21.523H31.25V31.75H21.023z"></path><path fill="#05A6F0" d="M8.75 21.523h10.227V31.75H8.75z"></path><path fill="#80BC06" d="M21.023 9.25H31.25v10.227H21.023z"></path><path fill="#F25325" d="M8.75 9.25h10.227v10.227H8.75z"></path></svg>
                            <span style={{marginLeft:'5px'}}>Continue With Microsoft</span>
                        </a>
                    </Box>
                </Box>
            </Paper>
        </Box>

    )
}
