import {useState} from "react";
import {Box, Button} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

export default function ColorPicker({selectedColor, setSelectedColor, colors}){

    return (
        <Box sx={{display:'flex'}}>
            {
                colors
                &&
                colors.map(color => {
                    return (
                        <Box
                            sx={{
                                width: 36,
                                height: 36,
                                borderRadius: "50%",
                                background: color.color,
                                padding: 0,
                                margin: 1,
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#fff'
                            }}
                             key={color.color}
                            onClick={() => {setSelectedColor(color.id)}}
                        >
                            {(selectedColor === color.id) && <CheckIcon/>}
                        </Box>
                    )
                })
            }
        </Box>
    )
}
