import { useState } from 'react';
import jwt_decode from "jwt-decode";

export default function LoginToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('@auth');
        const userToken = JSON.parse(tokenString);
        return userToken
    };
    const getData = () => {
        const tokenString = localStorage.getItem('@data');
        const userData = JSON.parse(tokenString);
        return userData
    };

    const [token, setToken] = useState(getToken());
    const [data, setData] = useState(getData());

    const saveUserData = data => {
        localStorage.setItem('@data', JSON.stringify(data));
        setData(data);
        if(!data) return false;
        return data;
    };

    const saveToken = userToken => {
        localStorage.setItem('@auth', JSON.stringify(userToken));
        setToken(userToken.token);
        if(!userToken) return false;
        if(!userToken.success) return false;
        const decoded = jwt_decode(userToken.token);
        let {brand_image, background_image, mobile_background_image, picture, name, user_id} = decoded;
        saveUserData({brand_image, background_image, mobile_background_image, picture, name, user_id})
        return decoded;
    };

    const removeToken = () => {
        localStorage.removeItem('@auth')
        localStorage.removeItem('@data')
    }

    const isTokenExpired = () => {
        if(!token) return true;
        return token.expire < new Date().getTime();
    }

    const tokenData = () => {
        if(!token) return false;
        if(!token?.success) return false;
        return jwt_decode(token.token);
    }

    const userData = () => {
        if(!data) return false;
        return data;
    }

    return {
        setToken: saveToken,
        removeToken: removeToken,
        getToken: getToken,
        isTokenExpired: isTokenExpired,
        tokenData: tokenData,
        userData: userData,
        setUserData: saveUserData,
        token
    }
}
