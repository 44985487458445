import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import * as React from "react";

export default function TimeSelect({value, changeValue, disabled, text}){

    return (
        <FormControl sx={{width:100, marginX:1}} size='small'>
            <InputLabel id="demo-simple-select-label">{text}</InputLabel>
            <Select
                sx={{fontSize: 14}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={text}
                onChange={changeValue}
                disabled={disabled}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200, // Set your desired max height here
                        },
                    },
                }}
            >
                <MenuItem value={0}>0:00</MenuItem>
                <MenuItem value={0.5}>0:30</MenuItem>
                <MenuItem value={1}>1:00</MenuItem>
                <MenuItem value={1.5}>1:30</MenuItem>
                <MenuItem value={2}>2:00</MenuItem>
                <MenuItem value={2.5}>2:30</MenuItem>
                <MenuItem value={3}>3:00</MenuItem>
                <MenuItem value={3.5}>3:30</MenuItem>
                <MenuItem value={4}>4:00</MenuItem>
                <MenuItem value={4.5}>4:30</MenuItem>
                <MenuItem value={5}>5:00</MenuItem>
                <MenuItem value={5.5}>5:30</MenuItem>
                <MenuItem value={6}>6:00</MenuItem>
                <MenuItem value={6.5}>6:30</MenuItem>
                <MenuItem value={7}>7:00</MenuItem>
                <MenuItem value={7.5}>7:30</MenuItem>
                <MenuItem value={8}>8:00</MenuItem>
                <MenuItem value={8.5}>8:30</MenuItem>
                <MenuItem value={9}>9:00</MenuItem>
                <MenuItem value={9.5}>9:30</MenuItem>
                <MenuItem value={10}>10:00</MenuItem>
                <MenuItem value={10.5}>10:30</MenuItem>
                <MenuItem value={11}>11:00</MenuItem>
                <MenuItem value={11.5}>11:30</MenuItem>
                <MenuItem value={12}>12:00</MenuItem>
                <MenuItem value={12.5}>12:30</MenuItem>
                <MenuItem value={13}>13:00</MenuItem>
                <MenuItem value={13.5}>13:30</MenuItem>
                <MenuItem value={14}>14:00</MenuItem>
                <MenuItem value={14.5}>14:30</MenuItem>
                <MenuItem value={15}>15:00</MenuItem>
                <MenuItem value={15.5}>15:30</MenuItem>
                <MenuItem value={16}>16:00</MenuItem>
                <MenuItem value={16.5}>16:30</MenuItem>
                <MenuItem value={17}>17:00</MenuItem>
                <MenuItem value={17.5}>17:30</MenuItem>
                <MenuItem value={18}>18:00</MenuItem>
                <MenuItem value={18.5}>18:30</MenuItem>
                <MenuItem value={19}>19:00</MenuItem>
                <MenuItem value={19.5}>19:30</MenuItem>
                <MenuItem value={20}>20:00</MenuItem>
                <MenuItem value={20.5}>20:30</MenuItem>
                <MenuItem value={21}>21:00</MenuItem>
                <MenuItem value={21.5}>21:30</MenuItem>
                <MenuItem value={22}>22:00</MenuItem>
                <MenuItem value={22.5}>22:30</MenuItem>
                <MenuItem value={23}>23:00</MenuItem>
                <MenuItem value={23.5}>23:30</MenuItem>
            </Select>
        </FormControl>

    )
}
