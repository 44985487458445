import { Box, CssBaseline, List, ListItem, ThemeProvider, Typography } from "@mui/material";
import * as React from "react";
import '../../style/PrivacyPolicy.css'
import Header from "../Homepage/Header";
import Footer from "../Homepage/Footer";
import Theme from "../../Theme";

export default function PrivacyPolicy() {
    const { theme } = Theme();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box className='privacy-policy-background'>
                <Header />
                <Box className='privacy-policy-wrapper'>
                    <Box className="privacy-policy-event-types-events">
                        <Box className='privacy-policy-blur-effect' />
                        <Box className='privacy-policy-text'>
                            <Typography component='h1' variant='h1'>Privacy Policy</Typography>
                            <Typography component='h3' variant='h3'>Last Updated: 6 August 2024</Typography>
                            <Box className='privacy-policy-text-privacy-policy'>
                                <Typography component='h2' variant='h2'>1. Introduction</Typography>
                                <Typography component='p' variant='p'>Welcome to Zingley Calendar. We are committed to
                                    protecting the privacy and security of your personal information. This Privacy
                                    Policy explains how we collect, use, disclose, and safeguard your personal
                                    information when you use our mobile application or website (collectively, the
                                    "Service").</Typography>

                                <Typography component='h2' variant='h2'>2. Information We Collect</Typography>
                                <Typography component='p' variant='p'>a. Information You Provide: We may collect
                                    information that you voluntarily provide when using the Service. This includes your
                                    name, email address, and any other information you choose to provide.</Typography>
                                <Typography component='p' variant='p'>b. Automatically Collected Information: We may
                                    automatically collect certain information when you use the Service, such as your
                                    device's unique identifier, IP address, browser type, and operating
                                    system.</Typography>

                                <Typography component='h2' variant='h2'>3. How We Use Your Information</Typography>
                                <Typography component='p' variant='p'>We may use your personal information for various
                                    purposes, including:</Typography>
                                <Typography component='p' variant='p' ml={2}>a. Providing and maintaining the
                                    Service.</Typography>
                                <Typography component='p' variant='p' ml={2}>b. Personalising your
                                    experience.</Typography>
                                <Typography component='p' variant='p' ml={2}>c. Sending you updates, notifications, and
                                    marketing communications.</Typography>
                                <Typography component='p' variant='p' ml={2}>d. Analysing and improving the
                                    Service.</Typography>

                                <Typography component='h2' variant='h2'>4. Disclosure of Your Information</Typography>
                                <Typography component='p' variant='p'>We may share your personal information
                                    with:</Typography>
                                <Typography component='p' variant='p' ml={2}>a. Third-party service providers who assist
                                    us in operating the Service.</Typography>
                                <Typography component='p' variant='p' ml={2}>b. Law enforcement or government
                                    authorities when required by law.</Typography>

                                <Typography component='h2' variant='h2'>5. Security</Typography>
                                <Typography component='p' variant='p'>We take reasonable measures to protect your
                                    personal information from unauthorised access or disclosure. However, no method of
                                    data transmission or storage is entirely secure, and we cannot guarantee its
                                    absolute security.</Typography>

                                <Typography component='h2' variant='h2'>6. Your Choices</Typography>
                                <Typography component='p' variant='p'>You may have certain rights regarding your
                                    personal information. You can:</Typography>
                                <Typography component='p' variant='p' ml={2}>a. Access, correct, or delete your personal
                                    information.</Typography>
                                <Typography component='p' variant='p' ml={2}>b. Opt out of receiving marketing
                                    communications.</Typography>

                                <Typography component='h2' variant='h2'>7. Children's Privacy</Typography>
                                <Typography component='p' variant='p'>The Service is not intended for children under the
                                    age of 18.</Typography>

                                <Typography component='h2' variant='h2'>8. Changes to this Privacy Policy</Typography>
                                <Typography component='p' variant='p'>We may update this Privacy Policy from time to
                                    time. The most recent version will be posted on our website or within the app with
                                    the effective date.</Typography>

                                <Typography component='h2' variant='h2'>9. Google Services Integration</Typography>
                                <Typography component='p' variant='p'>We integrate with Google services, including
                                    Google Calendar and Google Login, to enhance the functionality of our app. When you
                                    use these services through our app, please be aware that your usage may also be
                                    subject to Google's Privacy Policy.</Typography>

                                <Typography component='h2' variant='h2'>10. Google Calendar Data Access</Typography>
                                <Typography component='p' variant='p'>When you grant our app access to your Google
                                    Calendar, we may collect and use the following data:</Typography>
                                <ul style={{ marginLeft: '2%' }}>
                                    <li>Calendar events</li>
                                    <li>Event details (e.g., title, description, date, time)</li>
                                </ul>
                                <Typography component='p' variant='p'>This data is used solely for the purpose of
                                    providing scheduling and calendar-related features within our app.</Typography>

                                <Typography component='h2' variant='h2'>11. Google Login Information</Typography>
                                <Typography component='p' variant='p'>When you log in to our app using your Google
                                    account, we may collect and use the following information:</Typography>
                                <ul style={{ marginLeft: '2%' }}>
                                    <li>Your name</li>
                                    <li>Your email address</li>
                                    <li>Profile picture (if available)</li>
                                </ul>
                                <Typography component='p' variant='p'>This information is used to personalize your
                                    experience within our app and may be used for account management and communication
                                    purposes.</Typography>

                                {/* New Section for Google API Services Disclosure */}
                                <Typography component='h2' variant='h2'>12. Google API Services Disclosure</Typography>
                                <Typography component='p' variant='p'>Zingley Calendar complies with the Google API
                                    Services User Data Policy, including Limited Use requirements. We access your Google
                                    Calendar for scheduling and conflict detection. Your data is encrypted and secure.
                                    Manage or revoke permissions in your Google account settings. For privacy questions,
                                    contact us at info@zingley.com. For more information, please refer to <a
                                        href="https://developers.google.com/terms/api-services-user-data-policy">this link</a>.</Typography>

                                <Typography component='h2' variant='h2'>13. Contact Us</Typography>
                                <Typography component='p' variant='p'>If you have any questions about this Privacy
                                    Policy, please contact us at contact@zingley.com.</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', paddingTop: "100px" }}>
                    <Box sx={{ maxWidth: 1440, width: 1, paddingY: 4 }}>
                        <Footer />
                    </Box>
                </Box>

            </Box>
        </ThemeProvider>
    )
}
