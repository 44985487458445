import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Typography
} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import ToggleLiveSandbox from "./ToggleLiveSandbox";
import LoginToken from "../../../../local_storage/LoginToken";
import {useState} from "react";
import StripeLoginButton from "./StripeLoginButton";
import Grid2 from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";

async function getCredentials(accessToken) {
    return fetch(process.env.REACT_APP_SERVER_URL + '/stripe/database', {
        method: 'GET',
        headers: { 'Authorization': accessToken },
    }).then(data => data.json());
}

export default function StripeComponent({login_token}) {
    let {getToken} = LoginToken();

    const [account, setAccount] = React.useState(1);
    const [firstRender, setFirstRender] = useState(true);
    const [stripeCredentials, setStripeCredentials] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(firstRender) {
            setLoading(true)
            getCredentials(getToken().token).then(result => {
                if(result.status) {
                    setStripeCredentials(result.data);
                    setAccounts(result.data.filter(a => a.payment_environment_id === account));
                } else {
                    setError(result?.message || "Error on getting data.")
                }
                setLoading(false);
                console.log({result});
                setFirstRender(false)
            }).catch(err => {
                setLoading(false);
                setError(err?.message || "Error on getting data.")
            })
        }
    }, [firstRender]);
    useEffect(() => {
        console.log({stripeCredentials})
        setAccounts([...stripeCredentials].filter(a => a.payment_environment_id === account));
        console.log({account});
    }, [account]);

    return (
        <Box sx={{display:'flex', flexDirection:'column', gap:2}}>
            <ToggleLiveSandbox account={account} setAccount={setAccount}/>
            {
                loading
                    ?
                    <LinearProgress/>
                    :
                    <AllPaymentCredentials array={accounts}/>
            }
            <AddStripeAccount disabled={loading} account={account}/>
        </Box>
    )
}

function AddStripeAccount({account, disabled}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleClickOpen} disabled={disabled}>
                Connect Account
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle >
                    Connect {account === 1 ? 'sandbox' : 'live'} stripe account.
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        <Box>
                            <StripeLoginButton/>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

function AllPaymentCredentials({array}) {
    if(array && array?.length > 0) {
        return (
            <Box>
                <Grid2 container sx={{borderBottom: 1, borderColor:'divider'}} pb={1} mb={1}>
                    <Grid2 xs={1}>ID</Grid2>
                    <Grid2 xs={4.5}>Created At</Grid2>
                    <Grid2 xs={4.5}>Account ID</Grid2>
                    <Grid2 xs={2}></Grid2>
                </Grid2>
                {array.map(s => <SingleStripeCredentials cred={s}/> )}
            </Box>
        )
    }
    return (
        <Box>
            <Typography>You dont have connected accounts</Typography>
        </Box>
    )
}

function SingleStripeCredentials({cred}) {

    return (
        <Grid2 container >
            <Grid2 xs={1}>{cred.stripe_credentials_id}</Grid2>
            <Grid2 xs={4.5}>{dayjs(cred.created_at).toISOString()}</Grid2>
            <Grid2 xs={4.5}>{cred.stripel_account_id}</Grid2>
            <Grid2 xs={2}></Grid2>
        </Grid2>
    )
}
