import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import TimeZoneSelect from "../UserScheduleComponents/TimeZoneSelect";
import * as React from "react";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import LoginToken from "../local_storage/LoginToken";

async function getUserData(accessToken) {
    return fetch(process.env.REACT_APP_SERVER_URL + '/microsoft/user_calendars', {
        method: 'GET',
        headers: { 'Authorization': accessToken },
    }).then(data => data.json());
}

async function checkIfUserExist(user) {
    return fetch(process.env.REACT_APP_SERVER_URL + '/microsoft/user_registered_finished', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user})
    }).then(data => data.json());
}

async function finishMicrosoftRegistration(credentials) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/microsoft/finish_registration`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(data => data.json())
}

export default function ConnectCalendars({ setReloadCalendars, setLoginToken }) {
    const [access_token, setAccess_token] = useState(null);
    const [refresh_token, setRefresh_token] = useState(null);
    const [expiry, setExpiry] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [user, setUser] = useState(null);
    const [calendars, setCalendars] = useState(null);
    const [loading, setLoading] = useState(true);
    const [firstRender, setFirstRender] = useState(true);
    const [error, setError] = useState(null);
    const [defaultCalendar, setDefaultCalendar] = useState(true);
    const [selectedTimezone, setSelectedTimezone] = useState({});
    const navigate = useNavigate();
    const { setToken, tokenData, isTokenExpired, removeToken, getToken } = LoginToken();


    useEffect(() => {
        if(firstRender) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            setAccess_token(urlParams.get('access_token'));
            setRefresh_token(urlParams.get('refresh_token'));
            setExpiry(urlParams.get('expiry'));
            setName(urlParams.get('name'));
            setEmail(urlParams.get('email'));
            getUserData(urlParams.get('access_token')).then(data => {
                if(data.state) {
                    checkIfUserExist(data.user).then(userLoggedIn => {
                        if(userLoggedIn.state) {
                            let user_data = setToken(userLoggedIn.login_data);
                            setLoginToken(user_data);
                            return navigate('/dashboard')
                            //setReloadCalendars(true);
                        }
                    })
                    setUser(data.user);
                    setCalendars(data.calendars);
                } else {
                    setError(data?.error ? data.error : 'Error on fetching user data.')
                }
                setLoading(false);
                console.log(data);
            })
            setFirstRender(false);
        }
    }, [firstRender]);

    const addCalendar = () => {
        setError(null);
        setLoading(true);
        //console.log({access_token, refresh_token, expiry, name: `${name} - ${email}`, user_id:null, defaultCalendar, timezone: (selectedTimezone && selectedTimezone?.value) ? selectedTimezone?.value : dayjs.tz.guess()})
        finishMicrosoftRegistration({access_token, refresh_token, expiry, user, name: `${name} - ${email}`, defaultCalendar, timezone: (selectedTimezone && selectedTimezone?.value) ? selectedTimezone?.value : dayjs.tz.guess()}).then(result => {
            if(result?.status){
                console.log(result);
                let user_data = setToken(result.loggedIn);
                setLoginToken(user_data);
                return navigate('/dashboard')
            } else {
                setError(result?.error ? result.error : 'Error on fetching user data.')
            }
            setLoading(false);
        })
    }

    if(loading) {
        return (
            <Box sx={{width:1, height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <CircularProgress/>
            </Box>
        )
    }

    if(error) {
        return (
            <Typography color={'error'}>{error}</Typography>
        )
    }

    return (
        <Box sx={{display:"flex", justifyContent:'center', alignItems:'center', height:"100vh", backgroundColor:"#414A4C"}}>
            <Box sx={{width:400}}>
                <Box sx={{textAlign:"start", color:'#FFF'}}>
                    <Typography component="h2" variant="h5" p={2}>Finish Registration</Typography>
                    <Typography px={2}>Name: {name}</Typography>
                    <Typography px={2}>Email: {email}</Typography>
                    <Typography px={1}><Checkbox disabled={true} sx={{color:'#FFF'}} checked={defaultCalendar} value={defaultCalendar} onChange={() => {setDefaultCalendar(!defaultCalendar)}}/> Save events to this calendar </Typography>
                    <TimeZoneSelect selectedTimezone={selectedTimezone} setSelectedTimezone={setSelectedTimezone} changedTimezone={() => {}}/>
                </Box>

                <Box sx={{display:"flex", alignItems:"center", justifyContent:"start", marginTop:5, marginLeft:2}}>
                    <Button variant='outlined' onClick={addCalendar} sx={{width:160, marginRight:1, color:"#FFF", borderColor:'#FFF'}}>Add calendar</Button>
                </Box>
            </Box>

        </Box>
        /*<Box>
            <Typography>{user.displayName}</Typography>
        </Box>*/
    )
}
