import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import {Box, CircularProgress, Typography} from "@mui/material";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

const UserAvatar = ({src, name, changeImage, waiting, width, height}) => {

    const [image, setImage] = useState(src);

    const [showChangeImage, setShowChangeImage] = useState(false);

    useEffect(() => {
        setImage(src);
    }, [src]);

    const handleImageChange = async (event) => {
        const file = event.target.files[0];

        // Check file size
        if (file.size > 1 * 1024 * 1024) {
            alert('Oops! It looks like the image you\'re trying to upload is too large. Please choose a file that is smaller than 1MB and try again.');
            return;
        }

        // Check file type
        if (!/\.jpg|\.jpeg|\.png/i.test(file.name)) {
            alert('Sorry, we only accept files in .jpg, .jpeg, or .png format. Please choose a valid image file and try again.');
            return;
        }

        // Convert file to Data URI format
        const dataUri = await convertToDataUri(file);

        const formData = new FormData();
        formData.append('avatar', dataUri);
        const fileType = getFileType(file.name);

        try {
            const response = await changeImage(dataUri, fileType);
            if (response.state && response.status === 200) {
                setImage(response.src);
            } else {
                alert(`Error: ${response.error}`);
            }
        } catch (error) {
            alert('An error occurred while updating the image.');
        }
    };

    function trimAll(str) {
        return str.replace(/\s+/g, '');
    }

    const convertToDataUri = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result.split(',')[1]); // Extract the base64 data part
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png'].includes(extension)) {
            return extension;
        }
        return null;
    };

    return (
        <Box sx={{width, height}}>
            <input
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handleImageChange}
                style={{display: 'none'}}
                id={"avatar-input" + name}
            />
            <label htmlFor={"avatar-input" + name} className="module-edit-profile-image-label">
                <Box sx={{position: 'relative', width, height}} onMouseEnter={() => setShowChangeImage(true)} onMouseLeave={() => setShowChangeImage(false)}>
                    <Avatar src={image} style={{width, height, objectFit:'cover'}} alt={name}/>
                    <WaitingOverlay show={waiting}/>
                    <AvatarOverlay text='Update' show={!image}/>
                    <AvatarOverlay text='Change' show={image && showChangeImage}/>
                </Box>
            </label>
        </Box>
    );
};

function WaitingOverlay({show}){
    if(show){
        return(
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: 1,
                    height: 1,
                    zIndex: 1,
                    borderRadius: '50%',
                    border: '10px solid rgba(4, 80, 249, 0.5)',
                    clipPath: 'polygon(100% 100%, 100% 0%, 100% 0%, 0% 0%)',
                    animation: 'rotate 1s linear infinite',
                    '@keyframes rotate': {
                        from: { transform: 'rotate(0deg)' },
                        to: { transform: 'rotate(360deg)' },
                    },
                }}
            />

        )
    }
}

function AvatarOverlay({text, show}) {
    if(show) {
        return (
            <Box sx={{
                background: 'rgba(3,38,83,0.72)',
                color: '#FFF',
                width: 1,
                height: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: "center",
                flexDirection: 'column',
                position: 'absolute',
                top: 0,
                borderRadius: '50%',
                zIndex:1
            }}>
                <CameraAltOutlinedIcon fontSize={'small'}/>
                <Typography sx={{fontSize:12}}>{text}</Typography>
            </Box>
        )
    }
}

export default UserAvatar;
