import {Box, Typography} from "@mui/material";
import * as React from "react";
import '../style/WrongUrl.css'

export default function WrongUrl(){


    return (
        <Box className='wrapper'>
                <Box className="wrong-url">
                    {/*<Box className='circle-1'/>
                    <Box className='circle-2'/>
                    <Box className='circle-3'/>*/}
                    <Box className='blur-effect'/>
                        <Box className='header' height={550}  width={1} sx={{alignItems:'center'}}>
                            <Typography id='wrong-url-header' component="h1" variant='h1'>404</Typography>
                            <Typography id='wrong-url-text' component="h1" variant='h3'>Ooops, page not found</Typography>
                        </Box>
                </Box>

        </Box>
    )
}
