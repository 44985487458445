import {Avatar, AvatarGroup, Box, Button, LinearProgress, TextField, Tooltip, Typography} from "@mui/material";
import * as React from "react";
import UserImage from "./Media/UserImage";
import {useEffect, useState} from "react";
import LoginToken from "../../../local_storage/LoginToken";
import preview_only from "../../../media/preview_only.png";
import defaultBackground from '../../../media/NewZingleyBG-min.jpg'
import defaultMobileBackground from '../../../media/ZingleyNewBG_Mob-min.jpg'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

function getGroupImages(group_id, user_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/group/get_images/${group_id}?user_id=${user_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json()).catch(err => console.error(err))
}

function changeImage(media, media_name_type, user_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/media/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({media, media_name_type, user_id})
    }).then(data => data.json()).catch(err => console.error(err))
}

function addImage(media, media_name_type, user_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/media/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({media, media_name_type, user_id})
    }).then(data => data.json()).catch(err => console.error(err))
}

function saveGroupImage(token, column, src, group_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/group/images/${group_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, column, src})
    }).then(data => data.json()).catch(err => console.error(err))
}

function removeGroupImages(token, group_id, media_prefix) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/group/images/${group_id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, media_prefix})
    }).then(data => data.json()).catch(err => console.error(err))
}

function updateName(token, name, group_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/group/name/${group_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, name})
    }).then(data => data.json()).catch(err => console.error(err))
}

function checkIfIsLocalImage(src, user_id) {
    if(!src) return false;
    const parts = src.split('/');
    return parts[parts.length - 2] === user_id.toString() && parts[parts.length - 3] === 'media'
}

export default function ManageGroupImages ({eventGroup, isMobile}){
    const { userData, setUserData, getToken } = LoginToken();

    const [firstRender, setFirstRender] = useState(true);
    const [avatarSrc, setAvatarSrc] = useState(null);
    const [name, setName] = useState(null);
    const [brandSrc, setBrandSrc] = useState(null);
    const [backgroundSrc, setBackgroundSrc] = useState(null);
    const [mobileBackgroundSrc, setMobileBackgroundSrc] = useState(null);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [avatarIsLocalImage, setAvatarIsLocalImage] = useState(false);
    const [brandIsLocalImage, setBrandIsLocalImage] = useState(false);
    const [backgroundIsLocalImage, setBackgroundIsLocalImage] = useState(false);
    const [mobileBackgroundIsLocalImage, setMobileBackgroundIsLocalImage] = useState(false);
    const [changeAvatarImageWaiting, setChangeAvatarImageWaiting] = useState(false);
    const [changeBrandImageWaiting, setChangeBrandImageWaiting] = useState(false);
    const [changeBackgroundImageWaiting, setChangeBackgroundImageWaiting] = useState(false);
    const [changeMobileBackgroundImageWaiting, setChangeMobileBackgroundImageWaiting] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [multipleUsers, setMultipleUsers] = useState(eventGroup?.with_other_users);

    const [width, setWidth] = useState("100%");
    const [height, setHeight] = useState("70vh");

    const [mobilePreview, setMobilePreview] = useState(false);

    useEffect(() => {
        setMultipleUsers(eventGroup?.with_other_users);
        setLoading(true);
        setFirstRender(true);
    }, [eventGroup]);


    useEffect(() => {
        if(firstRender) {

            getGroupImages(eventGroup.id, userId).then(result => {
                if(!!(userData())){
                    let {brand_image, background_image, mobile_background_image, picture, name, user_id} = userData();
                    let {event_brand_image, event_background_image, event_mobile_background_image, is_admin} = result;
                    setIsAdmin(is_admin);
                    setAvatarSrc(picture);
                    setBrandSrc(event_brand_image ? event_brand_image : brand_image);
                    setName(multipleUsers ? eventGroup.name : name);
                    setBackgroundSrc(event_background_image ? event_background_image : background_image ? background_image : defaultBackground);
                    setMobileBackgroundSrc(event_mobile_background_image ? event_mobile_background_image : mobile_background_image ? mobile_background_image : defaultMobileBackground);
                    setUserId(user_id);
                    setAvatarIsLocalImage(checkIfIsLocalImage(picture, user_id));
                    setBrandIsLocalImage(checkIfIsLocalImage(event_brand_image, user_id));
                    setBackgroundIsLocalImage(checkIfIsLocalImage(event_background_image, user_id));
                    setMobileBackgroundIsLocalImage(checkIfIsLocalImage(event_mobile_background_image, user_id));
                    setLoading(false)
                }
            })

            setFirstRender(false)
        }
    }, [firstRender]);


    const changeMedia = (media, media_name_type, replace) => {
        media_name_type = media_name_type.replace(/\s+/g, '').replace(/_/g, '');
        if(replace) {
            return changeImage(media, media_name_type, userId).then(response => {
                return response;
            })
        } else {
            return addImage(media, media_name_type, userId).then(response => {
                return response;
            })
        }
    }

    const changeBrandImage = (media, media_name_type) => {
        setChangeBrandImageWaiting(true);
        return changeMedia(media, `${eventGroup.id}_${eventGroup.name}_brand_image.${media_name_type}`, brandIsLocalImage).then(result => {
            if(result.state) {
                return saveGroupImage(getToken().token, 'event_brand_image', result.src, eventGroup.id).then(updated => {
                    if(updated.status) {
                        setBrandSrc(result.src);
                        setBrandIsLocalImage(true);
                        setChangeBrandImageWaiting(false);
                        //userData().brand_image = result.src;
                        setUserData(userData());
                        return result;
                    } else {
                        result.state = false;
                        result.error = "Image is not saved."
                        return result;
                    }
                })
            } else {
                result.state = false;
                return result;
            }
        })
    }

    const changeBackgroundImage = (media, media_name_type) => {
        setChangeBackgroundImageWaiting(true);
        return changeMedia(media, `${eventGroup.id}_${eventGroup.name}_background_image.${media_name_type}`, backgroundIsLocalImage).then(result => {
            if(result.state) {
                return saveGroupImage(getToken().token, 'event_background_image', result.src, eventGroup.id).then(updated => {
                    if(updated.status) {
                        setBackgroundSrc(result.src);
                        setBackgroundIsLocalImage(true);
                        setChangeBackgroundImageWaiting(false);
                        //userData().background_image = result.src;
                        setUserData(userData());
                        return result;
                    } else {
                        result.state = false;
                        result.error = "Image is not saved."
                        return result;
                    }
                })
            } else {
                result.state = false;
                return result;
            }
        })
    }

    const changeMobileBackgroundImage = (media, media_name_type) => {
        setChangeMobileBackgroundImageWaiting(true);
        return changeMedia(media, `${eventGroup.id}_${eventGroup.name}_mobile_background_image.${media_name_type}`, mobileBackgroundIsLocalImage).then(result => {
            if(result.state) {
                return saveGroupImage(getToken().token, 'event_mobile_background_image', result.src, eventGroup.id).then(updated => {
                    if(updated.status) {
                        setMobileBackgroundSrc(result.src);
                        setMobileBackgroundIsLocalImage(true);
                        setChangeMobileBackgroundImageWaiting(false);
                        //userData().mobile_background_image = result.src;
                        setUserData(userData());
                        return result;
                    } else {
                        result.state = false;
                        result.error = "Image is not saved."
                        return result;
                    }
                })
            } else {
                result.state = false;
                return result;
            }
        })
    }

    const resetToDefault = () => {
        removeGroupImages(getToken().token, eventGroup.id, `${eventGroup.id}_${eventGroup.name}_`).then(result => {
            setLoading(true);
            setFirstRender(true);
        })
    }

    const toggleMobilePreview = () => {
        if(mobilePreview) {
            setWidth("100%");
            setHeight("70vh");
        } else {
            setWidth('375px');
            setHeight('667px')
        }
        setMobilePreview(!mobilePreview);
    }

    const handleUpdateName = (newName) => {
        return updateName(getToken().token, newName, eventGroup.id).then(updated => {
            if(updated.status) {
                setName(newName);
                eventGroup.name = newName;
                return true;
            } else {
                return false;
            }
        })

    }

    if(loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width:isMobile ? 1 : 0.60, height:'70vh'}}>
                <Box sx={{width:0.8, height:0.8, background: "#FFF", borderRadius: 2, position: 'relative', filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))'}}>
                    <LinearProgress/>
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection:'column', width:isMobile ? 1: 0.60}}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', width:1}}>
                <Typography sx={{color:"#131313", fontWeight:600}}>{isAdmin ? 'Event group personalization:' : 'Event group preview:'}</Typography>
                <ToggleButton mobilePreview={mobilePreview} toggleMobilePreview={toggleMobilePreview}/>
                <Button size={'small'} variant={'outlined'} color={'error'} onClick={resetToDefault} disabled={!isAdmin}>Reset to default</Button>
            </Box>


            <Box sx={{width, height, borderRadius: 2, position: 'relative', filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))', background: `url(${mobilePreview ? mobileBackgroundSrc : backgroundSrc})`, backgroundSize:'cover', backgroundPosition:'center center', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Box className="event-types-calendar" id='scroll-holder' sx={{width: mobilePreview ? '90% !important' : '800px !important', margin:5}}>
                    <Box className='blur-effect'/>
                    <Box className='header'>
                        <UserImage src={brandSrc} name={'brand image'} waiting={changeBrandImageWaiting} changeImage={changeBrandImage} width={'auto'} height={50} disableChanging={!isAdmin}/>
                        <Box sx={{display:'flex', zIndex:9999, justifyContent:'space-between', alignItems:'center', margin:'40px 40px 0 40px', background:'rgba(0, 0, 0, 0.2)', backdropFilter:'blur(22px)', borderRadius:'30px'}}>
                            {
                                multipleUsers ?
                                    <AvatarGroup spacing={"small"} sx={{marginBlock:mobilePreview ? 0 : -1}} max={ mobilePreview ? 3 : 10} >
                                        <Tooltip title={name}>
                                            <Avatar src={avatarSrc} name={name}  sx={{width:mobilePreview ? 40 : 60, height:mobilePreview ? 40 : 60, zIndex:eventGroup?.users_calendar?.length}}/>
                                        </Tooltip>
                                        {
                                            eventGroup?.users_calendar?.map((user, index) =>
                                                <Tooltip title={user.name} key={user.user_id}>
                                                    <Avatar src={user.picture} name={user.name} sx={{width:mobilePreview ? 40 : 60, height:mobilePreview ? 40 : 60, zIndex:eventGroup.users_calendar.length-index}}/>
                                                </Tooltip>
                                            )
                                        }
                                    </AvatarGroup>
                                    :
                                    <Avatar src={avatarSrc} name={name} sx={{width:60, height:60}}/>
                            }
                            <NameComponent mobilePreview={mobilePreview} name={name} multipleUsers={multipleUsers} handleUpdateName={handleUpdateName}/>

                        </Box>

                    </Box>
                </Box>

                {
                    isAdmin &&
                    (
                        !mobilePreview ?
                        <Box sx={{position: 'absolute', top: 10, right: 15, border: '1px solid #FFF'}}>
                            <UserImage src={backgroundSrc} name={'background image'}
                                       waiting={changeBackgroundImageWaiting} changeImage={changeBackgroundImage}
                                       width={190} height={100} alwaysShowOverlay={true} disableChanging={!isAdmin}/>
                        </Box>
                        :
                        <Box sx={{position: 'absolute', top: 10, right: 10, border: '1px solid #FFF'}}>
                            <UserImage src={mobileBackgroundSrc} name={'mobile background image'}
                                       waiting={changeMobileBackgroundImageWaiting}
                                       changeImage={changeMobileBackgroundImage} width={80} height={120}
                                       alwaysShowOverlay={true} disableChanging={!isAdmin}/>
                        </Box>
                    )
                }
                {
                    !isAdmin &&
                    <img src={preview_only} alt={'preview only image'} style={{position:'absolute', top:0, opacity:"50%"}}/>
                }
            </Box>
        </Box>
    )
}
function NameComponent({name, mobilePreview, multipleUsers, handleUpdateName}) {
    const [edit, setEdit] = useState(false);
    const [textValue, setTextValue] = useState(name);

    const toggleEdit = () => {
        setEdit(!edit);
    }

    const updateName = () => {
        let result = handleUpdateName(textValue);
        if(result) {
            console.log({name})
            toggleEdit();
        }
    }

    if(edit) {
        return (
            <Box sx={{position:'relative'}}>
                <TextField
                    size='small' variant="standard"
                    inputProps={{ style: { color: "#FFF", fontSize:mobilePreview ? 16 : 22} }}
                    value={textValue} onChange={(e) => setTextValue(e.target.value)}
                    sx={{marginLeft: 2, marginRight: 8, width: mobilePreview ? 140 : 240}}
                />
                <CheckIcon sx={{position:'absolute', right: 32, top:mobilePreview ? 4 : 8, color: "#FFF", cursor:'pointer'}} onClick={updateName}/>
                <ClearIcon sx={{position:'absolute', right: 8, top:mobilePreview ? 4 : 8, color: "#FFF", cursor:'pointer'}} onClick={toggleEdit}/>
            </Box>
        )
    }
    return (
        <Box sx={{position:'relative'}}>
            <Typography sx={{color:'#FFF', fontSize:mobilePreview ? 18 : 24, fontFamily:'Poppins', zIndex:1000, width: mobilePreview ? 180 : 1, textAlign:'center'}} pl={2} pr={5}>{name}</Typography>
            {
                multipleUsers &&
                <DriveFileRenameOutlineIcon sx={{position:'absolute', right: 8, top:mobilePreview ? 4 : 8, color: "#FFF", cursor:'pointer'}} onClick={toggleEdit}/>
            }
        </Box>
    )

}

function ToggleButton ({mobilePreview, toggleMobilePreview}) {
    return (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:0.5, filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))', background:'#FFF', borderRadius:'20px', height:40, marginTop:2, marginBottom:2, cursor:'pointer'}} onClick={toggleMobilePreview}>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center',width:150, height:1, borderRadius:'18px', background: mobilePreview ? '#FFF' : 'green'}}>
                <Typography sx={{color: !mobilePreview ? '#FFF' : '#333', fontWeight:!mobilePreview ? 600 : 400}}>Desktop</Typography>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center',width:150, height:1, borderRadius:'18px', background: !mobilePreview ? '#FFF' : 'green'}}>
                <Typography sx={{color: mobilePreview ? '#FFF' : '#333', fontWeight:mobilePreview ? 600 : 400}}>Mobile</Typography>
            </Box>
        </Box>
    )
}
