import {Box, Button, MenuItem, Select, Switch, Typography, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import LoginToken from "../../../local_storage/LoginToken";
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TimeSelect from "./WorkingDays/TimeSelect";
import LoadingComponent from "../../../UserScheduleComponents/LoadingComponent";
import WorkingDaysForGroup from "./WorkingDays/WorkingDaysForGroup";
import ManageGroupImages from "./ManageGroupImages";
import {createTheme} from "@mui/material/styles";

async function getWorkingNonWorkingDays(token) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/users/working_days`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token})
    }).then(data => data.json())
}

export default function WorkingDaysNew( {eventGroup, allGroups, editEventGroup} ) {
    const { getToken, tokenData } = LoginToken();

    const [firstRender, setFirstRender] = useState(true);
    const [workingDays, setWorkingDays] = useState(null);
    const [workingDaysByGroup, setWorkingDaysByGroup] = useState(null);
    const [selectedWorkingDays, setSelectedWorkingDays] = useState(eventGroup);
    const [rerender, setRerender] = useState(false);
    const theme = createTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if(firstRender){
            getWorkingNonWorkingDays(getToken().token).then(days => {
                let {working_days} = days;
                setWorkingDays(working_days);
                const getUniqueValues = (data, key) => Array.from(new Set(array.map(({ [key]: value }) => value))), array = [...working_days];
                let uniqueEventGroupsId = getUniqueValues(array, 'event_group_id');
                let eventGroups = [];
                //console.log({uniqueEventGroupsId});
                uniqueEventGroupsId.forEach(uniqueEventGroupId => {
                    let foundWorkingDays = working_days.filter(wd => wd.event_group_id === uniqueEventGroupId);
                    if(foundWorkingDays && foundWorkingDays.length > 0) {
                        eventGroups.push({
                            id: uniqueEventGroupId,
                            name: foundWorkingDays[0].group_name,
                            with_other_users: foundWorkingDays[0]?.with_other_users === 1,
                            users_calendar: allGroups.find(ag => ag.group_id === uniqueEventGroupId)?.users_calendar,
                            working_days: foundWorkingDays.map(found => {
                                return {
                                    day: found.day,
                                    end_time: found.end_time,
                                    event_group_id: found.event_group_id,
                                    start_time: found.start_time,
                                    user_id: found.user_id,
                                    user_working_day_id: found.user_working_day_id,
                                    hours_before: found.hours_before,
                                }
                            })
                        });
                    }
                })
                setWorkingDaysByGroup(eventGroups);
                //setSelectedWorkingDays(eventGroups[0]);
                setSelectedWorkingDays(eventGroup ? eventGroups.find(eg => eg.id === eventGroup.group_id) : eventGroups[0]);
            })
            setFirstRender(false)
        }
    });

    const changeSelectedWorkingDays = (e) => {
        setSelectedWorkingDays(e);
        setRerender(true)
    }

    if(!workingDaysByGroup){
        return (
            <LoadingComponent/>
        )
    } else {
        return (
        <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', justifyContent: 'start', alignItems: 'center', gap:0, paddingX:3, maxWidth:1, overflowX:'auto'}}>
                <Typography sx={{fontWeight:600, borderRight:1, borderColor:'#999'}} pr={2} py={0.5}>Event Groups:</Typography>
                {
                    selectedWorkingDays && workingDaysByGroup.map(working_day => {
                        return (
                            <Box key={working_day.id} py={0.5} px={2}
                                sx={{borderRight: 1, borderTop:selectedWorkingDays?.id === working_day.id ? 2 : 1, borderColor: selectedWorkingDays?.id === working_day.id ? '#2e7d32' : '#999', display: 'flex', justifyContent: 'center', alignItems: 'center',cursor:'pointer'}}
                                onClick={() => changeSelectedWorkingDays(working_day)}
                            >
                                <Typography sx={{color: selectedWorkingDays?.id === working_day.id ? '#2e7d32' : '#999'}}>{working_day.name}</Typography>
                                <Typography ml={1} sx={{fontSize:12}}> {working_day.with_other_users && "(Multiple Users)"}</Typography>
                            </Box>
                        )
                    })
                }
            </Box>
            <Box mt={2} sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'start', flexDirection:isMobile ? 'column' : 'row', gap:1}}>
                <WorkingDaysForGroup days={selectedWorkingDays} rerender={rerender} setRerender={setRerender} isMobile={isMobile}/>
                <ManageGroupImages eventGroup={selectedWorkingDays} isMobile={isMobile} setRerenderParent={setFirstRender}/>
            </Box>

        </Box>

        )
    }


}
