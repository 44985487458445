import {
    Box,
    Radio,
    FormControl,
    FormControlLabel,
    Grid,
    RadioGroup,
    TextField,
    Typography, InputLabel, NativeSelect
} from "@mui/material";
import {useEffect, useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SuperAdminAddAllowedEmail from "./SuperAdminAddAllowedEmail";
import CloseIcon from '@mui/icons-material/Close';
import SuperAdminDeleteAlertDialog from "./SuperAdminDeleteAlertDialog";
import LoginToken from "../../../../local_storage/LoginToken";



async function updateUser(allowed_email_id, email, is_admin, added_by, organization_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/allowed_emails`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({allowed_email_id, email, is_admin, added_by, organization_id})
    }).then(data => data.json())
}

async function getAllOrganizations(accessToken) {
    return fetch(process.env.REACT_APP_SERVER_URL + '/organization', {
        method: 'GET',
        headers: { 'Authorization': accessToken },
    }).then(data => data.json());
}

export default function SuperAdminAllowedEmails({allowedEmails, setAllowedEmails, userEmail, organization, role, rerender}) {

    const [firstRender, setFirstRender] = useState(true);
    let {getToken} = LoginToken();
    const [allOrganizations, setAllOrganizations] = useState([]);

    useEffect(() => {
        if(firstRender) {
            getAllOrganizations(getToken()?.token).then(result => {
                if(result.status) {
                    setAllOrganizations(result.organizations);
                }
            })
            setFirstRender(false);
        }
    }, [firstRender]);
    return (
        <Box>

            <Grid container spacing={2} mb={2}>
                <Grid item xs={0.5} >ID</Grid>
                <Grid item xs={3.5} >User email</Grid>
                <Grid item xs={1} >Admin</Grid>
                <Grid item xs={3.5} >Added by user</Grid>
                <Grid item xs={1.5} >Registered</Grid>
                <Grid item xs={2} >Organization</Grid>
            </Grid>
            <Box sx={{overflow: 'auto', height:460}}>
            {
                allowedEmails.map((item, index) =>
                    <SingleAllowedEmail
                        item={item} key={index} setAllowedEmails={setAllowedEmails} allowedEmails={allowedEmails}
                        allOrganizations={allOrganizations} rerender={rerender} index={index}
                    />
                )
            }
            <Box>
                <SuperAdminAddAllowedEmail
                    userEmail={userEmail} setAllowedEmails={setAllowedEmails} allowedEmails={allowedEmails}
                    organization={organization} allOrganizations={allOrganizations} setAllOrganizations={setAllOrganizations}
                    rerender={rerender}
                />
            </Box>
            </Box>
        </Box>
    )
}

function SingleAllowedEmail({item, allowedEmails, setAllowedEmails, allOrganizations, setAllOrganizations, rerender, index}) {

    const [id, setId] = useState(item.allowed_email_id);
    const [email, setEmail] = useState(item.email);
    const [isAdmin, setIsAdmin] = useState(item.is_admin === 1);
    const [addedBy, setAddedBy] = useState(item.added_by);
    const [organizationId, setOrganizationId] = useState(item.organization_id);
    const [registered, setRegistered] = useState(item.registered === 1);
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setId(item.allowed_email_id);
        setEmail(item.email);
        setIsAdmin(item.is_admin === 1);
        setAddedBy(item.added_by);
        setRegistered(item.registered === 1);
        setOrganizationId(item.organization_id);
    }, [item]);

    useEffect(() => {
         if(update) {
             updateUser(id, email, isAdmin, addedBy, organizationId).then(result => {
                 if(result.state) {
                     item.email = email;
                     item.is_admin = isAdmin ? 1 : 0;
                     item.organization_id = organizationId;
                     setError(null);
                 } else {
                     setError(result.error);
                 }
             }).catch(() => {
                 setError("Error on email update");
             })
             setUpdate(false);

        }

    }, [update]);

    const handleChangeOrganization = async (e) => {
        setOrganizationId(e.target.value);
        await setUpdate(true);
        rerender(true);
    }

    return (
        <Grid container spacing={0} my={1} py={0.5} sx={{fontSize:14, position:'relative', background: index % 2 === 0 ? 'rgba(0,0,0,0.1)' : 'none'}}>
            {
                error && <Grid item xs={12} sx={{fontSize:10, color:'red'}}>{error}</Grid>
            }

            <Grid item xs={0.5} pl={1} my={'auto'}>{id}</Grid>
            <Grid item xs={3.5} px={1} my={'auto'}><Email email={email} disable={registered} setEmail={setEmail} setUpdate={setUpdate}/> </Grid>
            <Grid item xs={1} px={1} my={'auto'}><IsAdmin isAdmin={isAdmin} setIsAdmin={setIsAdmin} setUpdate={setUpdate}/></Grid>
            <Grid item xs={3.5} pl={1} my={'auto'}>{addedBy ? addedBy : 'Not defined'}</Grid>
            <Grid item xs={1.5} pl={2} my={'auto'}>{registered ? 'true' : 'false'}</Grid>
            <Grid item xs={1.5} pl={2} my={'auto'}><OrganizationSelect allOrganizations={allOrganizations} setAllOrganizations={setAllOrganizations} organization_id={organizationId} handleChange={handleChangeOrganization}/></Grid>
            <Grid item xs={0.5} my={'auto'} >{!registered && <SuperAdminDeleteAlertDialog userToRemove={item} setAllowedEmails={setAllowedEmails} allowedEmails={allowedEmails} rerender={rerender}/>}</Grid>
            {/*{
                !registered && <SuperAdminDeleteAlertDialog userToRemove={item} setAllowedEmails={setAllowedEmails} allowedEmails={allowedEmails} rerender={rerender}/>
            }*/}

        </Grid>
    )
}


function OrganizationSelect({ allOrganizations, organization_id, handleChange }) {
    return (
        <Box sx={{ minWidth: 80 }}>
            <FormControl fullWidth size={'small'} onChange={handleChange}>
                <NativeSelect
                    value={organization_id}
                    inputProps={{
                        name: 'organization',
                        id: 'uncontrolled-native',
                    }}
                    sx={{ fontSize: '12px' }}
                >
                    {
                        allOrganizations.map(org => (
                            <option key={org.organization_id} value={org.organization_id}>
                                {org.organization_name}
                            </option>
                        ))
                    }
                </NativeSelect>
            </FormControl>
        </Box>
    );
}


function Email({email, setEmail, disable, setUpdate}) {

    const [edit, setEdit] = useState(false);
    const [textValue, setTextValue] = useState(email);

    const resetText = () => {
        setEdit(!edit);
        setTextValue(email);
    }

    const saveEmail = () => {
        setUpdate(true);
        setEmail(textValue);
        setEdit(!edit);
    }

    return (
        <Box sx={{display:'flex', position:'relative', alignItems:'center'}}>
            {
                edit ?
                    <TextField size='small' variant="standard" sx={{fontSize:14, padding:0}} value={textValue} onChange={(e) => setTextValue(e.target.value)}/>
                    :
                    <Typography sx={{fontSize:14}}>{email}</Typography>
            }

            {
                !disable ?
                    !edit ?
                    <EditIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                        :
                    <Box sx={{display: 'flex', alignItems:'center'}}>
                        <CheckIcon onClick={saveEmail} sx={{fontSize:14, position: 'absolute', right:20, cursor:'pointer'}}/>
                        <ClearIcon onClick={resetText} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                    </Box>
                    :
                    ""
            }

        </Box>
    )
}

function IsAdmin({isAdmin, setIsAdmin, setUpdate}) {

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(isAdmin ? 'admin' : 'user');

    const saveIsAdmin = (event) => {
        setValue(event.target.value);
        setIsAdmin(event.target.value === 'admin');
        setUpdate(true);
        setEdit(!edit);
    }

    return (
        <Box sx={{display:'flex', position:'relative', alignItems:'center'}}>
            {
                edit ?
                    <FormControl sx={{boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)', paddingX:1, borderRadius:2, background:'#FFF'}}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={saveIsAdmin}
                        >
                            <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontSize: '12px', marginLeft:-1 } }} value="admin" control={<Radio size="small" />} label="Admin"/>
                            <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontSize: '12px', marginLeft:-1 } }} value="user" control={<Radio size="small"/>} label="User"/>
                        </RadioGroup>
                    </FormControl>
                    :
                    <Typography sx={{fontSize:14}}>{isAdmin ? 'true' : 'false'}</Typography>
            }

            {
                (!edit)
                    ?
                    <EditIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                    :
                    <CloseIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:1, top:1, cursor:'pointer'}}/>
            }

        </Box>
    )
}

