import {Box, Button} from "@mui/material";
import {useEffect, useState} from "react";
import LoginToken from "../../../local_storage/LoginToken";
import LoadingComponent from "../../../UserScheduleComponents/LoadingComponent";
import EditCalendarComponent from "./ManageCalendars/EditCalendarComponent";
import * as React from "react";


async function getUserCalendars(token) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/microsoft_get_all?login_token=${token}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json())
}

async function removeUserCalendars(login_token, calendar_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({login_token, calendar_id})
    }).then(data => data.json())
}


async function setDefaultCalendar(user_id, calendar_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/set_default`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user_id, calendar_id})
    }).then(data => data.json())
}

async function changeAutoAcceptCalendar(user_id, user_calendar_id, auto_accept) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/change_auto_accept`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user_id, user_calendar_id, auto_accept})
    }).then(data => data.json())
}

async function changeCalendarsToUseCalendar(user_id, user_calendar_id, calendars_to_use) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/change_calendars_to_use`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user_id, user_calendar_id, calendars_to_use})
    }).then(data => data.json())
}

export default function ManageMicrosoftCalendars ({logoutUser}){
    const { getToken, tokenData } = LoginToken();

    const [firstRender, setFirstRender] = useState(true);
    const [calendars, setCalendars] = useState(null);
    const [refresh, setRefresh] = useState();
    const [defaultChanged, setDefaultChanged] = useState(false);
    const [autoAcceptChanged, setAutoAcceptChanged] = useState(false);
    const [dbDefault, setDbDefault] = useState(null);

    useEffect(() => {
        if(firstRender){
            getUserCalendars(getToken().token).then(db_calendars => {
                console.log({db_calendars});
                db_calendars.forEach(db_calendar => {
                    db_calendar.default = db_calendar.default === 1;
                    db_calendar.auto_accept = db_calendar.auto_accept === 1;
                    if(db_calendar.default){
                        setDbDefault(db_calendar.calendar_id);
                    }
                })
                setCalendars(db_calendars);
            })
            setFirstRender(false);
        }
    });

    const deleteCalendar = (calendar_id) => {
        console.log("delete calendar", calendar_id);
        removeUserCalendars(getToken().token, calendar_id).then(data => {
            console.log(data);
            setFirstRender(true)
        })
    }

    const changedDefault = (calendar_id) => {
        console.log('changed default', calendar_id);
        calendars.forEach(calendar => {
            calendar.default = calendar.calendar_id === calendar_id;
        })
        setCalendars(calendars);
        setRefresh(true);
        console.log(dbDefault, calendar_id)
        if(calendar_id !== dbDefault){
            setDefaultChanged(calendar_id);
        } else {
            setDefaultChanged(null)
        }
        //setDefaultChanged(calendar_id)
    }

    const changedAutoAccept = (calendar_id) => {
        console.log('changed auto accept', calendar_id);
        let calendar_to_change = null;
        calendars.forEach(calendar => {
            if(calendar.calendar_id === calendar_id) {
                calendar.auto_accept = !calendar.auto_accept;
                calendar_to_change = calendar;
            }
        });
        changeAutoAcceptCalendar(calendar_to_change.user_id, calendar_to_change.user_calendar_id, calendar_to_change.auto_accept).then(saved => {
            console.log(saved);
            setCalendars(calendars);
            setRefresh(true);
            setAutoAcceptChanged(true);
        })

    }

    const changeCalendarsToUse = (calendar_id) => {
        console.log('changed changeCalendarsToUse', calendar_id);
        let calendar_to_change = null;
        calendars.forEach(calendar => {
            if(calendar.calendar_id === calendar_id) {
                calendar_to_change = calendar;
            }
        });
        changeCalendarsToUseCalendar(calendar_to_change.user_id, calendar_to_change.user_calendar_id, calendar_to_change.calendars_to_use).then(saved => {
            console.log({changeCalendarsToUseCalendarSaved: saved});
            setCalendars(calendars);
            setRefresh(true);
        })

    }

    const saveDefaultCalendar = () => {
        console.log(defaultChanged)
        let found = calendars.find(calendar => calendar.calendar_id === defaultChanged);
        console.log({found})
        setDefaultCalendar(found.user_id, found.calendar_id).then(saved => {
            console.log(saved);
            setDefaultChanged(null)
            setFirstRender(true)
        })
    }

    if(!calendars){
        return <LoadingComponent/>
    } else {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height:'80vh', flexDirection:'column'}}>
                <Box sx={{width:800, height:600, overflowY:'auto', background: "#FFF", borderRadius: 2, position: 'relative', filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))'}}>
                    {
                        calendars.map(calendar => {
                            return <EditCalendarComponent logoutUser={logoutUser} dbDefault={dbDefault} calendar={calendar} key={calendar.calendar_id} deleteCalendar={deleteCalendar} changedDefault={changedDefault} changedAutoAccept={changedAutoAccept} changeCalendarsToUse={changeCalendarsToUse} refresh={refresh} setRefresh={setRefresh}/>
                        })
                    }


                </Box>
                {
                    defaultChanged &&
                    <Box mt={1} sx={{width:800}}>
                        <Button color='success' variant='contained' onClick={saveDefaultCalendar} sx={{width:150}}>Save</Button>
                    </Box>
                }
            </Box>
        )
    }


}
