import {Box, Typography} from "@mui/material";
import logo from "../../media/Zingley-Logo-Dark.png";
import * as React from "react";
import {useNavigate} from "react-router-dom";

export default function Footer() {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/')
    }
    const handleLogin = () => {
        navigate('/login')
    }
    const handlePrivacyPolicy = () => {
        navigate('/privacy_policy')
    }
    const handleTerms = () => {
        navigate('/terms_and_conditions')
    }

    return(
        <Box>
            <Box pt={3} pb={2} mt={3} sx={{borderTop:'1px solid #999', display:'flex', justifyContent:'space-between'}}>
                <Box sx={{height:100, display: 'flex', flexDirection:'column', justifyContent:'space-between'}}>
                    <img src={logo} alt={'Logo image'} width={150}/>
                    <Typography >Copyright© Zingley Calendar {new Date().getFullYear()}</Typography>
                </Box>

                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'start', textAlign:'end'}}>
                    <Typography sx={{cursor:'pointer'}} onClick={handleHome}>Home</Typography>
                    <Typography sx={{cursor:'pointer'}} onClick={handleLogin}>Log In</Typography>
                    <Typography sx={{cursor:'pointer'}} onClick={handlePrivacyPolicy}>Privacy Policy</Typography>
                    <Typography sx={{cursor:'pointer'}} onClick={handleTerms}>Terms and Conditions</Typography>
                </Box>
            </Box>
            <Typography sx={{fontSize:12, color:'#999', textAlign:'justify'}}>
                *Zingley Calendar complies with the <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>. We use Google Calendar for scheduling and conflict detection. Your data is secure. You can manage permissions in your Google settings.
            </Typography>

        </Box>

    )
}
