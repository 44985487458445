import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
}));

function Day(props) {
    const { day, selectedDay, start_day, end_day, ...other } = props;


    if (selectedDay === null) {
        return <PickersDay day={day} {...other} />;
    } else if (selectedDay.day() < start_day && selectedDay.day() > end_day) {
        selectedDay.day(start_day)
    }

    const start = selectedDay.day(start_day);
    const end = selectedDay.day(end_day);



    const dayIsBetween = day.isBetween(start, end, null, '[]');
    const isFirstDay = day.isSame(start, 'day');
    const isLastDay = day.isSame(end, 'day');

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
            dayIsBetween={dayIsBetween}
            isFirstDay={isFirstDay}
            isLastDay={isLastDay}
        />
    );
}

Day.propTypes = {
    /**
     * The date to show.
     */
    day: PropTypes.object.isRequired,
    selectedDay: PropTypes.object,
};

export default function CustomCalendar({start_day, end_day}) {
    const [value, setValue] = React.useState(dayjs('2022-04-17'));

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
                value={value}
                onChange={(newValue) => {
                    if(newValue?.day() >= start_day && newValue?.day() <= end_day){
                        setValue(newValue)
                    }
                }}
                slots={{ day: Day }}
                slotProps={{
                    day: {
                        selectedDay: value,
                        start_day: start_day,
                        end_day: end_day
                    },
                }}
            />
        </LocalizationProvider>
    );
}
