import * as React from 'react';
import Main from './Main'
import {Box, CircularProgress, CssBaseline, Paper, ThemeProvider, Typography} from "@mui/material";
import '../../style/Dashboard.css'
import MenuHeader from "./Header/MenuHeader";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import LoginToken from "../../local_storage/LoginToken";
import Theme from "../../Theme";

async function getAllowedEmails(role_id, organization_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/allowed_emails?role_id=${role_id}&organization_id=${organization_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json());
}

export default function Dashboard( { setLoginToken, logoutUser, haveCalendars } ){
    const [selected, setSelected] = useState(0);
    const [eventGroup, setEventGroup] = useState(null);
    const { tokenData, getToken } = LoginToken();
    const [firstRender, setFirstRender] = useState(true);
    const [googleUser, setGoogleUser] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [allowedEmails, setAllowedEmails] = useState([]);
    const [allAllowedEmails, setAllAllowedEmails] = useState([]);
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [userIsSuperAdmin, setUserIsSuperAdmin] = useState(false);
    const [userName, setUserName] = useState(null);
    const [userAvatar, setUserAvatar] = useState(null);
    const [organization, setOrganization] = useState(null);
    const [role, setRole] = useState(null);
    const {theme} = Theme();


    useEffect(() => {
        if(firstRender) {
            setUserName(tokenData().name);
            setUserAvatar(tokenData().picture);
            setGoogleUser(!!(tokenData().google_id));
            let {organization_id, organization_name, organization_users_limit, organization_logo, role_id, role_name, enable_teams} = tokenData();
            setOrganization({organization_id, organization_name, organization_users_limit, organization_logo, enable_teams: enable_teams === 1});
            setRole({role_id, role_name});
            setUserIsSuperAdmin(role_id === 1);
            //console.log(tokenData());

            setUserEmail(tokenData().email);
            getAllowedEmails(role_id, organization_id).then(result => {
                //console.log(result)
                if(result.state) {
                    setAllowedEmails(result.allowed_emails.filter(email => email.organization_id === organization_id));
                    //console.log(result.allowed_emails)
                    if(role_id === 1) setAllAllowedEmails(result.allowed_emails);
                    setUserIsAdmin(result.allowed_emails.find(user => user.email === tokenData().email)?.is_admin === 1);
                }
                setFirstRender(false);
            }).catch(err => {
                setFirstRender(false);
            })
        }

    }, [firstRender]);


    const editEventGroup = (e) => {
        setEventGroup(e);
        setSelected(1);
    }

    if(googleUser === null) {
        return (
            <Box sx={{width:1, height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <CircularProgress/>
            </Box>
        )
    }

    return(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box className='background-dashboard'>
                <Helmet>
                    <title>Calendar - Dashboard</title>
                </Helmet>
                <MenuHeader setLoginToken={setLoginToken} logoutUser={logoutUser} selected={selected} setSelected={setSelected} haveCalendars={haveCalendars}  googleUser={googleUser} userIsAdmin={userIsAdmin} userIsSuperAdmin={userIsSuperAdmin}/>
                {
                    haveCalendars
                        ?
                        <Main
                            organization={organization} setOrganization={setOrganization} role={role} selected={selected} eventGroup={eventGroup}
                            editEventGroup={editEventGroup} logoutUser={logoutUser} userEmail={userEmail} allowedEmails={allowedEmails}
                            setAllowedEmails={setAllowedEmails} userName={userName} userAvatar={userAvatar}
                            allAllowedEmails={allAllowedEmails} setAllAllowedEmails={setAllAllowedEmails} rerender={setFirstRender}
                        />
                        :
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography component='h4' variant='h4' sx={{color: '#FFF'}}>Please connect your default calendar.</Typography>
                        </Box>
                }

            </Box>
        </ThemeProvider>

    )
}
