import {Outlet, Navigate} from 'react-router-dom'

const PrivateRoutes = ({token}) => {
    let auth = token;
    return (
        (auth) ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes
