import {Box, Button, LinearProgress, Typography} from "@mui/material";
import * as React from "react";
import UserImage from "./Media/UserImage";
import {useEffect, useState} from "react";
import LoginToken from "../../../local_storage/LoginToken";
import UserAvatar from "./Media/UserAvatar";
import defaultBackground from '../../../media/NewZingleyBG-min.jpg'
import defaultMobileBackground from '../../../media/ZingleyNewBG_Mob-min.jpg'

function changeImage(media, media_name_type, user_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/media/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({media, media_name_type, user_id})
    }).then(data => data.json()).catch(err => console.error(err))
}

function addImage(media, media_name_type, user_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/media/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({media, media_name_type, user_id})
    }).then(data => data.json()).catch(err => console.error(err))
}

function saveUserImage(token, column, src) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/users/images`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, column, src})
    }).then(data => data.json()).catch(err => console.error(err))
}

function checkIfIsLocalImage(src, user_id) {
    if(!src) return false;
    const parts = src.split('/');
    return parts[parts.length - 2] === user_id.toString() && parts[parts.length - 3] === 'media'
}

export default function ManageImages (){
    const { userData, setUserData, getToken } = LoginToken();

    const [firstRender, setFirstRender] = useState(true);
    const [avatarSrc, setAvatarSrc] = useState(null);
    const [name, setName] = useState(null);
    const [brandSrc, setBrandSrc] = useState(null);
    const [backgroundSrc, setBackgroundSrc] = useState(null);
    const [mobileBackgroundSrc, setMobileBackgroundSrc] = useState(null);
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [avatarIsLocalImage, setAvatarIsLocalImage] = useState(false);
    const [brandIsLocalImage, setBrandIsLocalImage] = useState(false);
    const [backgroundIsLocalImage, setBackgroundIsLocalImage] = useState(false);
    const [mobileBackgroundIsLocalImage, setMobileBackgroundIsLocalImage] = useState(false);
    const [changeAvatarImageWaiting, setChangeAvatarImageWaiting] = useState(false);
    const [changeBrandImageWaiting, setChangeBrandImageWaiting] = useState(false);
    const [changeBackgroundImageWaiting, setChangeBackgroundImageWaiting] = useState(false);
    const [changeMobileBackgroundImageWaiting, setChangeMobileBackgroundImageWaiting] = useState(false);

    const [width, setWidth] = useState('70vw');
    const [height, setHeight] = useState('80vh');

    const [mobilePreview, setMobilePreview] = useState(false);

    useEffect(() => {
        if(firstRender) {
            if(!!(userData())){
                let {brand_image, background_image, mobile_background_image, picture, name, user_id} = userData();
                setAvatarSrc(picture);
                setBrandSrc(brand_image);
                setName(name);
                setBackgroundSrc(background_image ? background_image : defaultBackground);
                setMobileBackgroundSrc(mobile_background_image ? mobile_background_image : defaultMobileBackground);
                setUserId(user_id);
                setAvatarIsLocalImage(checkIfIsLocalImage(picture, user_id));
                setBrandIsLocalImage(checkIfIsLocalImage(brand_image, user_id));
                setBackgroundIsLocalImage(checkIfIsLocalImage(background_image, user_id));
                setMobileBackgroundIsLocalImage(checkIfIsLocalImage(mobile_background_image, user_id));
                setLoading(false)
            }
            setFirstRender(false)
        }
    }, [firstRender]);


    const changeMedia = (media, media_name_type, replace) => {
        if(replace) {
            return changeImage(media, media_name_type, userId).then(response => {
                return response;
            })
        } else {
            return addImage(media, media_name_type, userId).then(response => {
                return response;
            })
        }
    }

    const changeAvatar = (media, media_name_type) => {
        setChangeAvatarImageWaiting(true);
        return changeMedia(media, `avatar.${media_name_type}`, avatarIsLocalImage).then(result => {
            console.log({result});
            if(result.state) {
                return saveUserImage(getToken().token, 'picture', result.src).then(updated => {
                    if(updated.status) {
                        setAvatarSrc(result.src);
                        setAvatarIsLocalImage(true);
                        setChangeAvatarImageWaiting(false);
                        userData().picture = result.src;
                        setUserData(userData());
                        return result;
                    } else {
                        result.state = false;
                        result.error = "Image is not saved."
                        return result;
                    }
                })
            } else {
                result.state = false;
                return result;
            }
        })
    }

    const changeBrandImage = (media, media_name_type) => {
        setChangeBrandImageWaiting(true);
        return changeMedia(media, `brand_image.${media_name_type}`, brandIsLocalImage).then(result => {
            console.log({result});
            if(result.state) {
                return saveUserImage(getToken().token, 'brand_image', result.src).then(updated => {
                    if(updated.status) {
                        setBrandSrc(result.src);
                        setBrandIsLocalImage(true);
                        setChangeBrandImageWaiting(false);
                        userData().brand_image = result.src;
                        setUserData(userData());
                        return result;
                    } else {
                        result.state = false;
                        result.error = "Image is not saved."
                        return result;
                    }
                })
            } else {
                result.state = false;
                return result;
            }
        })
    }

    const changeBackgroundImage = (media, media_name_type) => {
        setChangeBackgroundImageWaiting(true);
        return changeMedia(media, `background_image.${media_name_type}`, backgroundIsLocalImage).then(result => {
            console.log({result});
            if(result.state) {
                return saveUserImage(getToken().token, 'background_image', result.src).then(updated => {
                    if(updated.status) {
                        setBackgroundSrc(result.src);
                        setBackgroundIsLocalImage(true);
                        setChangeBackgroundImageWaiting(false);
                        userData().background_image = result.src;
                        setUserData(userData());
                        return result;
                    } else {
                        result.state = false;
                        result.error = "Image is not saved."
                        return result;
                    }
                })
            } else {
                result.state = false;
                return result;
            }
        })
    }

    const changeMobileBackgroundImage = (media, media_name_type) => {
        setChangeMobileBackgroundImageWaiting(true);
        return changeMedia(media, `mobile_background_image.${media_name_type}`, mobileBackgroundIsLocalImage).then(result => {
            console.log({result});
            if(result.state) {
                return saveUserImage(getToken().token, 'mobile_background_image', result.src).then(updated => {
                    if(updated.status) {
                        setMobileBackgroundSrc(result.src);
                        setMobileBackgroundIsLocalImage(true);
                        setChangeMobileBackgroundImageWaiting(false);
                        userData().mobile_background_image = result.src;
                        setUserData(userData());
                        return result;
                    } else {
                        result.state = false;
                        result.error = "Image is not saved."
                        return result;
                    }
                })
            } else {
                result.state = false;
                return result;
            }
        })
    }

    const toggleMobilePreview = () => {
        if(mobilePreview) {
            setWidth('70vw');
            setHeight('80vh');
        } else {
            setWidth('375px');
            setHeight('667px')
        }
        setMobilePreview(!mobilePreview);
    }

    if(loading) {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height:'80vh'}}>
                <Box sx={{width:0.8, height:0.8, background: "#FFF", borderRadius: 2, position: 'relative', filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))'}}>
                    <LinearProgress/>
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection:'column'}}>
            <ToggleButton mobilePreview={mobilePreview} toggleMobilePreview={toggleMobilePreview}/>
            <Box sx={{width, height, borderRadius: 2, position: 'relative', filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))', background: `url(${mobilePreview ? mobileBackgroundSrc : backgroundSrc})`, backgroundSize:'cover', backgroundPosition:'center center', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Box className="event-types-calendar" id='scroll-holder' sx={{width: mobilePreview ? '90% !important' : '800px !important', margin:5}}>
                    <Box className='blur-effect'/>
                    <Box className='header'>
                        <UserImage src={brandSrc} name={'brand image'} waiting={changeBrandImageWaiting} changeImage={changeBrandImage} width={'auto'} height={50}/>
                        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', margin:'40px 40px 0 40px', background:'rgba(0, 0, 0, 0.2)', backdropFilter:'blur(22px)', borderRadius:'30px'}}>
                            <UserAvatar src={avatarSrc} name={name} waiting={changeAvatarImageWaiting} changeImage={changeAvatar} width={60} height={60}/>
                            <Typography sx={{color:'#FFF', fontSize:mobilePreview ? 18 : 24, fontFamily:'Poppins', zIndex:1000, width: mobilePreview ? 180 : 1, textAlign:'center'}} pl={2} pr={4}>{name}</Typography>
                        </Box>

                        <Box className='user-details' sx={{zIndex:"10000 !important"}}>
                        </Box>

                    </Box>
                </Box>

                {
                    !mobilePreview ?
                        <Box sx={{position:'absolute', top:10, right:15, border:'1px solid #FFF'}}>
                            <UserImage src={backgroundSrc} name={'background image'} waiting={changeBackgroundImageWaiting} changeImage={changeBackgroundImage} width={190} height={100} alwaysShowOverlay={true}/>
                        </Box>
                        :
                        <Box sx={{position:'absolute', top:10, right:10, border:'1px solid #FFF'}}>
                            <UserImage src={mobileBackgroundSrc} name={'mobile background image'} waiting={changeMobileBackgroundImageWaiting} changeImage={changeMobileBackgroundImage} width={80} height={120} alwaysShowOverlay={true}/>
                        </Box>
                }

            </Box>
        </Box>
    )
}

function ToggleButton ({mobilePreview, toggleMobilePreview}) {
    return (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', padding:0.5, filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))', background:'#FFF', borderRadius:'20px', height:40, marginTop:-2, marginBottom:2, cursor:'pointer'}} onClick={toggleMobilePreview}>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center',width:150, height:1, borderRadius:'18px', background: mobilePreview ? '#FFF' : 'green'}}>
                <Typography sx={{color: !mobilePreview ? '#FFF' : '#333', fontWeight:!mobilePreview ? 600 : 400}}>Desktop</Typography>
            </Box>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center',width:150, height:1, borderRadius:'18px', background: !mobilePreview ? '#FFF' : 'green'}}>
                <Typography sx={{color: mobilePreview ? '#FFF' : '#333', fontWeight:mobilePreview ? 600 : 400}}>Mobile</Typography>
            </Box>
        </Box>
    )
}
