import { Box, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import * as React from "react";
import '../../style/PrivacyPolicy.css'
import Header from "../Homepage/Header";
import Footer from "../Homepage/Footer";
import Theme from "../../Theme";

export default function Terms() {
    const { theme } = Theme();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box className='privacy-policy-background'>
                <Header />

                <Box className='privacy-policy-wrapper'>
                    <Box className="privacy-policy-event-types-events">
                        <Box className='privacy-policy-blur-effect' />
                        <Box className='privacy-policy-text'>
                            <Typography component='h1' variant='h1'>Terms and Conditions</Typography>
                            <Typography component='h3' variant='h3'>Last Updated:6 August 2024</Typography>
                            <Box className='privacy-policy-text-privacy-policy'>
                                <Typography component='h2' variant='h2'>1. Introduction</Typography>
                                <Typography component='p' variant='p'>Welcome to Zingley calendar, a platform designed
                                    to seamlessly integrate your Google or Microsoft calendars, manage event types, and
                                    enhance your scheduling experience. These Terms and Conditions outline the rules and
                                    regulations for the use of Zingley calendar. By accessing this application, we
                                    assume you accept these terms in full. Do not continue to use Zingley calendar if
                                    you do not agree to all of the terms and conditions stated on this
                                    page.</Typography>

                                <Typography component='h2' variant='h2'>2. Company Information</Typography>
                                <Typography component='p' variant='p'>Name: Zingley</Typography>
                                <Typography component='p' variant='p'>Contact: info@zingley.com</Typography>

                                <Typography component='h2' variant='h2'>3. App Description</Typography>
                                <Typography component='p' variant='p'>Zingley calendar allows users to:</Typography>
                                <Typography component='p' variant='p' ml={2}>a. Integrate multiple Google or Microsoft
                                    calendars.</Typography>
                                <Typography component='p' variant='p' ml={2}>b. Customize and manage event types,
                                    including group events.</Typography>
                                <Typography component='p' variant='p' ml={2}>c. Avoid scheduling conflicts by selecting
                                    calendars to check for availability.</Typography>
                                <Typography component='p' variant='p' ml={2}>d. Automatically accept meetings when
                                    enabled.</Typography>
                                <Typography component='p' variant='p' ml={2}>e. Personalize event scheduling with custom
                                    backgrounds, logos, and more.</Typography>
                                <Typography component='p' variant='p' ml={2}>f. Share scheduling links and embed
                                    calendars on websites.</Typography>
                                <Typography component='p' variant='p' ml={2}>g. Add non-working dates and specify
                                    non-working hours.</Typography>

                                <Typography component='h2' variant='h2'>4. User Obligations and
                                    Responsibilities</Typography>
                                <Typography component='p' variant='p'>By using Zingley calendar, you agree
                                    to:</Typography>
                                <Typography component='p' variant='p' ml={2}>a. Provide accurate and up-to-date
                                    information when creating an account.</Typography>
                                <Typography component='p' variant='p' ml={2}>b. Use the app only for lawful purposes and
                                    in accordance with these Terms.</Typography>
                                <Typography component='p' variant='p' ml={2}>c. Not engage in any conduct that restricts
                                    or inhibits anyone’s use or enjoyment of the app.</Typography>
                                <Typography component='p' variant='p' ml={2}>d. Safeguard your account information and
                                    notify Zingley calendar of any unauthorized use of your account.</Typography>
                                <Typography component='p' variant='p' ml={2}>e. Ensure that your use of the app complies
                                    with all applicable local, state, national, and international laws and
                                    regulations.</Typography>

                                <Typography component='h2' variant='h2'>5. Payment and Subscription Details</Typography>
                                <Typography component='p' variant='p'>Zingley calendar is currently offered free of charge, with
                                    no subscription fees. Any changes to payment policies will be communicated to users
                                    in advance.</Typography>

                                <Typography component='h2' variant='h2'>6. Data Privacy and Security</Typography>
                                <Typography component='p' variant='p'>Zingley calendar is committed to protecting your privacy.
                                    We do not share your data with third parties, and we only save data necessary for
                                    the app's functionality. For more information, please refer to our Privacy
                                    Policy.</Typography>

                                <Typography component='h2' variant='h2'>7. Intellectual Property</Typography>
                                <Typography component='p' variant='p'>The app and its original content, features, and
                                    functionality are and will remain the exclusive property of Zingley. You must
                                    not:</Typography>
                                <Typography component='p' variant='p' ml={2}>a. Republish material from
                                    Zingley.</Typography>
                                <Typography component='p' variant='p' ml={2}>b. Sell, rent, or sub-license material from
                                    Zingley.</Typography>
                                <Typography component='p' variant='p' ml={2}>c. Reproduce, duplicate, or copy material
                                    from Zingley.</Typography>

                                <Typography component='h2' variant='h2'>8. Limitation of Liability</Typography>
                                <Typography component='p' variant='p'>Zingley calendar shall not be liable for any damages that
                                    may occur as a result of your use of the app, to the fullest extent permitted by
                                    law.</Typography>

                                <Typography component='h2' variant='h2'>9. Termination of Service</Typography>
                                <Typography component='p' variant='p'>Zingley calendar reserves the right to suspend or terminate
                                    your access to the app at our sole discretion, without notice, for conduct that we
                                    believe violates these Terms and Conditions or is harmful to other users of the app,
                                    us, or third parties, or for any other reason.</Typography>

                                <Typography component='h2' variant='h2'>10. Google API Services Disclosure</Typography>
                                <Typography component='p' variant='p'>Zingley Calendar complies with the Google API
                                    Services User Data Policy, including Limited Use requirements. We access your Google
                                    Calendar for scheduling and conflict detection. Your data is encrypted and secure.
                                    Manage or revoke permissions in your Google account settings. For privacy questions,
                                    contact us at info@zingley.com. For more information, please refer to <a
                                        href="https://developers.google.com/terms/api-services-user-data-policy">this link</a>.</Typography>

                                <Typography component='h2' variant='h2'>11. Changes to Terms</Typography>
                                <Typography component='p' variant='p'>Zingley calendar reserves the right to revise these terms
                                    at any time. We will provide notice of any changes by updating the terms on our
                                    website or app. Your continued use of the app following any changes indicates your
                                    acceptance of the new terms.</Typography>

                                <Typography component='h2' variant='h2'>12. Governing Law</Typography>
                                <Typography component='p' variant='p'>These terms are governed by and construed in
                                    accordance with the laws of United States, and you irrevocably submit to the
                                    exclusive jurisdiction of the courts in that location.</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', paddingTop: "100px" }}>
                    <Box sx={{ maxWidth: 1440, width: 1, paddingY: 4 }}>
                        <Footer />
                    </Box>
                </Box>

            </Box>
        </ThemeProvider>
    )
}
