import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddIcon from "@mui/icons-material/Add";
import {
    Box,
    LinearProgress,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel, NativeSelect
} from "@mui/material";
import {useState} from "react";
import LoginToken from "../../../../local_storage/LoginToken";


async function addNewOrganization(accessToken, organization_name, organization_users_limit) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/organization`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        body: JSON.stringify({organization_name, organization_users_limit})
    }).then(data => data.json())
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//TODO remove and edit organizations

export default function SuperAdminAddOrganization({allOrganizations, setAllOrganizations, rerender}) {
    const [open, setOpen] = useState(false);
    const [newOrgName, setNewOrgName] = useState('');
    const [newOrgLimit, setNewOrgLimit] = useState(null);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    let {getToken} = LoginToken();


    const addOrganization = () => {
        if(allOrganizations.find(org => org.organization_name === newOrgName)) {
            setError("Organization with this name already exists")
        } else {
            setSaving(true);
            addNewOrganization(getToken()?.token, newOrgName, newOrgLimit).then(result => {
                if(result.state) {
                    let currentOrganizations = [...allOrganizations];
                    result.added.number_of_users = 0;
                    currentOrganizations.push(result.added);
                    setAllOrganizations(currentOrganizations);
                    setError(null);
                    setOpen(false);
                } else {
                    setError(result?.error ? result.error : 'Error on adding new organization')
                }
                setSaving(false);
            })
        }

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Button startIcon={<AddIcon/>} size={'small'} color={'success'} onClick={handleClickOpen}>
                add new organization
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>

                    { saving && <LinearProgress/> }

                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Add new organization
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box sx={{
                    '& > :not(style)': { m: 1, width: 500 },
                    textAlign:'center',
                    display:'flex',
                    flexDirection:'column',
                    margin:'40px auto',
                }}
                     noValidate
                     autoComplete="off"
                >
                    {
                        error && <Typography color={'error'} sx={{textAlign:'start', marginBottom:1, fontSize:12}}>{error}</Typography>
                    }
                    <FormControl fullWidth >
                        <TextField
                            size={'small'}
                            value={newOrgName}
                            type='text'
                            id="name"
                            label="Organization name"
                            variant="outlined"
                            required
                            onChange={(e) => setNewOrgName(e.target.value)}
                        />
                        <TextField
                            sx={{marginTop:2}}
                            size={'small'}
                            value={newOrgLimit}
                            type='number'
                            id="name"
                            label="User Limit"
                            variant="outlined"
                            onChange={(e) => setNewOrgLimit(parseInt(e.target.value))}
                        />
                        <Button type='submit' variant='contained' onClick={addOrganization} sx={{marginTop:2}}>
                            Save organization
                        </Button>
                    </FormControl>
                </Box>
            </Dialog>
        </Box>
    );
}


