import { DateCalendar } from '@mui/x-date-pickers';
import {Box, Typography} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import 'dayjs/locale/en';
import {useEffect, useRef, useState} from "react";
import * as React from 'react';
import Checkbox from '@mui/material/Checkbox'
import PropTypes from 'prop-types';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import LoadingComponent from "../../../../UserScheduleComponents/LoadingComponent";
import CircularProgress from "@mui/material/CircularProgress";

dayjs.extend(weekday);
dayjs.locale('en');

let sd = 1;
let ed = 5;

let disabledDates = [];
let workingDays = [];

function isWorkDay(date) {
    const day = date.day();
    return !!(workingDays.find(wd => wd.day === day)); // 1 is Monday, 5 is Friday
}

function shouldDisableDate(date) {
    // Disable weekends
    if (!isWorkDay(date)) {
        return true;
    }

    // Disable specific dates
    return disabledDates.some((disabledDate) => {
        return date.year() === disabledDate.year() &&
            date.month() === disabledDate.month() &&
            date.date() === disabledDate.date();
    });
}





function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, highlight, ...other } = props;

    const isSelected =
        !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) > 0;

    return (
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} className={highlight ? isSelected ? 'not-full-availability' : 'full-availability' : ''}/>

    );
}

ServerDay.propTypes = {
    /**
     * The date to show.
     */
    day: PropTypes.object.isRequired,
    highlightedDays: PropTypes.arrayOf(PropTypes.number),
    /**
     * If `true`, day is outside of month and will be hidden.
     */
    outsideCurrentMonth: PropTypes.bool.isRequired,
};

export default function MyDateCalendar({start_day, end_day, not_working, working_days, setSelected, conflicts, timeSlots, setChangedMonth, rerenderCalendar, setRerenderCalendar, calendarLoading}) {

    if(start_day) sd = start_day;
    if(end_day) ed = end_day;
    if(not_working) disabledDates = not_working;
    if(working_days) workingDays = working_days;
    const [highlightedDays, setHighlightedDays] = useState([]);
    const requestAbortController = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [highlight, setHighlight] = useState(false);

    const fetchHighlightedDays = (month) => {
        if(!month){
            month = selectedMonth ? selectedMonth : dayjs().month();
        }
        let occupied = [];

        timeSlots?.occupied?.forEach(occ => {
            let start = new Date(occ.start);
            let end = new Date(occ.end);
            start.setHours(0,0,0,0);
            end.setHours(0,0,0,0);
            if(!occupied.find(date => date.getTime() === start.getTime())){
                occupied.push(start)
            }
            if(!occupied.find(date => date.getTime() === end.getTime())){
                occupied.push(end)
            }
        })

        let highlighted = [0];
        occupied.forEach(occ => {
            if(occ.getMonth() === month){
                highlighted.push(occ.getDate());
            }
        })
        setHighlightedDays(highlighted);
    };

    const handleCheckbox = (e) => {
        setHighlight(!highlight);
    }

    const handleMonthChange = (e) => {
        let date = e
        //console.log({handleMonthChange: date.format("DD.MM.YYYY"),e})
        setSelectedMonth(date.month())
        setHighlightedDays([]);
        setChangedMonth(date)
        //fetchHighlightedDays(date.month());
    };

    useEffect(() => {
        if(rerenderCalendar){
            fetchHighlightedDays();
            // abort request on unmount
            setRerenderCalendar(false)
            return () => requestAbortController.current?.abort();
        }

    }, [rerenderCalendar]);


    return (
        <Box sx={{position:'relative'}}>
            {
                !calendarLoading
                    ?
                    <Box className='checkbox-holder'>
                        <Checkbox
                            {...label}
                            checked={highlight}
                            sx={{
                                color: "rgba(255, 255, 255, 0.15)",
                                '&.Mui-checked': {
                                    color: "#E5C518",
                                },
                            }}
                            onChange={handleCheckbox}
                        />
                        <Typography variant='p' component='p'>Highlight days with full availability</Typography>
                    </Box>
                    :
                    <Box className='checkbox-holder' sx={{height:40}}>
                        <Typography variant='p' component='p' mb={1} className="animated-ellipsis">Fetching calendar data</Typography>
                    </Box>
            }
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                locale={dayjs.locale()}
                dateFormats={{ header: 'ddd, MMM D' }}
                startOfWeek={() => dayjs().weekday(1)}
            >
                {
                    calendarLoading &&
                    <Box sx={{position:'absolute', width:1, height:0.85, zIndex:1, display:'flex', alignItems:'center', justifyContent:'center', borderRadius:2, backdropFilter:'blur(9px)', background:'rgba(255,255,255,0.2)'}}>
                        <CircularProgress style={{color:'#E5C518'}}/>
                    </Box>
                }
                <DateCalendar
                    minDate={dayjs()}
                    shouldDisableDate={shouldDisableDate}
                    views={['day']}
                    //onMonthChange={handleMonthChange}
                    components={{
                        day: ({ day, ...DayProps }) => {
                            const date = day.date;
                            if (isWorkDay(date)) {
                                DayProps.className = `${DayProps.className}`;
                            } else {
                                DayProps.className = `${DayProps.className}`;
                            }
                            return <div {...DayProps}>{date.getDate()}</div>;
                        },
                    }}
                    onMonthChange={handleMonthChange}
                    onChange={setSelected}
                    slotProps={{
                        day: {
                            highlightedDays,
                            highlight
                        },
                    }}
                    slots={{
                        day: ServerDay,
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
}
