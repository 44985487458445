import {Box, Checkbox, Grid, TextField, Tooltip, Typography} from "@mui/material";
import LoginToken from "../../../../local_storage/LoginToken";
import {useEffect, useState} from "react";
import SuperAdminAddOrganization from "./SuperAdminAddOrganization";
import SuperAdminDeleteOrganizationAlertDialog from "./SuperAdminDeleteOrganizationAlertDialog";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

async function getAllOrganizations(accessToken) {
    return fetch(process.env.REACT_APP_SERVER_URL + '/organization', {
        method: 'GET',
        headers: { 'Authorization': accessToken },
    }).then(data => data.json());
}

async function updateOrganization(organization_id, organization_name, organization_users_limit, enable_teams, accessToken) {
    console.log({organization_id, organization_name, organization_users_limit})
    return fetch(process.env.REACT_APP_SERVER_URL + '/organization', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        body: JSON.stringify({organization_id, organization_name, organization_users_limit, enable_teams})
    }).then(data => data.json());
}

export default function SuperAdminOrganizations({myOrganization, setMyOrganization}) {

    let {getToken} = LoginToken();
    const [firstRender, setFirstRender] = useState(true);
    const [allOrganizations, setAllOrganizations] = useState([]);

    useEffect(() => {
        if(firstRender) {
            getAllOrganizations(getToken()?.token).then(result => {
                if(result.status) {
                    setAllOrganizations(result.organizations);
                }
            })
            setFirstRender(false);
        }
    }, [firstRender]);

    return (
        <Box>
            <Grid container spacing={2} mb={2}>
                <Grid item xs={0.5} >ID</Grid>
                <Grid item xs={4} >Name</Grid>
                <Grid item xs={2} >Users Limit</Grid>
                <Grid item xs={1.5} >Added Users</Grid>
                <Grid item xs={1} >Teams</Grid>
                <Grid item xs={3} >Logo</Grid>
            </Grid>
            {
                allOrganizations.length > 0 &&
                allOrganizations.map((org, index) => <SingleOrganization myOrganization={myOrganization} setMyOrganization={setMyOrganization} index={index} key={org.organization_id} organization={org} allOrganizations={allOrganizations} setAllOrganizations={setAllOrganizations}/>)
            }
            <SuperAdminAddOrganization
                allOrganizations={allOrganizations} setAllOrganizations={setAllOrganizations}
            />
        </Box>
    )
}

function SingleOrganization({organization, allOrganizations, setAllOrganizations, index, myOrganization, setMyOrganization}) {
    const [deletable, setDeletable] = useState(organization.number_of_users === 0);
    const [name, setName] = useState(organization.organization_name);
    const [userLimit, setUserLimit] = useState(organization.organization_users_limit);
    const [teamsEnabled, setTeamsEnabled] = useState(organization.enable_teams === 1);

    let {getToken} = LoginToken();

    const updateOrganizationName = (new_name) => {
        updateOrganization(organization.organization_id, new_name, organization.organization_users_limit, teamsEnabled, getToken()?.token).then(result => {
            if(result.state) {
                organization.organization_name = new_name;
                let orgs = [...allOrganizations];
                let found = orgs.find(o => o.organization_id === organization.organization_id);
                found.organization_name = new_name;
                setAllOrganizations(orgs)
            }
        })
    }

    const updateOrganizationLimit = (new_limit) => {
        updateOrganization(organization.organization_id, organization.organization_name, (new_limit !== '' ? new_limit : null), teamsEnabled, getToken()?.token).then(result => {
            if(result.state) {
                organization.organization_users_limit = (new_limit !== '' ? new_limit : null);
                let orgs = [...allOrganizations];
                let found = orgs.find(o => o.organization_id === organization.organization_id);
                found.organization_users_limit = (new_limit !== '' ? new_limit : null);
                setAllOrganizations(orgs)
            }
        })
    }

    const updateEnableTeams = (new_value) => {
        updateOrganization(organization.organization_id, organization.organization_name, organization.organization_users_limit, new_value, getToken()?.token).then(result => {
            if(result.state) {
                organization.enable_teams = new_value ? 1 : 0;
                let orgs = [...allOrganizations];
                let found = orgs.find(o => o.organization_id === organization.organization_id);
                found.enable_teams = new_value ? 1 : 0;
                setAllOrganizations(orgs);
                let myOrg = {...myOrganization};
                myOrg.enable_teams = new_value;
                setMyOrganization(myOrg);
            }
        })
    }

    useEffect(() => {
        setDeletable(organization.number_of_users === 0);
    }, [organization]);
    return (
        <Grid container spacing={0} sx={{fontSize:14, position:'relative', alignItems:'center', background: index % 2 === 0 ? 'rgba(0,0,0,0.1)' : 'none'}}>
            <Grid item xs={0.5} pl={1}>{organization.organization_id}</Grid>
            <Grid item xs={4} px={1}><Name name={name} setName={setName} updateName={updateOrganizationName}/> </Grid>
            <Grid item xs={2} px={1}><Limit limit={userLimit} setLimit={setUserLimit} updateLimit={updateOrganizationLimit}/> </Grid>
            <Grid item xs={1.5} pl={1}>{organization.number_of_users}</Grid>
            <Grid item xs={1} pl={1}><TeamsCheckbox checked={teamsEnabled} setChecked={setTeamsEnabled} updateEnableTeams={updateEnableTeams}/> </Grid>
            <Grid item xs={2.5} pl={1}>
                <Tooltip title={organization.organization_logo}>
                    <Typography
                        sx={{
                            fontSize:14,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {organization.organization_logo}
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={0.5} >{deletable && <SuperAdminDeleteOrganizationAlertDialog organizationToRemove={organization} organizations={allOrganizations} setOrganizations={setAllOrganizations}/>}</Grid>
        </Grid>
    )
}

function TeamsCheckbox({checked, setChecked, updateEnableTeams}) {

    const handleChange = (event) => {
        updateEnableTeams(event.target.checked);
        setChecked(event.target.checked);
    };

    return (
        <Checkbox
            size={'small'}
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}

function Name({name, setName, disable, updateName}) {

    const [edit, setEdit] = useState(false);
    const [textValue, setTextValue] = useState(name);

    const resetText = () => {
        setEdit(!edit);
        setTextValue(name);
    }

    const saveEmail = () => {
        setName(textValue);
        setEdit(!edit);
        updateName(textValue);
    }

    return (
        <Box sx={{display:'flex', position:'relative', alignItems:'center'}}>
            {
                edit ?
                    <TextField size='small' variant="standard" sx={{fontSize:14, padding:0}} value={textValue} onChange={(e) => setTextValue(e.target.value)}/>
                    :
                    <Typography sx={{fontSize:14}}>{name}</Typography>
            }

            {
                !disable ?
                    !edit ?
                        <EditIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                        :
                        <Box sx={{display: 'flex', alignItems:'center'}}>
                            <CheckIcon onClick={saveEmail} sx={{fontSize:14, position: 'absolute', right:20, cursor:'pointer'}}/>
                            <ClearIcon onClick={resetText} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                        </Box>
                    :
                    ""
            }

        </Box>
    )
}

function Limit({limit, setLimit, disable, updateLimit}) {

    const [edit, setEdit] = useState(false);
    const [textValue, setTextValue] = useState(limit);

    const resetText = () => {
        setEdit(!edit);
        setTextValue(limit);
    }

    const saveEmail = () => {
        setLimit(textValue);
        setEdit(!edit);
        updateLimit(textValue);
    }

    return (
        <Box sx={{display:'flex', position:'relative', alignItems:'center'}}>
            {
                edit ?
                    <TextField size='small' variant="standard" type={'number'} sx={{fontSize:14, padding:0}} value={textValue} onChange={(e) => setTextValue(e.target.value)}/>
                    :
                    <Typography sx={{fontSize:14}}>{limit ? limit : '∞'}</Typography>
            }

            {
                !disable ?
                    !edit ?
                        <EditIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                        :
                        <Box sx={{display: 'flex', alignItems:'center'}}>
                            <CheckIcon onClick={saveEmail} sx={{fontSize:14, position: 'absolute', right:40, cursor:'pointer'}}/>
                            <ClearIcon onClick={resetText} sx={{fontSize:14, position: 'absolute', right:20, cursor:'pointer'}}/>
                        </Box>
                    :
                    ""
            }

        </Box>
    )
}
