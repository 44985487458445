import {Box, Typography} from "@mui/material";

export default function NotAllowedEmail() {
    return (
        <Box sx={{height:'100vh', width:1, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', gap:2}}>
            <Typography variant={'h3'}>This email is not allowed</Typography>
            <a href="/login">Go back</a>
        </Box>
    )
}
