import {Box, Typography} from "@mui/material";
import RenderScheduleComponent from "./RenderScheduleComponent";
import '../style/UserSchedule.css';
import { useEffect, useState } from "react";
import defaultBackground from '../media/NewZingleyBG-min.jpg';
import defaultMobileBackground from '../media/ZingleyNewBG_Mob-min.jpg';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import { useLocation } from 'react-router-dom';


async function getUserImages(path, group_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/users/images_for_user/${path}?group_id=${group_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json());
}

export default function UserSchedule({ path }) {
    const theme = createTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [paths, setPaths] = useState(path.split("/"));
    const [firstRender, setFirstRender] = useState(true);
    const [backgroundSrc, setBackgroundSrc] = useState(null);
    const [mobileBackgroundSrc, setMobileBackgroundSrc] = useState(null);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const background = queryParams.get('background');
    const showLogo = queryParams.get('showLogo');
    const showName = queryParams.get('showName');
    const [customLogo, setCustomLogo] = useState(queryParams.get('customLogo'));
    const customText = queryParams.get('customText');
    //console.log({background})

    useEffect(() => {
        if (firstRender) {
            getUserImages(paths[1], paths[2]).then(images => {
                if (images) {
                    let { background_image, mobile_background_image, event_brand_image} = images;
                    setBackgroundSrc(background_image ? background_image : defaultBackground);
                    setMobileBackgroundSrc(mobile_background_image ? mobile_background_image : defaultMobileBackground);
                    if(!queryParams.get('customLogo') && event_brand_image) {
                        setCustomLogo(event_brand_image);
                    }
                }
                setFirstRender(false);
            });
        }
    }, [firstRender, paths]);

    const handleImageLoad = () => {
        setImagesLoaded(true);
    };

    return (
        <>
            <img
                src={background ? background : (isMobile ? mobileBackgroundSrc : backgroundSrc)}
                style={{ display: 'none' }}
                onLoad={handleImageLoad}
                alt="Background preloader"
            />
            {background || imagesLoaded ? (
                <Box className='background' sx={{
                    background: `url(${background ? background : (isMobile ? mobileBackgroundSrc : backgroundSrc)})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center'
                }}>
                    <RenderScheduleComponent path={path} showLogo={showLogo} showName={showName} customLogo={customLogo} customText={customText}/>
                </Box>
            ) : (
                <Box sx={{width:1, height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <CircularProgress style={{color:'#E5C518'}}/>
                </Box> // or a more complex loading indicator
            )}
        </>
    );
}
