import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Tooltip} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {useState} from "react";

export default function RemoveTemplate({template, remove}) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        console.log(template)
        setOpen(true);
    };

    const handleRemove = () => {
        console.log('remove');

        console.log({template})

        remove(template);
        setOpen(false);
    };

    const handleCancel = () => {
        console.log('close')
        setOpen(false);
    };

    return (
        <Box>
            <Tooltip title="Remove template">
                <RemoveCircleOutlineIcon sx={{color:'#ff3030', position:'absolute', right:15, cursor:'pointer'}} onClick={handleClickOpen}/>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to delete {template.name} template?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This action cannot be undone!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleRemove} autoFocus>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
