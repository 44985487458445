import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SuperAdminAllowedEmails from "./SuperAdminPanel/SuperAdminAllowedEmails";
import SuperAdminOrganizations from "./SuperAdminPanel/SuperAdminOrganizations";
import {Typography} from "@mui/material";
import PaypalComponent from "./PaymentComponents/PaypalComponent";
import StripeComponent from "./PaymentComponents/StripeComponent";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </Box>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const tabStyle = {
    '&.Mui-selected': {
        backgroundColor: '#FFF !important', // Selected background color
        filter: "none !important"
    },
}


export default function PaymentComponent({}) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', flexDirection: 'column' }}>
            <Box sx={{ width: 1000, height: 600, overflowY: 'auto', background: "#FFF", borderRadius: 2, position: 'relative', filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))' }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab
                                label="Paypal"
                                {...a11yProps(0)}
                                sx={tabStyle}
                            />
                            <Tab
                                label="Stripe"
                                {...a11yProps(1)}
                                sx={tabStyle}
                            />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <PaypalComponent/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <StripeComponent/>
                    </CustomTabPanel>
                </Box>
            </Box>
        </Box>
    );
}
