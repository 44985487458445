import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {Box, LinearProgress, Tooltip} from "@mui/material";
import {useState} from "react";
import LoginToken from "../../../../local_storage/LoginToken";

async function removeUserEmail(organization_teams_id, accessToken) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/organization_teams`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        body: JSON.stringify({organization_teams_id})
    }).then(data => data.json())
}

export default function DeleteTeamAlertDialog({teamToRemove, teams, setTeams, rerender}) {
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    let {getToken} = LoginToken();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setSaving(true);
        removeUserEmail(teamToRemove.organization_teams_id, getToken()?.token).then(result => {
            if(result?.state) {
                let indexToDelete = teams.findIndex(t => t.organization_teams_id === teamToRemove.organization_teams_id);
                let newArray = [...teams];
                newArray.splice(indexToDelete, 1);
                setTeams(newArray);
                setOpen(false);
                setSaving(false);
                rerender(true);
            } else {
                setError(result?.error ? result.error : 'Error on removing team.');
                setSaving(false);
            }
        })
    };

    return (
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'end'}} pr={1}>
            <Tooltip title={`Delete ${teamToRemove.team_name}`}>
                <RemoveCircleOutlineIcon sx={{fontSize:16}} cursor={'pointer'} color={'error'} onClick={handleClickOpen}/>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                { saving && <LinearProgress/> }
                <DialogTitle id="alert-dialog-title">
                    Delete Email
                </DialogTitle>
                <DialogContent>
                    <DialogContentText color={'error'}>{error}</DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete <b>{teamToRemove.team_name}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={saving}>Cancel</Button>
                    <Button onClick={handleDelete} autoFocus disabled={saving}>DELETE</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
