import { useEffect } from "react";
import React from 'react';

export default function PaypalLoginButton() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.paypalobjects.com/js/external/api.js';
        script.async = true;
        script.onload = () => {
            if (window.paypal) {
                window.paypal.use(['login'], function (login) {
                    login.render({
                        "appid": "AYRLKygvtk3g1uJanvZp_XeoZ6CtvgUciyUlw0gdfyAw-RQiCao2d31_VogGSGVHGfi-ruPeNYAErDPn",
                        "authend": "sandbox", // Use "live" for production
                        "scopes": "openid email",
                        "containerid": "lippButton",
                        "responseType": "code",
                        "locale": "en-us",
                        "buttonType": "LWP",
                        "buttonShape": "pill",
                        "buttonSize": "lg",
                        "fullPage": true,
                        "returnurl": "http://127.0.0.1:8040/paypal/callback"
                    });
                });
            }
        };
        script.onerror = () => {
            console.error('PayPal SDK failed to load.');
        };
        document.body.appendChild(script);

        // Cleanup the script from the DOM
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <span id='lippButton'></span>;
}
