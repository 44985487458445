import {Avatar, AvatarGroup, Box, Divider, Tooltip, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import MyDateCalendar from "../components/dashboard/Components/Calendar/MyDateCalendar";
import dayjs from "dayjs";
import * as React from "react";
import ScheduleTimePicker from "./ScheduleTimePicker";
import LoadingComponent from "./LoadingComponent";
import TimeZoneSelect from "./TimeZoneSelect";
import '../style/ScheduleCalendar.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useMediaQuery} from "react-responsive";
import ScheduleTimePickerMobile from "./ScheduleTimePickerMobile";
import {Helmet} from "react-helmet";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";



let utc = require('dayjs/plugin/utc')
let timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

async function getWorkingDays(path, eventGroup) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/users/working_days_user/${path}?event_group_id=${eventGroup}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json())
}

//TODO this is right function
/*async function getCalendarTimeSlots(path, start, end, eventGroup) {
    let start_time = new Date().getTime();
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/time_slots_user2/${path}?event_group_id=${eventGroup}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({start, end})
    }).then(data => {
        console.log(new Date().getTime() - start_time);
        return data.json()
    })
}*/


//TODO this is just for testing
async function getCalendarTimeSlots(path, start, end, eventGroup) {
    let start_time = new Date().getTime();
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/time_slots_user_test/${path}?event_group_id=${eventGroup}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({start, end})
    }).then(data => {
        console.log(new Date().getTime() - start_time);
        return data.json()
    })
}

async function getUserTemplate(path, event_group_id, id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/template_for_user/${path}/${event_group_id}/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json())
}

export default function ScheduleCalendar({
                                             path,
                                             event,
                                             selectedEvent,
                                             selectedTimezone,
                                             setSelectedTimezone,
                                             eventGroup, customLogo, showLogo, showName, customText, isMobile
                                         }) {
    const [firstRender, setFirstRender] = useState(true);
    const [workingDays, setWorkingDays] = useState([]);
    const [notWorkingDays, setNotWorkingDays] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [startDate, setStartDate] = useState(dayjs()?.startOf('month'));
    const [endDate, setEndDate] = useState(dayjs().endOf('month'));
    const [conflicts, setConflicts] = useState(null);
    const [timeSlots, setTimeSlots] = useState(null);
    const [rerenderCalendar, setRerenderCalendar] = useState(true);
    const [forDay, setForDay] = useState(null);
    const [template, setTemplate] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userPicture, setUserPicture] = useState(null);
    const [userBrand, setUserBrand] = useState(null);
    const [userName, setUserName] = useState(null);
    const [eventName, setEventName] = useState("");
    const [circleColor, setCircleColor] = useState(null);
    const [rerender, setRerender] = useState(false);
    const [rerenderTimePicker, setRerenderTimePicker] = useState(true);
    const [useTimezone, setUseTimezone] = useState(false);
    const [adminTimezone, setAdminTimezone] = useState(null);
    const [timezoneDifference, setTimezoneDifference] = useState(0);
    const [calendarLoading, setCalendarLoading] = useState(false);
    const [multipleUsers, setMultipleUsers] = useState(false);
    const [otherUsers, setOtherUsers] = useState(null);
    //timesDayBefore, timesDayAfter
    const [timesDayBefore, setTimesDayBefore] = useState(null);
    const [timesDayAfter, setTimesDayAfter] = useState(null);

    useEffect(() => {
        if(rerender){
            setForDay(forDay)
            setRerender(false);
        }
    });

    const changeTimeZone = (e) => {
        setSelectedTimezone(e);
        setForDay(null)
        setFirstRender(true);
    }

    const changedTimeZone = (e) => {
        const guessedTimezone = dayjs().tz(dayjs.tz.guess()).utcOffset();
        const adminUtcOffset = dayjs().tz(adminTimezone).utcOffset();

// Get the current UTC offset for userTimezone
        const userUtcOffset = dayjs().tz(e.value).utcOffset();

// Calculate the difference in hours
        const offsetDifferenceInHours = (adminUtcOffset - userUtcOffset) / 60;

        //console.log("Offset difference in hours:", offsetDifferenceInHours, e.value, guessedTimezone, adminUtcOffset);
        setTimezoneDifference(offsetDifferenceInHours + (guessedTimezone - adminUtcOffset)/60);
        //console.log("DIFFFF", (offsetDifferenceInHours + (guessedTimezone - adminUtcOffset)/60))
        setUseTimezone(true);
    }

    const setSelected = (e) => {
        handleMobileScroll()
        let nextDate = dayjs(e.clone()).add(1, 'day');
        let previousDate = dayjs(e.clone()).add(-1, 'day');
        //console.log({nextDate, previousDate});
        if (e) {
            setSelectedDate(e)
        } else {
            e = selectedDate;
        }
        setRerenderTimePicker(true)

        /*let combinedArray = [];

        let now = dayjs();

        let foundWorkingDay = workingDays.find(wd => {
            let day = wd.day === 7 ? 0 : wd.day;
            return day === e.day();
        })

        let hoursBefore = foundWorkingDay ? foundWorkingDay?.hours_before : 0;

        timeSlots?.occupied?.forEach(occ => {
            if (dayjs(occ?.start).date() === e.date()) {
                let startDate = new Date(occ?.start);
                let startdate = useTimezone ? dayjs(startDate).hour(dayjs(startDate).hour()-timezoneDifference) : dayjs(startDate);

                combinedArray.push({
                    start: startdate.format('HH:mm'),
                    occupied: true
                })

            }
        })

        timeSlots?.free?.forEach((free, index) => {
            if (dayjs(free?.start).date() === e.date()) {
                let startDate = new Date(free?.start);
                let startdate = useTimezone ? dayjs(startDate).hour(dayjs(startDate).hour()-timezoneDifference) : dayjs(startDate);
                let startDateForCheck = dayjs(startdate).add(timezoneDifference, 'hour');

                combinedArray.push({
                    start: startdate.format('HH:mm'),
                    occupied: startDateForCheck.isSame(now, 'date') &&
                        (
                            startDateForCheck.hour() < now.hour() + hoursBefore || // Check hours
                            (
                                startDateForCheck.hour() === now.hour() + hoursBefore &&
                                startDateForCheck.minute() <= now.minute() // Check minutes
                            )
                        )
                })
            }
        })

        // Sort the combined array by start time
        const sortedArray = combinedArray.sort((a, b) => (a?.start > b?.start) ? 1 : -1);
        let lastSlot = sortedArray[sortedArray.length-1];
        let timeSpit = lastSlot?.start.split(':');

        if(timeSpit && timeSpit?.length){
            sortedArray.push({ start: `${parseInt(timeSpit[0])+1}:00`, occupied: true })
        }*/


        setForDay(getForDay(e));
        setTimesDayBefore(getForDay(previousDate));
        setTimesDayAfter(getForDay(nextDate));
    }

    const getForDay = (e) => {
        let combinedArray = [];

        let now = dayjs();

        let foundWorkingDay = workingDays.find(wd => {
            let day = wd.day === 7 ? 0 : wd.day;
            return day === e.day();
        })

        let hoursBefore = foundWorkingDay ? foundWorkingDay?.hours_before : 0;

        timeSlots?.occupied?.forEach(occ => {
            if (dayjs(occ?.start).date() === e.date()) {
                let startDate = new Date(occ?.start);
                let startdate = useTimezone ? dayjs(startDate).hour(dayjs(startDate).hour()-timezoneDifference) : dayjs(startDate);

                combinedArray.push({
                    start: startdate.format('HH:mm'),
                    occupied: true
                })

            }
        })

        timeSlots?.free?.forEach((free, index) => {
            if (dayjs(free?.start).date() === e.date()) {
                let startDate = new Date(free?.start);
                let startdate = useTimezone ? dayjs(startDate).hour(dayjs(startDate).hour()-timezoneDifference) : dayjs(startDate);
                let startDateForCheck = dayjs(startdate).add(timezoneDifference, 'hour');

                combinedArray.push({
                    start: startdate.format('HH:mm'),
                    occupied: startDateForCheck.isSame(now, 'date') &&
                        (
                            startDateForCheck.hour() < now.hour() + hoursBefore || // Check hours
                            (
                                startDateForCheck.hour() === now.hour() + hoursBefore &&
                                startDateForCheck.minute() <= now.minute() // Check minutes
                            )
                        )
                })
            }
        })

        // Sort the combined array by start time
        const sortedArray = combinedArray.sort((a, b) => (a?.start > b?.start) ? 1 : -1);
        let lastSlot = sortedArray[sortedArray.length-1];
        let timeSpit = lastSlot?.start.split(':');

        //console.log({workingDays, day:e.day()});
        let selected_day = e.day() === 0 ? 7 : e.day();
        let working_day_data = workingDays.find(wd => wd.day === selected_day);
        console.log({ working_day_data });

        if (working_day_data) {
            let endTime = working_day_data.end_time;
            let hours = Math.floor(endTime); // Extract the hour part
            let minutes = (endTime % 1) * 60; // Convert fractional part to minutes

            let formattedEndTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`; // Format as HH:mm

            sortedArray.push({ start: formattedEndTime, occupied: true });
        }


        return sortedArray;
    }

    const setChangedMonth = (date) => {
        setCalendarLoading(true);
        setForDay(null);
        setStartDate(date);
        setEndDate(date.endOf('month'));
        getCalendarTimeSlots(path, date?.startOf('month').$d.getTime(), date.endOf('month').$d.getTime(), eventGroup).then(slots => {
            setTimeSlots(slots);
            setRerenderCalendar(true);
            setCalendarLoading(false);
        })
    }

    useEffect(() => {
        if (firstRender) {
            getWorkingDays(path, eventGroup).then(result => {
                let {working_days, not_working_days} = result;
                setWorkingDays(working_days);
                setNotWorkingDays(not_working_days?.map(day => {
                    return dayjs(day.date);
                }))
                getCalendarTimeSlots(path, startDate ? startDate.$d.getTime() : dayjs()?.startOf('month'), endDate ? endDate.$d.getTime() : dayjs().endOf('month'), eventGroup).then(slots => {
                    console.log({slots})
                    setTimeSlots(slots);
                    //console.log({slots});
                })
            })

            getUserTemplate(path, eventGroup, event).then(template => {
                console.log({template})
                if (template) {
                    setTemplate(template);
                    setUserEmail(template.email);
                    setUserPicture(template.picture);
                    setUserBrand(template.brand_image);
                    setUserName(template.username);
                    setEventName(template?.multiple_users ? template.event_name : template.username);
                    setCircleColor(hexToRgb(template.colour));
                    setAdminTimezone(template.timezone);
                    setMultipleUsers(template?.multiple_users);
                    setOtherUsers(template?.users);
                } else
                    window.location.pathname = `${path}`
            })
            setFirstRender(false);
        }
    }, []);

    function hexToRgb(hex) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    const goBack = () => {
        window.location.pathname = `${path}/${eventGroup}`
    }

    const handleMobileScroll = () => {
        const element = document.getElementById('scroll-holder');
        if (isMobile && element) {
            setTimeout(() => {
                element.scrollTop = element.scrollHeight;
            }, 700)
        }
    };

    if (!template) {
        return (
            <Box className='wrapper'>
                {/*<Box className='circle'/>*/}
                <LoadingComponent width={1} height={670}/>
            </Box>
        )
    }

    return (
        <Box className='wrapper'>
            <Helmet>
                <meta property="og:title" content={`Calendar - ${path}`}/>
                <title>Calendar - {path}</title>
            </Helmet>
            {/*<Box className='circle' sx={{
                background: `radial-gradient(50% 50% at 50% 50%, rgba(${circleColor.r}, ${circleColor.g}, ${circleColor.g}, 0.4) 0%, rgba(${circleColor.r}, ${circleColor.g}, ${circleColor.g}, 0) 87.5%)`
            }}/>*/}
            <Box className="event-types-calendar" id='scroll-holder'>
                <Box className='blur-effect'/>
                <Box className='header'>
                    {
                        !(showLogo) &&
                        <img src={customLogo ? customLogo : userBrand} alt="brand-pic" style={{cursor: 'pointer', height: 50, objectFit: 'contain'}}/>
                    }
                    {
                        !(showName) &&
                        <Box className='user-details'>
                            {
                                multipleUsers ?
                                    <AvatarGroup spacing={"small"} sx={{marginBlock:isMobile ? 0 : -1}} max={ isMobile ? 3 : 10} >
                                        <Tooltip title={userName}>
                                            <Avatar src={userPicture} name={userName}  sx={{width:isMobile ? 40 : 60, height:isMobile ? 40 : 60, zIndex:otherUsers.length}}/>
                                        </Tooltip>
                                        {
                                            otherUsers.map((user, index) =>
                                                <Tooltip title={user.name} key={user.user_id}>
                                                    <Avatar src={user.picture} name={user.name} sx={{width:isMobile ? 40 : 60, height:isMobile ? 40 : 60, zIndex:otherUsers.length-index}}/>
                                                </Tooltip>
                                            )
                                        }
                                    </AvatarGroup>
                                    :
                                    <Avatar src={userPicture} name={userName} sx={{width:60, height:60}}/>
                            }
                            <Typography sx={{color:'#FFF', fontSize:isMobile ? 18 : 24, fontFamily:'Poppins', zIndex:1000, width: isMobile ? 180 : 1, textAlign:'center'}} pl={2} pr={4}>{eventName}</Typography>

                        </Box>
                    }
                </Box>

                <Box className='holder-calendar'>
                    <Box className='event-color' sx={{background:template.colour}}></Box>
                    <Box className='calendar-timeselect-holder'>
                        {
                            (workingDays?.length > 0)
                            &&
                            <Box className='calendar-timezone'>
                                <Typography component="h3" variant='h5'><ArrowBackIosIcon style={{color:"#E5C518", paddingRight:2, cursor:'pointer'}} onClick={goBack}/> Select a Date & Time</Typography>
                                <TimeZoneSelect selectedTimezone={selectedTimezone} changedTimezone={changedTimeZone} setSelectedTimezone={changeTimeZone}/>
                                {
                                    timeSlots ?
                                    <MyDateCalendar
                                        calendarLoading={calendarLoading}
                                        start_day={1}
                                        end_day={5}
                                        not_working={notWorkingDays}
                                        working_days={workingDays}
                                        setSelected={setSelected}
                                        conflicts={conflicts}
                                        timeSlots={timeSlots}
                                        setChangedMonth={setChangedMonth}
                                        rerenderCalendar={rerenderCalendar}
                                        setRerenderCalendar={setRerenderCalendar}
                                    />
                                        :
                                        <Box sx={{minHeight:370}}>
                                            <Box className='checkbox-holder' sx={{height:40}}>
                                                <Typography variant='p' component='p' mb={1} className="animated-ellipsis">Fetching calendar data</Typography>
                                            </Box>
                                            <Box sx={{width:1, height:300, zIndex:1, display:'flex', alignItems:'center', justifyContent:'center', borderRadius:2, backdropFilter:'blur(9px)', background:'rgba(255,255,255,0.2)'}}>
                                                <CircularProgress style={{color:'#E5C518'}}/>
                                            </Box>
                                        </Box>

                                }


                            </Box>
                        }

                        {
                            forDay &&
                            (!isMobile ?
                            <ScheduleTimePicker rerenderComponent={rerenderTimePicker} setRerenderComponent={setRerenderTimePicker} setRerender={setRerender} times={forDay} setTimes={setForDay} selectedDate={selectedDate} path={path} event={event}
                                                duration={template.duration} eventGroup={eventGroup}
                                                selectedTimeZone={(selectedTimezone && selectedTimezone?.value) ? selectedTimezone?.value : dayjs.tz.guess()}
                                                adminTimezone={adminTimezone} timesDayAfter={timesDayAfter} timesDayBefore={timesDayBefore}></ScheduleTimePicker>
                            :
                            <ScheduleTimePickerMobile rerenderComponent={rerenderTimePicker} setRerenderComponent={setRerenderTimePicker} setRerender={setRerender} times={forDay} setTimes={setForDay} selectedDate={selectedDate} path={path} event={event}
                                                duration={template.duration} eventGroup={eventGroup}
                                                selectedTimeZone={(selectedTimezone && selectedTimezone?.value) ? selectedTimezone?.value : dayjs.tz.guess()}></ScheduleTimePickerMobile>)
                        }

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
