import {
    Box,
    Radio,
    FormControl,
    FormControlLabel,
    Grid,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AddAllowedEmail from "./AddAllowedEmail";
import CloseIcon from '@mui/icons-material/Close';
import DeleteAlertDialog from "./DeleteAlertDialog";



async function updateUser(allowed_email_id, email, is_admin, added_by, organization_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/allowed_emails`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({allowed_email_id, email, is_admin, added_by, organization_id})
    }).then(data => data.json())
}

export default function AllowedEmails({allowedEmails, setAllowedEmails, userEmail, organization, role, rerender}) {
    const [limitExceeded, setLimitExceeded] = useState(organization.organization_users_limit === null ? false : organization.organization_users_limit <= allowedEmails.length);
    console.log({limitExceeded, organization})

    useEffect(() => {
        setLimitExceeded(organization.organization_users_limit === null ? false : organization.organization_users_limit <= allowedEmails.length);
    }, [allowedEmails]);

    return (
        <Box>

            <Grid container spacing={2} mb={2}>
                <Grid item xs={0.5} >ID</Grid>
                <Grid item xs={4.25} >User email</Grid>
                <Grid item xs={1.5} >Admin</Grid>
                <Grid item xs={4.25} >Added by user</Grid>
                <Grid item xs={1.5} >Registered</Grid>
            </Grid>
            <Box sx={{overflow: 'auto', height:460}}>
            {
                allowedEmails.map((item, index) =>
                    <SingleAllowedEmail index={index} item={item} key={index} setAllowedEmails={setAllowedEmails} allowedEmails={allowedEmails} organization={organization} rerender={rerender} userEmail={userEmail}/>
                )
            }
            <Box>
                {
                    !limitExceeded ?
                        <AddAllowedEmail userEmail={userEmail} setAllowedEmails={setAllowedEmails} allowedEmails={allowedEmails} organization={organization} rerender={rerender}/>
                        :
                        <Typography mt={2} color={'error'} sx={{fontSize:14}}>User limit exceeded.</Typography>
                }

            </Box>
            </Box>
        </Box>
    )
}

function SingleAllowedEmail({item, allowedEmails, setAllowedEmails, organization, rerender, userEmail, index}) {

    const [id, setId] = useState(item.allowed_email_id);
    const [email, setEmail] = useState(item.email);
    const [isAdmin, setIsAdmin] = useState(item.is_admin === 1);
    const [addedBy, setAddedBy] = useState(item.added_by);
    const [organizationId, setOrganizationId] = useState(item.organization_id);
    const [registered, setRegistered] = useState(item.registered === 1);
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState(null);
    const [loggedUser, setLoggedUser] = useState(false);

    useEffect(() => {
        setId(item.allowed_email_id);
        setEmail(item.email);
        setIsAdmin(item.is_admin === 1);
        setAddedBy(item.added_by);
        setOrganizationId(item.organization_id);
        setRegistered(item.registered === 1);
        setLoggedUser(userEmail === item.email);
    }, [item]);

    useEffect(() => {
         if(update) {
             updateUser(id, email, isAdmin, addedBy, organizationId).then(result => {
                 if(result.state) {
                     item.email = email;
                     item.is_admin = isAdmin ? 1 : 0;
                     setError(null);
                 } else {
                     setError(result.error);
                 }
             }).catch(() => {
                 setError("Error on email update");
             })
             setUpdate(false);

        }

    }, [update]);

    return (
        <Grid container spacing={0} my={1} py={0.5} sx={{fontSize:14, position:'relative', background: index % 2 === 0 ? 'rgba(0,0,0,0.1)' : 'none'}}>
            {
                error && <Grid item xs={12} sx={{fontSize:10, color:'red'}}>{error}</Grid>
            }

            <Grid item xs={0.5} pl={1} my={'auto'}>{id}</Grid>
            <Grid item xs={4.25} pl={1} my={'auto'}><Email email={email} disable={registered} setEmail={setEmail} setUpdate={setUpdate}/> </Grid>
            <Grid item xs={1.5} pl={1} my={'auto'}><IsAdmin isAdmin={isAdmin} setIsAdmin={setIsAdmin} setUpdate={setUpdate} disabled={loggedUser}/></Grid>
            <Grid item xs={4.25} pl={1} my={'auto'}>{addedBy ? addedBy : 'Not defined'}</Grid>
            <Grid item xs={1} pl={2} my={'auto'}>{registered ? 'true' : 'false'}</Grid>
            <Grid item xs={0.5} my={'auto'}>{!registered && <DeleteAlertDialog userToRemove={item} setAllowedEmails={setAllowedEmails} allowedEmails={allowedEmails} rerender={rerender}/>}</Grid>

        </Grid>
    )
}

function Email({email, setEmail, disable, setUpdate}) {

    const [edit, setEdit] = useState(false);
    const [textValue, setTextValue] = useState(email);

    const resetText = () => {
        setEdit(!edit);
        setTextValue(email);
    }

    const saveEmail = () => {
        setUpdate(true);
        setEmail(textValue);
        setEdit(!edit);
    }

    return (
        <Box sx={{display:'flex', position:'relative', alignItems:'center'}}>
            {
                edit ?
                    <TextField size='small' variant="standard" sx={{fontSize:14, padding:0}} value={textValue} onChange={(e) => setTextValue(e.target.value)}/>
                    :
                    <Typography sx={{fontSize:14}}>{email}</Typography>
            }

            {
                !disable ?
                    !edit ?
                    <EditIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                        :
                    <Box sx={{display: 'flex', alignItems:'center'}}>
                        <CheckIcon onClick={saveEmail} sx={{fontSize:14, position: 'absolute', right:20, cursor:'pointer'}}/>
                        <ClearIcon onClick={resetText} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                    </Box>
                    :
                    ""
            }

        </Box>
    )
}

function IsAdmin({isAdmin, setIsAdmin, setUpdate, disabled}) {

    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(isAdmin ? 'admin' : 'user');

    const saveIsAdmin = (event) => {
        setValue(event.target.value);
        setIsAdmin(event.target.value === 'admin');
        setUpdate(true);
        setEdit(!edit);
    }

    return (
        <Box sx={{display:'flex', position:'relative', alignItems:'center'}}>
            {
                edit ?
                    <FormControl sx={{boxShadow:'0 4px 8px rgba(0, 0, 0, 0.2)', paddingX:1, borderRadius:2}}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={saveIsAdmin}
                        >
                            <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontSize: '12px', marginLeft:-1 } }} value="admin" control={<Radio size="small" />} label="Admin"/>
                            <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontSize: '12px', marginLeft:-1 } }} value="user" control={<Radio size="small"/>} label="User"/>
                        </RadioGroup>
                    </FormControl>
                    :
                    <Typography sx={{fontSize:14}}>{isAdmin ? 'true' : 'false'}</Typography>
            }

            {
                disabled ?
                    null :
                (!edit)
                    ?
                    <EditIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                    :
                    <CloseIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:1, top:1, cursor:'pointer'}}/>
            }

        </Box>
    )
}

