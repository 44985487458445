import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddIcon from "@mui/icons-material/Add";
import {
    Box,
    LinearProgress,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel, NativeSelect
} from "@mui/material";
import {useState} from "react";
import LoginToken from "../../../../local_storage/LoginToken";

async function addUserEmail(email, is_admin, added_by, organization_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/allowed_emails`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email, is_admin, added_by, organization_id})
    }).then(data => data.json())
}

async function addNewOrganization(accessToken, organization_name, organization_users_limit) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/organization`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        body: JSON.stringify({organization_name, organization_users_limit})
    }).then(data => data.json())
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuperAdminAddAllowedEmail({userEmail, allowedEmails, setAllowedEmails, organization, allOrganizations, setAllOrganizations, rerender}) {
    const [open, setOpen] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [userType, setUserType] = useState(''); // State for radio button selection
    const [emailError, setEmailError] = useState(false); // State for email validation error
    const [userTypeError, setUserTypeError] = useState(false); // State for radio button selection error
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [selectedOrganization, setSelectedOrganization] = useState(organization);
    const [disableSave, setDisableSave] = useState(false);

    const handleNewEmail = (e) => {
        setNewEmail(e.target.value);
        setEmailError(false); // Reset error when typing
    };

    const handleUserTypeChange = (e) => {
        setUserType(e.target.value);
        setUserTypeError(false); // Reset error when selecting
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
        return emailRegex.test(email);
    };

    const handleSave = () => {
        if (!validateEmail(newEmail)) {
            setEmailError(true);
        } else if (!userType) {
            setUserTypeError(true);
        } else if(allowedEmails.find(user => user.email === newEmail)){
            setError('Email already exists.');
        } else {
            setSaving(true);
            addUserEmail(newEmail, userType === 'admin', userEmail, selectedOrganization.organization_id).then(result => {
                console.log(result);
                if(result?.state) {
                    let newArray = [...allowedEmails];
                    newArray.push(result.added);
                    setAllowedEmails(newArray);
                    setError(null);
                    setOpen(false);
                    setSaving(false);
                    rerender(true);
                } else {
                    setError(result?.error ? result.error : 'Error on adding user.');
                    setSaving(false);
                }
            })

            // Save logic
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setNewEmail('');
        setUserType('');
        setError(null);
        setSaving(false);
        setOpen(false);
    };

    return (
        <Box>
            <Button startIcon={<AddIcon/>} size={'small'} color={'success'} onClick={handleClickOpen}>
                add new email
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>

                    { saving && <LinearProgress/> }

                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Add new user email
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        '& > :not(style)': { m: 1, width: 500 },
                        textAlign:'center',
                        display:'flex',
                        flexDirection:'column',
                        margin:'40px auto',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography color={'error'}>{error}</Typography>
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Typography component='h2' variant='h4'>Add New Email</Typography>
                    </Box>
                    <TextField
                        disabled={saving}
                        type='email'
                        value={newEmail}
                        onChange={handleNewEmail}
                        error={emailError}
                        helperText={emailError ? "Please enter a valid email" : ""}
                        id="name"
                        label="Email"
                        variant="outlined"
                        required
                    />
                    <RadioButtons userType={userType} onUserTypeChange={handleUserTypeChange} userTypeError={userTypeError} disabled={saving}/>
                    <OrganizationSelect
                        organization_id={1} allOrganizations={allOrganizations} setAllOrganizations={setAllOrganizations}
                        selectedOrganization={selectedOrganization} setSelectedOrganization={setSelectedOrganization} setDisableSave={setDisableSave}
                    />
                    <Button disabled={disableSave} type='submit' variant='contained' onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Dialog>
        </Box>
    );
}

function RadioButtons({ userType, onUserTypeChange, userTypeError, disabled }) {
    return (
        <FormControl sx={{display:'flex'}} error={userTypeError} disabled={disabled}>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={userType}
                onChange={onUserTypeChange}
            >
                <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                <FormControlLabel value="user" control={<Radio />} label="User" />
            </RadioGroup>
            {userTypeError && <Typography color="error">Please select a user type</Typography>}
        </FormControl>
    );
}

function OrganizationSelect({ allOrganizations, setAllOrganizations, selectedOrganization, setSelectedOrganization, setDisableSave }) {
    const [openAddOrg, setOpenAddOrg] = useState(false);
    const [newOrgName, setNewOrgName] = useState('');
    const [newOrgLimit, setNewOrgLimit] = useState(null);
    const [error, setError] = useState(null);
    let {getToken} = LoginToken();

    const handleChange = (e) => {
        setSelectedOrganization(allOrganizations.find(org => org.organization_id === parseInt(e.target.value)));
    }

    const addOrganization = () => {
        if(allOrganizations.find(org => org.organization_name === newOrgName)) {
            setError("Organization with this name already exists")
        } else {
            addNewOrganization(getToken()?.token, newOrgName, newOrgLimit).then(result => {
                if(result.state) {
                    let currentOrganizations = [...allOrganizations];
                    currentOrganizations.push(result.added);
                    setAllOrganizations(currentOrganizations);
                    setSelectedOrganization(result.added);
                    setError(null);
                    toggleOpenAddOrg();
                } else {
                    setError(result?.error ? result.error : 'Error on adding new oraganization')
                }
            })
        }

    }

    const toggleOpenAddOrg = () => {
        setDisableSave(!openAddOrg)
        setOpenAddOrg(!openAddOrg);
    }

    return (
        <Box sx={{ minWidth: 200 }}>
            <Box sx={{width:1, display:'flex', justifyContent:'space-between', alignItems:'center'}} mb={1}>
                <Typography>Organization</Typography>
                <Button color={'success'} startIcon={<AddIcon/>} onClick={toggleOpenAddOrg}>add organization</Button>
            </Box>
            {
                openAddOrg ?
                <Box my={2}>
                    {
                        error && <Typography color={'error'} sx={{textAlign:'start', marginBottom:1, fontSize:12}}>{error}</Typography>
                    }
                    <FormControl fullWidth >
                        <TextField
                            size={'small'}
                            value={newOrgName}
                            type='text'
                            id="name"
                            label="Organizaiton name"
                            variant="outlined"
                            required
                            onChange={(e) => setNewOrgName(e.target.value)}
                        />
                        <TextField
                            sx={{marginTop:1}}
                            size={'small'}
                            value={newOrgLimit}
                            type='number'
                            id="name"
                            label="User Limit"
                            variant="outlined"
                            required
                            onChange={(e) => setNewOrgLimit(parseInt(e.target.value))}
                        />
                        <Button type='submit' variant='contained' onClick={addOrganization} sx={{marginTop:1}}>
                            Save organization
                        </Button>
                    </FormControl>
                </Box>
                :
                <FormControl fullWidth size={'small'} onChange={handleChange}>
                    <NativeSelect
                        value={selectedOrganization.organization_id}
                        inputProps={{
                            name: 'organization',
                            id: 'uncontrolled-native',
                        }}
                    >
                        {
                            allOrganizations.map(org => (
                                <option key={org.organization_id} value={org.organization_id}>
                                    {org.organization_name}
                                </option>
                            ))
                        }
                    </NativeSelect>
                </FormControl>
            }


        </Box>
    );
}


