import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AllowedEmails from "./AdminPanel/AllowedEmails";
import {useEffect, useState} from "react";
import OrganizationTeams from "./AdminPanel/OrganizationTeams";
import LoginToken from "../../../local_storage/LoginToken";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </Box>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const tabStyle = {
    '&.Mui-selected': {
        backgroundColor: '#FFF !important', // Selected background color
        filter: "none !important"
    },
}

async function getTeams(accessToken) {
    return fetch(process.env.REACT_APP_SERVER_URL + '/organization_teams/for_organization', {
        method: 'GET',
        headers: { 'Authorization': accessToken },
    }).then(data => data.json());
}

export default function AdminPanel({allowedEmails, setAllowedEmails, userEmail, organization, role, rerender}) {
    const [value, setValue] = React.useState(0);
    const [teamsEnables, setTeamsEnables] = useState(organization.enable_teams);
    const [teams, setTeams] = useState([]);
    const [firstRender, setFirstRender] = useState(true);
    let {getToken} = LoginToken();


    useEffect(() => {
        if(firstRender) {
            getTeams(getToken()?.token).then(result => {
                if(result.status) {
                    setTeams(result.teams);
                }
                console.log(result);
            })
            setFirstRender(false);
        }
    }, [firstRender]);
    useEffect(() => {
        setTeamsEnables(organization.enable_teams)
    }, [organization]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', flexDirection: 'column' }}>
            <Box sx={{ width: 800, height: 600, overflowY: 'auto', background: "#FFF", borderRadius: 2, position: 'relative', filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))' }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab
                                label="Allowed Emails"
                                {...a11yProps(0)}
                                sx={tabStyle}
                            />
                            {
                                teamsEnables &&
                                <Tab
                                    label="Teams"
                                    {...a11yProps(1)}
                                    sx={tabStyle}
                                />
                            }
                            {/*<Tab
                                label="Item Three"
                                {...a11yProps(2)}
                                sx={tabStyle}
                            />*/}
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <AllowedEmails userEmail={userEmail} allowedEmails={allowedEmails} setAllowedEmails={setAllowedEmails} organization={organization} role={role} rerender={rerender}/>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {
                            teamsEnables &&
                            <OrganizationTeams organization={organization} teams={teams} rerender={rerender} setTeams={setTeams}/>
                        }
                    </CustomTabPanel>
                    {/*<CustomTabPanel value={value} index={2}>
                        Item Three
                    </CustomTabPanel>*/}
                </Box>
            </Box>
        </Box>
    );
}
