import {Typography,Box,Paper} from "@mui/material";
import * as React from "react";
import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingComponent({width, height, min_height}){
    return(
        <Box className='loader-box' sx={{flexWrap:'center', margin:'20px auto', display:'flex', justifyContent:'center', alignItems:'center', height, width, minHeight: min_height ? min_height : '300px', flexDirection:'column'}}>
            <CircularProgress style={{color:'#E5C518'}}/>
        </Box>
    )
}
