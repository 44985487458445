import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle, FormControlLabel,
    IconButton,
    Snackbar,
    TextField,
    Tooltip
} from '@mui/material'
import {useEffect, useState} from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import * as React from "react";
import Slide from "@mui/material/Slide";
import LaptopIcon from '@mui/icons-material/Laptop';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EmbedButton ({text, height, variant, name, color}){
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true)
    }

    return (
        <>
            <Button size={'small'} variant={variant} color={color} sx={{height, borderRadius:'5px', textAlign: 'center'}} onClick={handleClick} >{name}<OpenInNewIcon sx={{ml:1}}/></Button>
            <EmbedCalendarDialog open={open} setOpen={setOpen} embedSrc={text}/>
        </>
    )
}

function EmbedCalendarDialog ({open, setOpen, embedSrc}) {

    const [width, setWidth] = useState("100%");
    const [height, setHeight] = useState('100%');
    const [src, setSrc] = useState(embedSrc);
    const [backgroundColor, setBackgroundColor] = useState("#808080");;

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullScreen TransitionComponent={Transition}>
            <DialogHeader setOpen={setOpen} width={width} setWidth={setWidth} height={height} setHeight={setHeight} backgroundColor={backgroundColor} setBackgroundColor={setBackgroundColor}/>
            <Box width={1} height={1} sx={{display: 'flex', justifyContent:'center', position:'relative'}}>
                <iframe style={{width, height, margin:'auto auto', borderRadius:"8px", backgroundColor}} src={src}/>
            </Box>
            <DialogDetails embedSrc={embedSrc} src={src} setSrc={setSrc} width={width} height={height}/>
        </Dialog>
    )
}

function DialogDetails ({embedSrc, src, setSrc, width, height}) {
    const [enableBackground, setEnableBackground] = useState(false);
    const [enableLogo, setEnableLogo] = useState(true);
    const [enableName, setEnableName] = useState(true);
    const [background, setBackground] = useState(null);
    const [customLogo, setCustomLogo] = useState(null);
    const [text, setText] = useState('Welcome to my scheduling page. Please follow the instructions to add an event to my calendar.');
    const [iframeWidth, setIframeWidth] = useState(width);
    const [iframeHeight, setIframeHeight] = useState(height);
    const [minHeight, setMinHeight] = useState(800);

    useEffect(() => {
        setIframeWidth(width);
        setIframeHeight(height);
    }, [width, height]);

    const handleBackgroundChange = () => {
        let currentSrc = embedSrc;
        if(enableBackground) {
            currentSrc = `${embedSrc}?background=${background === '' ? 'none' : background}`;
        }
        setSrc(currentSrc)
        return currentSrc;
    }

    const handleShowLogoChange = (currentSrc) => {
        let char = currentSrc !== embedSrc ? '&' : '?'
        if(!enableLogo) {
            currentSrc += `${char}showLogo=${enableLogo}`
            setSrc(currentSrc);
            setMinHeight(730)
        } else {
            setMinHeight(800)
        }
        return currentSrc
    }

    const handleShowNameChange = (currentSrc) => {
        let char = currentSrc !== embedSrc ? '&' : '?'
        if(!enableName) {
            currentSrc += `${char}showName=${enableName}`
            setSrc(currentSrc);
            setMinHeight(!enableLogo ? 660 : 730)
        } else {
            setMinHeight(!enableLogo ? 750 : 800)
        }
        return currentSrc
    }

    const handleCustomLogoUrlChange = (currentSrc) => {
        let char = currentSrc !== embedSrc ? '&' : '?'
        if(customLogo && customLogo !== '') {
            currentSrc += `${char}customLogo=${customLogo}`
            setSrc(currentSrc);
        }
        return currentSrc
    }

    const handleCustomTextChange = (currentSrc) => {
        let char = currentSrc !== embedSrc ? '&' : '?'
        if(text && text !== 'Welcome to my scheduling page. Please follow the instructions to add an event to my calendar.') {
            currentSrc += `${char}customText=${text}`
            setSrc(currentSrc);
        } else if (text === '') {
            currentSrc += `${char}customText=Welcome to my scheduling page. Please follow the instructions to add an event to my calendar.`
            setSrc(currentSrc);
        }
        return currentSrc
    }

    const handleSave = () => {
        let currentSrc = handleBackgroundChange();
        currentSrc = handleShowLogoChange(currentSrc);
        currentSrc = handleShowNameChange(currentSrc);
        currentSrc = handleCustomLogoUrlChange(currentSrc);
        handleCustomTextChange(currentSrc);
    }

    return (
        <Box sx={{width:350, background:'#FFF', borderRadius:2, padding:2, filter:"drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.15))", position:'absolute', top:100, left:50, display:'flex', flexDirection:'column', gap:1}}>
            <Typography variant={'h6'}>Details</Typography>
            <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap:1}}>
                <TextField onChange={(e) => setBackground(e.target.value)} disabled={!enableBackground} label={'Background'} fullWidth size={'small'}/>
                <FormControlLabel control={<Checkbox checked={!enableBackground} onChange={() => setEnableBackground(!enableBackground)} />} label="Default" />
            </Box>
            <FormControlLabel control={<Checkbox checked={enableLogo} onChange={() => setEnableLogo(!enableLogo)} />} label="Show Logo" />
            <TextField disabled={!enableLogo} value={customLogo} onChange={(e) => setCustomLogo(e.target.value)} label={'Custom Logo URL'} fullWidth size={'small'}/>

            <FormControlLabel control={<Checkbox checked={enableName} onChange={() => setEnableName(!enableName)} />} label="Show Name" />
            <TextField multiline={true} value={text} onChange={(e) => setText(e.target.value)} placeholder={'Text Under Name'} fullWidth size={'small'}/>
            <Button color={'success'} fullWidth variant={'contained'} size={'small'} onClick={handleSave}>Save</Button>

            <Typography mt={1}>Embed size:</Typography>
            <Box sx={{display:'flex', gap:1}}>
                <TextField value={iframeWidth} onChange={(e) => setIframeWidth(e.target.value)} label={'Width'} fullWidth size={'small'}/>
                <TextField value={iframeHeight} onChange={(e) => setIframeHeight(e.target.value)} label={'Height'} fullWidth size={'small'}/>
            </Box>
            <Typography>Code:</Typography>
            <Box
                component="div"
                sx={{
                    whiteSpace: 'normal',
                    border: '1px solid gray',
                    borderRadius: 1,
                    padding: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all'
                }}
            >
                <code>{`<iframe style="width:${iframeWidth}; height:${iframeHeight}; min-height: ${minHeight}px;" src="${src}"/>`}</code>
            </Box>
        </Box>
    )
}

function DialogHeader ({setOpen, width, setWidth, height, setHeight, backgroundColor, setBackgroundColor}) {
    return (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Embed
                </Typography>
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap:2}} width={1}>
                    <Tooltip title={'Current'}>
                        <PersonalVideoIcon sx={{cursor:'pointer'}}
                                           onClick={() => {
                                               setWidth("100%");
                                               setHeight("100%");
                                           }}
                        />
                    </Tooltip>

                    <Tooltip title={'Laptop'}>
                        <LaptopIcon sx={{cursor:'pointer'}}
                                    onClick={() => {
                                        setWidth('1440px');
                                        setHeight('1080px');
                                    }}
                        />
                    </Tooltip>

                    <Tooltip title={'Tablet'}>
                        <TabletAndroidIcon sx={{cursor:'pointer'}}
                                           onClick={() => {
                                               setWidth('800px');
                                               setHeight('1024px');
                                           }}
                        />
                    </Tooltip>

                    <Tooltip title={'Mobile'}>
                        <StayCurrentPortraitIcon sx={{cursor:'pointer'}}
                                                 onClick={() => {
                                                     setWidth('375px');
                                                     setHeight('667px');
                                                 }}
                        />
                    </Tooltip>
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap:1}}>
                        <TextField
                            sx={{width:100}}
                            InputProps={{
                                style: {
                                    color: '#FFF'  // Set the text color here
                                }
                            }}
                            label={'Width'} size={'small'} value={width} onChange={(e) => setWidth(e.target.value)}/>
                        <TextField
                            sx={{width:100}}
                            InputProps={{
                                style: {
                                    color: '#FFF'  // Set the text color here
                                }
                            }}
                            label={'Height'} size={'small'} value={height} onChange={(e) => setHeight(e.target.value)}/>
                        <TextField
                            sx={{width:150}}
                            InputProps={{
                                style: {
                                    color: '#FFF'  // Set the text color here
                                }
                            }}
                            label={'Background'} size={'small'} value={backgroundColor} onChange={(e) => setBackgroundColor(e.target.value)}/>
                    </Box>
                </Box>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setOpen((false))}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}
