import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ToggleLiveSandbox({account, setAccount}) {

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAccount(newAlignment);
        }
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={account}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
        >
            <ToggleButton value={1}>Sandbox</ToggleButton>
            <ToggleButton value={2}>Live</ToggleButton>
        </ToggleButtonGroup>
    );
}
