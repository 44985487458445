import {Box, Button, Typography} from "@mui/material";
import {useEffect} from "react";
import {useState} from "react";
import LoginToken from "../../../local_storage/LoginToken";
import {useNavigate} from "react-router-dom";

async function saveStripeCredentials(stripe_account_id, payment_environment_id, accessToken) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/stripe/database`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        body: JSON.stringify({stripe_account_id, payment_environment_id})
    }).then(data => {
        return data.json()
    })
}

export default function ConnectStripe() {


    let {getToken} = LoginToken();
    const [firstRender, setFirstRender] = useState(true);
    const [stripeUserId, setStripeUserId] = useState(null);
    const [paymentEnvironmentId, setPaymentEnvironmentId] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);

    const navigate = useNavigate('/?error=test');

    const handleRedirectToLogin = () => {
        navigate('/login');
    }

    useEffect(() => {
        if(firstRender) {
            setFirstRender(false);
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if(!getToken() && !getToken().token && getToken().expire < new Date().getTime()) {
                return setError('Your session expired.')
            }
            if(!urlParams.get('stripe_id') || urlParams.get('stripe_id') === '') {
                return setError(urlParams.get('error') ? urlParams.get('error') : 'Error on getting stripe id')
            }
            if(!urlParams.get('payment_environment_id') || urlParams.get('payment_environment_id') === '') {
                return setError(urlParams.get('error') ? urlParams.get('error') : 'Error on receiving payment environment id')
            }
            setPaymentEnvironmentId(urlParams.get('payment_environment_id'))
            setStripeUserId(urlParams.get('stripe_id'));
            saveStripeCredentials(urlParams.get('stripe_id'), urlParams.get('payment_environment_id'), getToken().token).then(saved => {
                console.log({saved});
                if(saved.state) {
                    setMessage("Stripe credentials connected. Redirecting...");
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 1000)
                } else {
                    setError("Stripe credentials not connected")
                }
                setLoading(false);
            }).catch(err => {
                setError(err?.error ? err.error : "Error oon saving stripe credentials");
                setLoading(false);
            })
        }
    }, [firstRender]);

    return (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}>
            {
                error
                ?
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <Typography color={'error'}>{error}</Typography>
                    <Typography>Please login and connect stripe again.</Typography>
                    <Button onClick={handleRedirectToLogin}>Login</Button>
                </Box>
                :
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    {
                        loading ? <Typography>Connecting stripe...</Typography> : <Typography>Connect Stripe</Typography>
                    }

                </Box>
            }
        </Box>
    )
}
