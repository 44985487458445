import LoginToken from "../../../../local_storage/LoginToken";
import {useEffect, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import LoadingComponent from "../../../../UserScheduleComponents/LoadingComponent";
import TimeSelect from "./TimeSelect";
import * as React from "react";
import HoursBeforeSelect from "./HoursBeforeSelect";

async function saveWorkingDays(token, update, add, remove) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/users/working_days`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, update, add, remove})
    }).then(data => data.json())
}

export default function WorkingDaysForGroup({days, rerender, setRerender, isMobile}){
    const { getToken, tokenData } = LoginToken();
    const [firstRender, setFirstRender] = useState(true);
    const [workingDays, setWorkingDays] = useState(null);
    const [dbWorkingDays, setDbWorkingDays] = useState(null);
    const [rerenderSelect, setRerenderSelect] = useState(false);
    const [loading, setLoading] = useState(true);

    const [monday, setMonday] = useState(null);
    const [tuesday, setTuesday] = useState(null);
    const [wednesday, setWednesday] = useState(null);
    const [thursday, setThursday] = useState(null);
    const [friday, setFriday] = useState(null);
    const [saturday, setSaturday] = useState(null);
    const [sunday, setSunday] = useState(null);
    const [userId, setUserId] = useState(tokenData().user_id);

    useEffect(() => {
        if(rerender){
            setRerenderSelect(true)
            setRerender(false);
            setFirstRender(true)
        }
    });

    useEffect(() => {
        if(rerenderSelect){
            workingDays.forEach(working_day => {
                if(!userId) setUserId(tokenData().user_id)
                switch (working_day.day){
                    case 1: setMonday(working_day); break;
                    case 2: setTuesday(working_day); break;
                    case 3: setWednesday(working_day); break;
                    case 4: setThursday(working_day); break;
                    case 5: setFriday(working_day); break;
                    case 6: setSaturday(working_day); break;
                    case 7: setSunday(working_day); break;
                }
            })
            setRerenderSelect(false)
        }
    });

    useEffect(() => {
        if(firstRender){
            setMonday(null);
            setTuesday(null);
            setWednesday(null);
            setThursday(null);
            setFriday(null);
            setSaturday(null);
            setSunday(null);
                console.log(days.working_days)
                setWorkingDays(days.working_days);
                setDbWorkingDays([...days.working_days]);
                days.working_days.forEach(working_day => {
                    if(!userId) setUserId(tokenData().user_id)
                    switch (working_day.day){
                        case 1: setMonday(working_day); break;
                        case 2: setTuesday(working_day); break;
                        case 3: setWednesday(working_day); break;
                        case 4: setThursday(working_day); break;
                        case 5: setFriday(working_day); break;
                        case 6: setSaturday(working_day); break;
                        case 7: setSunday(working_day); break;
                    }
                })
                setLoading(false);
            setFirstRender(false);
        }
    });

    const save = () => {
        setLoading(true)
        const removedWorkingDays = dbWorkingDays.filter(obj => !workingDays.some(dayObj => dayObj.day === obj.day));
        const removedIds = removedWorkingDays.map(rm => rm.user_working_day_id);
        const updatedWorkingDays = workingDays.filter(obj => obj?.user_working_day_id);
        const addedWorkingDays = workingDays.filter(obj => !obj?.user_working_day_id);
        const addedWorkingDaysFormatted = addedWorkingDays.map(aw => [aw.day, aw.start_time, aw.end_time, aw.user_id, days.id, aw.hours_before]);
        saveWorkingDays(getToken().token, updatedWorkingDays, addedWorkingDaysFormatted, removedIds).then(savedWorkingDays => {
            //console.log({savedWorkingDays})
            setFirstRender(true)
        })
        //console.log({workingDays, dbWorkingDays, removedWorkingDays, updatedWorkingDays, addedWorkingDays,addedWorkingDaysFormatted})
    }

    if(loading){
        return (
            <Box sx={{display:'flex', alignItems:'center'}}>
                {/*<Box className='circle'/>*/}
                <LoadingComponent width={ isMobile ? 1 : 640} height={670}/>
            </Box>
        )
    }

    return (
        <Box className="event-types-admin" sx={{justifyContent:"start", margin:0, width: isMobile ? 1 : 640}}>
            <Box sx={{margin:"0 0 30px 10px", display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Typography sx={{color:"#131313", fontWeight:600}}>Availability:</Typography>
            </Box>

            {
                monday ?
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Monday:</Typography>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <TimeSelect text={'Start At:'} value={monday ? monday?.start_time : 6} changeValue={(e) => {
                                if(e.target.value < monday.end_time){
                                    monday.start_time = e.target.value;
                                    setMonday(monday);
                                    workingDays.find(wd => wd.day === 1).start_time = e.target.value
                                    setRerenderSelect(true);
                                }
                            }} disabled={!(monday)}/>
                            <TimeSelect text={'End At:'} value={monday ? monday?.end_time : 6} changeValue={(e) => {
                                if(e.target.value > monday.start_time){
                                    monday.end_time = e.target.value;
                                    setMonday(monday);
                                    workingDays.find(wd => wd.day === 1).end_time = e.target.value
                                    setRerenderSelect(true);
                                }

                            }} disabled={!(monday)}/>
                            <HoursBeforeSelect text={'Hours before:'} value={monday?.hours_before ? monday?.hours_before : 0} changeValue={(e) => {
                                monday.hours_before = e.target.value;
                                setMonday(monday);
                                workingDays.find(wd => wd.day === 1).hours_before = e.target.value
                                setRerenderSelect(true);
                            }} disabled={!(monday)}/>
                            <Button onClick={() => {
                                workingDays.forEach((wd, index) => {
                                    if(wd.day === 1) workingDays.splice(index, 1)
                                })
                                setWorkingDays(workingDays);
                                setMonday(null);
                                setRerenderSelect(true)
                            }} size={'small'} variant={'contained'} color={'error'}>delete</Button>
                        </Box>
                    </Box>
                    :
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Monday</Typography>
                        <Button onClick={() => {
                            workingDays.push({
                                start_time: 6,
                                end_time: 14,
                                user_id: userId,
                                day:1,
                                hours_before: 0
                            })
                            setWorkingDays(workingDays);
                            setRerenderSelect(true)
                        }} size={'small'} variant={'contained'} color={'success'}>Add</Button>
                    </Box>
            }

            {
                tuesday ?
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Tuesday:</Typography>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <TimeSelect text={'Start At:'} value={tuesday ? tuesday?.start_time : 6} changeValue={(e) => {
                                if(e.target.value < tuesday.end_time){
                                    tuesday.start_time = e.target.value;
                                    setThursday(tuesday);
                                    workingDays.find(wd => wd.day === 2).start_time = e.target.value
                                    setRerenderSelect(true);
                                }
                            }} disabled={!(tuesday)}/>
                            <TimeSelect text={'End At:'} value={tuesday ? tuesday?.end_time : 6} changeValue={(e) => {
                                if(e.target.value > tuesday.start_time){
                                    tuesday.end_time = e.target.value;
                                    setThursday(tuesday);
                                    workingDays.find(wd => wd.day === 2).end_time = e.target.value
                                    setRerenderSelect(true);
                                }

                            }} disabled={!(tuesday)}/>
                            <HoursBeforeSelect text={'Hours before:'} value={tuesday?.hours_before ? tuesday?.hours_before : 0} changeValue={(e) => {
                                tuesday.hours_before = e.target.value;
                                setThursday(tuesday);
                                workingDays.find(wd => wd.day === 2).hours_before = e.target.value
                                setRerenderSelect(true);
                            }} disabled={!(tuesday)}/>
                            <Button onClick={() => {
                                workingDays.forEach((wd, index) => {
                                    if(wd.day === 2) workingDays.splice(index, 1)
                                })
                                setWorkingDays(workingDays);
                                setTuesday(null);
                                setRerenderSelect(true)
                            }} size={'small'} variant={'contained'} color={'error'}>delete</Button>
                        </Box>
                    </Box>
                    :
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Tuesday</Typography>
                        <Button onClick={() => {
                            workingDays.push({
                                start_time: 6,
                                end_time: 14,
                                user_id: userId,
                                day:2,
                                hours_before: 0
                            })
                            setWorkingDays(workingDays);
                            setRerenderSelect(true)
                        }} size={'small'} variant={'contained'} color={'success'}>Add</Button>
                    </Box>
            }

            {
                wednesday ?
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Wednesday:</Typography>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <TimeSelect text={'Start At:'} value={wednesday ? wednesday?.start_time : 6} changeValue={(e) => {
                                if(e.target.value < wednesday.end_time){
                                    wednesday.start_time = e.target.value;
                                    setWednesday(wednesday);
                                    workingDays.find(wd => wd.day === 3).start_time = e.target.value
                                    setRerenderSelect(true);
                                }
                            }} disabled={!(wednesday)}/>
                            <TimeSelect text={'End At:'} value={wednesday ? wednesday?.end_time : 6} changeValue={(e) => {
                                if(e.target.value > wednesday.start_time){
                                    wednesday.end_time = e.target.value;
                                    setWednesday(wednesday);
                                    workingDays.find(wd => wd.day === 3).end_time = e.target.value
                                    setRerenderSelect(true);
                                }

                            }} disabled={!(wednesday)}/>
                            <HoursBeforeSelect text={'Hours before:'} value={wednesday?.hours_before ? wednesday?.hours_before : 0} changeValue={(e) => {
                                wednesday.hours_before = e.target.value;
                                setWednesday(wednesday);
                                workingDays.find(wd => wd.day === 3).hours_before = e.target.value
                                setRerenderSelect(true);
                            }} disabled={!(wednesday)}/>
                            <Button onClick={() => {
                                workingDays.forEach((wd, index) => {
                                    if(wd.day === 3) workingDays.splice(index, 1)
                                })
                                setWorkingDays(workingDays);
                                setWednesday(null);
                                setRerenderSelect(true)
                            }} size={'small'} variant={'contained'} color={'error'}>delete</Button>
                        </Box>
                    </Box>
                    :
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Wednesday</Typography>
                        <Button onClick={() => {
                            workingDays.push({
                                start_time: 6,
                                end_time: 14,
                                user_id: userId,
                                day:3,
                                hours_before: 0
                            })
                            setWorkingDays(workingDays);
                            setRerenderSelect(true)
                        }} size={'small'} variant={'contained'} color={'success'}>Add</Button>
                    </Box>
            }

            {
                thursday ?
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Thursday:</Typography>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <TimeSelect text={'Start At:'} value={thursday ? thursday?.start_time : 6} changeValue={(e) => {
                                if(e.target.value < thursday.end_time){
                                    thursday.start_time = e.target.value;
                                    setThursday(thursday);
                                    workingDays.find(wd => wd.day === 4).start_time = e.target.value
                                    setRerenderSelect(true);
                                }
                            }} disabled={!(thursday)}/>
                            <TimeSelect text={'End At:'} value={thursday ? thursday?.end_time : 6} changeValue={(e) => {
                                if(e.target.value > thursday.start_time){
                                    thursday.end_time = e.target.value;
                                    setThursday(thursday);
                                    workingDays.find(wd => wd.day === 4).end_time = e.target.value
                                    setRerenderSelect(true);
                                }

                            }} disabled={!(thursday)}/>
                            <HoursBeforeSelect text={'Hours before:'} value={thursday?.hours_before ? thursday?.hours_before : 0} changeValue={(e) => {
                                thursday.hours_before = e.target.value;
                                setThursday(thursday);
                                workingDays.find(wd => wd.day === 4).hours_before = e.target.value
                                setRerenderSelect(true);
                            }} disabled={!(thursday)}/>
                            <Button onClick={() => {
                                workingDays.forEach((wd, index) => {
                                    if(wd.day === 4) workingDays.splice(index, 1)
                                })
                                setWorkingDays(workingDays);
                                setThursday(null);
                                setRerenderSelect(true)
                            }} size={'small'} variant={'contained'} color={'error'}>delete</Button>
                        </Box>
                    </Box>
                    :
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Thursday</Typography>
                        <Button onClick={() => {
                            workingDays.push({
                                start_time: 6,
                                end_time: 14,
                                user_id: userId,
                                day:4,
                                hours_before: 0
                            })
                            setWorkingDays(workingDays);
                            setRerenderSelect(true)
                        }} size={'small'} variant={'contained'} color={'success'}>Add</Button>
                    </Box>
            }

            {
                friday ?
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Friday:</Typography>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <TimeSelect text={'Start At:'} value={friday ? friday?.start_time : 6} changeValue={(e) => {
                                if(e.target.value < friday.end_time){
                                    friday.start_time = e.target.value;
                                    setFriday(friday);
                                    workingDays.find(wd => wd.day === 5).start_time = e.target.value
                                    setRerenderSelect(true);
                                }
                            }} disabled={!(friday)}/>
                            <TimeSelect text={'End At:'} value={friday ? friday?.end_time : 6} changeValue={(e) => {
                                if(e.target.value > friday.start_time){
                                    friday.end_time = e.target.value;
                                    setFriday(friday);
                                    workingDays.find(wd => wd.day === 5).end_time = e.target.value
                                    setRerenderSelect(true);
                                }

                            }} disabled={!(friday)}/>
                            <HoursBeforeSelect text={'Hours before:'} value={friday?.hours_before ? friday?.hours_before : 0} changeValue={(e) => {
                                friday.hours_before = e.target.value;
                                setFriday(friday);
                                workingDays.find(wd => wd.day === 5).hours_before = e.target.value
                                setRerenderSelect(true);
                            }} disabled={!(friday)}/>
                            <Button onClick={() => {
                                workingDays.forEach((wd, index) => {
                                    if(wd.day === 5) workingDays.splice(index, 1)
                                })
                                setWorkingDays(workingDays);
                                setRerenderSelect(true);
                                setFriday(null);
                            }} size={'small'} variant={'contained'} color={'error'}>delete</Button>
                        </Box>
                    </Box>
                    :
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Friday</Typography>
                        <Button onClick={() => {
                            workingDays.push({
                                start_time: 6,
                                end_time: 14,
                                user_id: userId,
                                day:5,
                                hours_before: 0
                            })
                            setWorkingDays(workingDays);
                            setRerenderSelect(true)
                        }} size={'small'} variant={'contained'} color={'success'}>Add</Button>
                    </Box>
            }

            {
                saturday ?
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Saturday:</Typography>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <TimeSelect text={'Start At:'} value={saturday ? saturday?.start_time : 6} changeValue={(e) => {
                                if(e.target.value < saturday.end_time){
                                    saturday.start_time = e.target.value;
                                    setSaturday(saturday);
                                    workingDays.find(wd => wd.day === 6).start_time = e.target.value
                                    setRerenderSelect(true);
                                }
                            }} disabled={!(saturday)}/>
                            <TimeSelect text={'End At:'} value={saturday ? saturday?.end_time : 6} changeValue={(e) => {
                                if(e.target.value > saturday.start_time){
                                    saturday.end_time = e.target.value;
                                    setSaturday(saturday);
                                    workingDays.find(wd => wd.day === 6).end_time = e.target.value
                                    setRerenderSelect(true);
                                }

                            }} disabled={!(saturday)}/>
                            <HoursBeforeSelect text={'Hours before:'} value={saturday?.hours_before ? saturday?.hours_before : 0} changeValue={(e) => {
                                saturday.hours_before = e.target.value;
                                setSaturday(saturday);
                                workingDays.find(wd => wd.day === 6).hours_before = e.target.value
                                setRerenderSelect(true);
                            }} disabled={!(saturday)}/>
                            <Button onClick={() => {
                                workingDays.forEach((wd, index) => {
                                    if(wd.day === 6) workingDays.splice(index, 1)
                                })
                                setWorkingDays(workingDays);
                                setRerenderSelect(true);
                                setSaturday(null);
                            }} size={'small'} variant={'contained'} color={'error'}>delete</Button>
                        </Box>
                    </Box>
                    :
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Saturday</Typography>
                        <Button onClick={() => {
                            workingDays.push({
                                start_time: 6,
                                end_time: 14,
                                user_id: userId,
                                day:6,
                                hours_before: 0
                            })
                            setWorkingDays(workingDays);
                            setRerenderSelect(true)
                        }} size={'small'} variant={'contained'} color={'success'}>Add</Button>
                    </Box>
            }

            {
                sunday ?
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Sunday:</Typography>
                        <Box sx={{display:'flex', alignItems:'center'}}>
                            <TimeSelect text={'Start At:'} value={sunday ? sunday?.start_time : 6} changeValue={(e) => {
                                if(e.target.value < sunday.end_time){
                                    sunday.start_time = e.target.value;
                                    setSunday(sunday);
                                    workingDays.find(wd => wd.day === 7).start_time = e.target.value
                                    setRerenderSelect(true);
                                }
                            }} disabled={!(sunday)}/>
                            <TimeSelect text={'End At:'} value={sunday ? sunday?.end_time : 6} changeValue={(e) => {
                                if(e.target.value > sunday.start_time){
                                    sunday.end_time = e.target.value;
                                    setSunday(sunday);
                                    workingDays.find(wd => wd.day === 7).end_time = e.target.value
                                    setRerenderSelect(true);
                                }

                            }} disabled={!(sunday)}/>
                            <HoursBeforeSelect text={'Hours before:'} value={sunday?.hours_before ? sunday?.hours_before : 0} changeValue={(e) => {
                                sunday.hours_before = e.target.value;
                                setSunday(sunday);
                                workingDays.find(wd => wd.day === 7).hours_before = e.target.value
                                setRerenderSelect(true);
                            }} disabled={!(sunday)}/>
                            <Button onClick={() => {
                                workingDays.forEach((wd, index) => {
                                    if(wd.day === 7) workingDays.splice(index, 1)
                                })
                                setWorkingDays(workingDays);
                                setRerenderSelect(true);
                                setSunday(null);
                            }} size={'small'} variant={'contained'} color={'error'}>delete</Button>
                        </Box>
                    </Box>
                    :
                    <Box sx={{margin:"0 0 10px 10px", display:'flex', alignItems:'center', background:"#FFF", borderRadius:2, padding:2}}>
                        <Typography sx={{marginRight:2, width:120}}>Sunday</Typography>
                        <Button onClick={() => {
                            workingDays.push({
                                start_time: 6,
                                end_time: 14,
                                user_id: userId,
                                day:7,
                                hours_before: 0
                            })
                            setWorkingDays(workingDays);
                            setRerenderSelect(true)
                        }} size={'small'} variant={'contained'} color={'success'}>Add</Button>
                    </Box>
            }

            <Button color='success' variant='contained' size='small' sx={{paddingX:3, paddingY:1, margin:1, fontWeight:700}} onClick={save}>Save Changes</Button>

        </Box>
    )
}
