import {
    Autocomplete,
    Avatar,
    AvatarGroup,
    Box,
    Button,
    Chip,
    Divider,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import * as React from "react";
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import Textarea from '@mui/joy/Textarea';
import LoadingComponent from "./LoadingComponent";
import SavedEvent from "./SavedEvent";
import MakeEmail from "../Email/MakeEmail";
import '../style/ScheduleEvent.css'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CircularProgress from "@mui/material/CircularProgress";
import {Helmet} from "react-helmet";

let utc = require('dayjs/plugin/utc')
let timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)


async function getUserTemplate(path, event_group_id, id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/template_for_user/${path}/${event_group_id}/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json())
}

async function saveNewEvent(path, start, template, timeZone, user_description, name, email) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/new_event/${path}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({start, template, timeZone, user_description, name, email})
        //body: JSON.stringify({token, start, end, timezone: dayjs.tz.guess()})
    }).then(data => data.json())
}

async function saveNewMeet(path, eventGroup, start, template, timeZone, user_description, name, email, guests, send_details) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/new_meet/${path}?event_group_id=${eventGroup}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({start, timeZone, user_description, template, name, email, guests, send_details})
        //body: JSON.stringify({token, start, end, timezone: dayjs.tz.guess()})
    }).then(data => data.json())
}

function sendEmail(from, mailto, subject, text, html) {
    const CLIENT_ID = process.env.REACT_APP_GMAIL_CLIENT_ID;
    const CLIENT_SECRET = process.env.REACT_APP_GMAIL_CLIENT_SECRET;
    const REDIRECT_URI = process.env.REACT_APP_GMAIL_REDIRECT_URI;
    const REFRESH_TOKEN = process.env.REACT_APP_GMAIL_REFRESH_TOKEN;
    const user = process.env.REACT_APP_GMAIL_USER;
    //return fetch(`https://send-email-api-seven.vercel.app/gmail`, {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/google/gmail`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({from, mailto, subject, text, html, user, CLIENT_ID, CLIENT_SECRET, REDIRECT_URI, REFRESH_TOKEN})
    })
        .then((response) => response.json())
}

function validEmail(input) {
    return !!(input.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/))
}

export default function ScheduleEvent({path, event, hour, minute, year, month, date, selectedTimezone, eventGroup, customLogo, showLogo, showName, customText, isMobile}){
    const [firstRender, setFirstRender] = useState(true);
    const [template, setTemplate] = useState(null);
    const [emails, setEmails] = useState([]);
    const [email, setEmail] = useState('');
    const [addGuests, setAddGuests] = useState(false);
    const [startDate, setStartDate] = useState(new Date(year, month, date, hour, minute));
    const [loading, setLoading] = useState(false);
    const [savedData, setSavedData] = useState(false);
    const [description, setDescription] = useState('');
    const [name, setName] = useState('');
    const [userEmail, setUserEmail] = useState(null);
    const [userPicture, setUserPicture] = useState(null);
    const [userBrand, setUserBrand] = useState(null);
    const [userName, setUserName] = useState(null);
    const [eventName, setEventName] = useState("");
    const [circleColor, setCircleColor] = useState(null);
    const [dateForDetails, setDateForDetails] = useState(null);
    const [dateForDetailsEnd, setDateForDetailsEnd] = useState(null);
    const [multipleUsers, setMultipleUsers] = useState(false);
    const [otherUsers, setOtherUsers] = useState(null);

    useEffect(() => {
        console.log({hour, minute, year, month, date})
        if(firstRender){
            getUserTemplate(path, eventGroup, event).then(template => {
                console.log({template});
                if(template) {
                    setTemplate(template);
                    setUserEmail(template.email);
                    setUserPicture(template.picture);
                    setUserBrand(template.brand_image);
                    setUserName(template.username);
                    setEventName(template?.multiple_users ? template.event_name : template.username);
                    setCircleColor(hexToRgb(template.colour));
                    setMultipleUsers(template?.multiple_users);
                    setOtherUsers(template?.users);
                    let dateForUser = dayjs().hour(hour).minute(minute).year(year).month(month).date(date);
                    setDateForDetails(dateForUser)
                    setDateForDetailsEnd(dayjs(dateForUser).add(template.duration, 'millisecond'));
                    console.log(dayjs(startDate))
                }
                else
                    window.location.pathname = `${path}`
            })
            setFirstRender(false);
        }
    });

    function hexToRgb(hex) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    const goBack = () => {
        window.location.pathname = `${path}/${eventGroup}/${template.template_id}`;
    }

    const goToStart = () => {
        window.location.pathname = `${path}/${eventGroup}`
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData(e.currentTarget);
        setDescription(data.get('details'));
        setName(data.get('name'));

        if(template.template_type_id === 1 || template.template_type_id === 3){
            console.log({startDate});
            /*let email_data = {
                name: data.get('name'),
                type: template.type_name,
                date:dayjs(startDate).format('DD/MM/YYYY'),
                time:dayjs(startDate).format('HH:mm'),
                duration:`${template.duration / 1000/60} mins`,
                details:(!data.get('details') || data.get('details') === '') ? '-' : data.get('details'),
                href: ''
            }*/
            saveNewMeet(path, eventGroup, startDate, template,
                selectedTimezone ? selectedTimezone : dayjs.tz.guess(),
                data.get('details'),
                data.get('name'),
                data.get('email'),
                emails,
                {from:"Zingley Calendar", mailto:template.email, subject:'New Google Meet', text:'New Google Meet'}
            ).then(saved => {

                /*if(saved.status){
                    let email_date = dayjs(saved?.insert_res?.data?.start?.dateTime);
                    console.log({email_date})
                    let email_data = {
                        name: data.get('name'),
                        type: template.type_name,
                        date:email_date.format('DD/MM/YYYY'),
                        time:email_date.format('HH:mm'),
                        duration:`${template.duration / 1000/60} mins`,
                        details:data.get('details'),
                        href: saved?.insert_res?.data?.htmlLink
                    }
                    /!*sendEmail('Calendar App', template.email, 'New Google Meet', 'New Google Meet', MakeEmail(email_data)).then(email_result => {
                        console.log({email_send: email_result});
                    })*!/
                }*/

                console.log({saved})
                setLoading(false);
                setSavedData(saved);


            })
        } else {
            saveNewEvent(path, startDate, template,
                selectedTimezone ? selectedTimezone : dayjs.tz.guess(),
                data.get('details'), data.get('name'),
                data.get('email')
            ).then(saved => {
                console.log({saved})
                setLoading(false);
                setSavedData(saved);
            })
        }
    }

    if(!template){
        return (
            <Box className='wrapper'>
                {/*<Box className='circle'/>*/}
                <LoadingComponent width={1} height={670}/>
            </Box>
        )
    }


    return (
        <Box className='wrapper'>
            <Helmet>
                <meta property="og:title" content={`Calendar - ${path}`}/>
                <title>Calendar - {path}</title>
            </Helmet>
            {/*<Box className='circle' sx={{
                background: `radial-gradient(50% 50% at 50% 50%, rgba(${circleColor.r}, ${circleColor.g}, ${circleColor.g}, 0.4) 0%, rgba(${circleColor.r}, ${circleColor.g}, ${circleColor.g}, 0) 87.5%)`
            }}/>*/}
            <Box className="event-types-events">
                <Box className='blur-effect'/>
                <Box className='header'>
                    {
                        !(showLogo) &&
                        <img src={customLogo ? customLogo : userBrand} alt="brand-pic" style={{cursor: 'pointer', height: 50, objectFit: 'contain'}}/>
                    }
                    {
                        !(showName) &&
                        <Box className='user-details'>
                            {
                                multipleUsers ?
                                    <AvatarGroup spacing={"small"} sx={{marginBlock:isMobile ? 0 : -1}} max={ isMobile ? 3 : 10} >
                                        <Tooltip title={userName}>
                                            <Avatar src={userPicture} name={userName}  sx={{width:isMobile ? 40 : 60, height:isMobile ? 40 : 60, zIndex:otherUsers.length}}/>
                                        </Tooltip>
                                        {
                                            otherUsers.map((user, index) =>
                                                <Tooltip title={user.name} key={user.user_id}>
                                                    <Avatar src={user.picture} name={user.name} sx={{width:isMobile ? 40 : 60, height:isMobile ? 40 : 60, zIndex:otherUsers.length-index}}/>
                                                </Tooltip>
                                            )
                                        }
                                    </AvatarGroup>
                                    :
                                    <Avatar src={userPicture} name={userName} sx={{width:60, height:60}}/>
                            }
                            <Typography sx={{color:'#FFF', fontSize:isMobile ? 18 : 24, fontFamily:'Poppins', zIndex:1000, width: isMobile ? 180 : 1, textAlign:'center'}} pl={2} pr={4}>{eventName}</Typography>

                        </Box>
                    }
                </Box>
                <Box className='holder-calendar' id='scroll-holder'>
                    <Box className='event-color' sx={{background:template.colour}}></Box>

                {
                    loading ? <Box className='temp-loader'><CircularProgress style={{color:'#E5C518'}}/></Box>
                        :
                        savedData ?
                            <SavedEvent microsoft={savedData?.insert_res?.location?.displayName === 'Microsoft Teams Meeting'} dateForDetails={dateForDetails} dateForDetailsEnd={dateForDetailsEnd} selectedTimezone={selectedTimezone} template={template} template_id={template.template_id} eventGroup={eventGroup} path={path} date={dateForDetails} name={name} title={template.name} savedData={savedData}/>
                            :
                        <Box
                            component="form"
                            className='form'
                            noValidate={false}
                            autoComplete="on"
                            onSubmit={handleSubmit}
                            id='form-holder'
                        >
                            <Typography component='h2' variant='h5'><ArrowBackIosIcon style={{color:"#E5C518", paddingRight:8, cursor:'pointer'}} onClick={goBack}/> Enter Details</Typography>
                            <Box className='input-holder'>
                                <Typography component='p' variant='p'>Name <span>*</span></Typography>
                                <TextField id="name" name="name" variant="outlined" required size="small" placeholder='Your name'/>
                            </Box>
                            <Box className='input-holder'>
                                <Typography component='p' variant='p'>Email <span>*</span></Typography>
                                <TextField
                                    placeholder='Your email'
                                    id="email"
                                    name="email"
                                    variant="outlined"
                                    required
                                    size="small"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={(e) => {
                                        if(!validEmail(email)){
                                            setEmail('');
                                        }
                                    }}
                                />
                            </Box>

                            {
                                template.template_type_id === 1 && (!addGuests
                                    ?
                                    <Button className='add-guests-button' variant='text' onClick={() => setAddGuests(!addGuests)}>+ <span>Add Guests</span></Button>
                                    :
                                    <Autocomplete
                                        className='guest-emails-holder'
                                        multiple
                                        id="tags-filled"
                                        options={emails.map((option) => option)}
                                        defaultValue={[]}
                                        freeSolo
                                        onChange={(event, newValue) => {
                                            const lastAdded = newValue[newValue.length-1];
                                            if(lastAdded && !validEmail(lastAdded)){
                                                newValue.pop()
                                            }
                                            setEmails(newValue)
                                        }}

                                        renderTags={(value, getTagProps) =>
                                            emails.map((option, index) => (
                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <Box className='guest-input-holder'>
                                                <Typography component='p' variant='p'>Guests email</Typography>
                                                <TextField
                                                    className='guests_input'
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="example@email.com"
                                                />
                                                <FormHelperText className='helper-text' sx={{paddingX:1}}>Press ENTER to save</FormHelperText>
                                            </Box>

                                        )}
                                    />)
                            }


                            <Box className='guest-input-holder'>
                                <Typography component='p' variant='p'>Message</Typography>
                                <FormControl>
                                    <Textarea name="details" id="details" minRows={4} className='textarea-schedule' placeholder='Please share anything that will help prepare for our meeting...'/>
                                </FormControl>
                            </Box>
                            <Box className='submit-button-holder'>
                                <Button variant="contained" type="submit" className='submit-button'>Schedule Event</Button>
                            </Box>


                        </Box>
                }
            </Box>
        </Box>
        </Box>
    )

}
