import {useState} from "react";
import ScheduleCalendar from "./ScheduleCalendar";
import ScheduleEventTypes from "./ScheduleEventTypes";
import ScheduleEvent from "./ScheduleEvent";
import WrongUrl from "./WrongUrl";
import dayjs from "dayjs";
import {useMediaQuery} from "@mui/material";
import {createTheme} from "@mui/material/styles";

export default function RenderScheduleComponent ({path, customLogo, showLogo, showName, customText}) {
    const [paths, setPaths] = useState(path.split("/"));
    const [userPath, setUserPath] = useState(paths[1]);
    const [eventGroup, setEventGroup] = useState(paths[2]);
    const [userEvent, setUserEvent] = useState(paths[3]);
    const [selectedHour, setSelectedHour] = useState(paths[4]);
    const [selectedMinute, setSelectedMinute] = useState(paths[5]);
    const [selectedYear, setSelectedYear] = useState(paths[6]);
    const [selectedMonth, setSelectedMonth] = useState(paths[7]);
    const [selectedDate, setSelectedDate] = useState(paths[8]);
    const [timezone, setTimezone] = useState(`${paths[9]}/${paths[10]}`);
    const [selectedTimezone, setSelectedTimezone] = useState(dayjs.tz.guess());
    const theme = createTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    //console.log({paths, selectedTimezone})

    const setEvent = (event_id) => {
        window.location.pathname = `${path}/${event_id}`
    }

    if(userEvent && userPath && selectedHour && selectedMinute) {
        return (
            <ScheduleEvent isMobile={isMobile} showLogo={showLogo} showName={showName} customLogo={customLogo} customText={customText} eventGroup={eventGroup} path={userPath} event={userEvent} hour={selectedHour} minute={selectedMinute} year={selectedYear} month={selectedMonth} date={selectedDate} selectedTimezone={timezone} />
        )
    } else if (userEvent) {        //user have event and path
        return (
            <ScheduleCalendar isMobile={isMobile} showLogo={showLogo} showName={showName} customLogo={customLogo} customText={customText} path={userPath} event={userEvent} selectedTimezone={selectedTimezone} setSelectedTimezone={setSelectedTimezone} eventGroup={eventGroup}/>
        )
    } else if (userPath && eventGroup) {         //user dont have event
        return (
            <ScheduleEventTypes isMobile={isMobile} showLogo={showLogo} showName={showName} customLogo={customLogo} customText={customText} path={userPath} setEvent={setEvent} eventGroup={eventGroup} />
        )
    } else {
        return <WrongUrl/>
    }

}
