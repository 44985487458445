import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoginToken from "../../../local_storage/LoginToken";
import LogoutIcon from "@mui/icons-material/Logout";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

const drawerWidth = 240;

function MenuHeader( props ) {
    const { window, logoutUser, setLoginToken, setSelected, selected, haveCalendars, googleUser, userIsAdmin, userIsSuperAdmin } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { tokenData, getToken } = LoginToken();
    const navigate = useNavigate();
    const [navItems, setNavItems] = useState([{id: 0, name: 'Event Types'}, {id: 1, name: 'Event Group'}, {id: 2, name: 'Days off'}, {id: 4, name: 'Personalization'}]);
    const [firstRender, setFirstRender] = useState(true);
    console.log({userIsAdmin, userIsSuperAdmin});

    useEffect(() => {
        if(firstRender) {
            if(googleUser) {
                setNavItems([{id: 0, name: 'Event Types'}, {id: 1, name: 'Event Group'}, {id: 2, name: 'Days off'}, {id: 3, name: 'Manage Calendars'}, {id: 4, name: 'Personalization'}])
            } else {
                setNavItems([{id: 0, name: 'Event Types'}, {id: 1, name: 'Event Group'}, {id: 2, name: 'Days off'}, {id: 5, name: 'Manage Microsoft Calendars'}, {id: 4, name: 'Personalization'}])
            }
            setFirstRender(false);
        }
    }, [firstRender]);

    useEffect(() => {
        if(userIsAdmin) {
            let newItems = [...navItems];
            newItems.push({id: 6, name: 'Organization Admin'})
            setNavItems(newItems)
        }
    }, [userIsAdmin]);

    useEffect(() => {
        if(userIsSuperAdmin) {
            let newItems = [...navItems];
            newItems.push({id: 7, name: 'Super Admin'})
            //TODO uncomment to enable payment (api need to push to server)
            //newItems.push({id: 8, name: 'Payments'})
            setNavItems(newItems)
        }
    }, [userIsSuperAdmin]);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }} onClick={() => setSelected(item.id)}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const connectGoogleCalendar = () => {
        navigate(`${process.env.REACT_APP_SERVER_URL}/google?user_token=${getToken().token}`);
    }

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav">
                <Toolbar sx={{backgroundColor: '#f5f5f5'}}>
                    <IconButton
                        color="#131313"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ display: { xs: 'none', sm: 'flex' },flexGrow: 1}}>
                        { haveCalendars && navItems.map((item) => (
                            <Button key={item.name} sx={{ color: '#131313', fontWeight:700 }} onClick={() => setSelected(item.id)}>
                                {item.name}
                            </Button>
                        ))}
                    </Box>
                    {
                        googleUser &&
                        <Box sx={{color: "#131313"}}>
                            <Button
                                href={`${process.env.REACT_APP_SERVER_URL}/google?user_token=${getToken().token}`}
                                variant="button"
                                endIcon={<ControlPointIcon/>}
                            >
                                Connect Calendars
                            </Button>
                        </Box>
                    }
                    <Box sx={{color:"#131313"}}>
                        <Button
                            onClick={logoutUser}
                            variant="button"
                            endIcon={<LogoutIcon />}
                        >
                            LOGOUT
                        </Button>
                    </Box>

                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{ p: 3 }}>
                <Toolbar />
            </Box>
        </Box>
    );
}

MenuHeader.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
    setLoginToken: PropTypes.func,
    logoutUser: PropTypes.func,
    selected: PropTypes.number,
    setSelected: PropTypes.func,
};

export default MenuHeader;
