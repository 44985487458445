import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({items, label, setSelected}) {
    const [type, setType] = React.useState('');

    const handleChange = (event) => {
        setType(event.target.value);
        setSelected(event.target.value);
    };

    return (
        <Box sx={{ textAlign:'start' }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label={label}
                    onChange={handleChange}
                >
                    {items.map(item => {
                        return (
                                <MenuItem value={item.id} key={item.name}>{item.name}</MenuItem>
                            )
                    })}
                </Select>
            </FormControl>
        </Box>
    );
}
