import { createTheme } from '@mui/material/styles';


export default function Theme() {
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Poppins',
                'Arial', // Fallback font
                'sans-serif', // Generic sans-serif font
            ].join(','),
        },
        palette: {
            success: {
                light: '#108A00',
                main: '#108A00',
                dark: '#108A00',
                contrastText: '#FFF',
                text: '#108A00',
            },
            error:{
                light: "rgba(249,79,56,0.5)",
                main: "#F94F38"
            },
            warning:{
                light: "rgba(255,185,5,0.5)",
                main: "#FFB905"
            },
            info:{
                light: "#0450f9",
                main: "#0450f9"
            },
            primary:{
                light: "#032653",
                main: "#032653"
            },
            secondary:{
                light: "#C5C8CB",
                main: "#C5C8CB"
            },
            button: {
                green: '#108A00',
                main: '#0450F9',
                red: '#ED6161',
                contrastText: '#FFF',
                text: '#0450F9',
                line: '#D4DBE0',
                hover: 'rgba(4, 80, 249, 0.5)'
            },
            text: {
                header: '#001E00',
                text: '#24262C',
                gray: '#919295'
            },
            custom_green:{
                main: '#13544E',
                contrastText: '#FFF'
            },
            white:{
                main: '#FFF',
                contrastText: '#FFF'
            }
        },
    });

    return {
        theme
    }
}
