import React from 'react'
import {Box,Typography} from "@mui/material";

export default function ErrorMessage( { message } ){
    if(message){
        return (
            <Box sx={{
                height:20,
                marginBottom:10,
                marginTop:-10
            }}>
                <Box sx={{
                    height:1,
                    borderLeft: '3px solid #ED6161',
                    display: 'flex',
                    alignItems:'center',
                    padding: 1
                }}>
                    <Typography component='p' variant='p'  sx={{opacity:1, color:'#FFF'}}>{message}</Typography>
                </Box>

            </Box>
        )
    } else return null
}
