import {Box, Typography} from "@mui/material";
import LoginToken from "../../../local_storage/LoginToken";
import {useEffect, useState} from "react";
import CustomCalendar from "./Calendar/CustomCalendar";
import MyDateCalendar from "./Calendar/MyDateCalendar";
import dayjs from "dayjs";
import TimePicker from "./Time/TimePicker";

let utc = require('dayjs/plugin/utc')
let timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

async function getWorkingDays(token) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/users/working_days`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(token)
    }).then(data => data.json())
}

async function getCalendarConflicts(token, start, end) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/conflicts`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, start, end})
    }).then(data => data.json())
}

async function getCalendarTimeSlots(token, start, end) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/time_slots`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({token, start, end})
        //body: JSON.stringify({token, start, end, timezone: dayjs.tz.guess()})
    }).then(data => data.json())
}

export default function Calendar(){
    const { getToken } = LoginToken();
    const [firstRender, setFirstRender] = useState(true);
    const [workingDays, setWorkingDays] = useState([]);
    const [notWorkingDays, setNotWorkingDays] = useState([
        dayjs(new Date(2023, 4, 15)), // May 15th, 2023
        dayjs(new Date(2023, 4, 22)), // May 22nd, 2023
    ]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [startDate, setStartDate] = useState(dayjs().startOf('month'));
    const [endDate, setEndDate] = useState(dayjs().endOf('month'));
    const [conflicts, setConflicts] = useState(null);
    const [timeSlots, setTimeSlots] = useState(null);
    const [rerenderCalendar, setRerenderCalendar] = useState(true);
    const [forDay, setForDay] = useState(null);

    const setSelected = (e) => {
        setSelectedDate(e)
        let occupiedForDay = [];
        let freeForDay = [];
        timeSlots?.occupied?.forEach(occ => {
            if(dayjs(occ.start).date() === e.date()){
                let startDate = new Date(occ.start);
                let startdate = dayjs.tz(startDate, dayjs.tz.guess());
                occupiedForDay.push({
                    start: startdate.format('HH:mm'),
                    //end: dayjs(occ.end).format('HH:mm'),
                    occupied: true
                })
            }
        })

        timeSlots?.free?.forEach(free => {
            if(dayjs(free.start).date() === e.date()){
                let startDate = new Date(free.start);
                let startdate = dayjs.tz(startDate, dayjs.tz.guess());
                freeForDay.push({
                    start: startdate.format('HH:mm'),
                    //end: dayjs(occ.end).format('HH:mm'),
                    occupied: false
                })
            }
        })

        const combinedArray = [...freeForDay, ...occupiedForDay];

// Sort the combined array by start time
        const sortedArray = combinedArray.sort((a, b) => (a.start > b.start) ? 1 : -1);

        setForDay(sortedArray)
        //console.log({sortedArray})
    }

    const setChangedMonth = (date) => {

        setForDay(null)
        //setTimeSlots(null)
        //console.log(date)
        setStartDate(date);
        setEndDate(date.endOf('month'));
        getCalendarTimeSlots(getToken()?.token, date.$d.getTime(), date.endOf('month').$d.getTime()).then(slots => {
            setTimeSlots(slots);
            //console.log({slots});
            setRerenderCalendar(true);
        })
    }

    useEffect(() => {
        if(firstRender){
            getWorkingDays(getToken()).then(result => {
                let {working_days, not_working_days} = result;
                setWorkingDays(working_days);
                setNotWorkingDays(not_working_days?.map(day => {
                    return dayjs(day.date);
                }))
                getCalendarConflicts(getToken()?.token, startDate ? startDate.$d.getTime() : null, endDate ? endDate.$d.getTime() : null).then(conflicts => {
                    setConflicts(conflicts);
                    console.log({conflicts});
                })
                getCalendarTimeSlots(getToken()?.token, startDate ? startDate.$d.getTime() : null, endDate ? endDate.$d.getTime() : null).then(slots => {
                    setTimeSlots(slots);
                    console.log({slots});
                })
            })
            setFirstRender(false);
        }
    });
    return (
        <Box sx={{display:'flex', justifyContent:'center'}}>
            {
                (workingDays?.length > 0 && timeSlots)
                &&
                <MyDateCalendar
                    start_day={1}
                    end_day={5}
                    not_working={notWorkingDays}
                    working_days={workingDays}
                    setSelected={setSelected}
                    conflicts={conflicts}
                    timeSlots={timeSlots}
                    setChangedMonth={setChangedMonth}
                    rerenderCalendar={rerenderCalendar}
                    setRerenderCalendar={setRerenderCalendar}
                />
            }

            {
                forDay &&
                <TimePicker times={forDay} selectedDate={selectedDate}></TimePicker>
            }



        </Box>
    )
}
