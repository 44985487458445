import { Box, Button, Typography} from "@mui/material";
import * as React from "react";
import '../style/SavedEvent.css'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LanguageIcon from '@mui/icons-material/Language';

export default function SavedEvent({microsoft, path, date, title, name, eventGroup, savedData, template_id, template, selectedTimezone, dateForDetails, dateForDetailsEnd}) {
    const close = () => {
        window.location.pathname = `${path}/${eventGroup}`
    }

    const tryAgain = () => {
        window.location.pathname = `${path}/${eventGroup}/${template_id}`
    }

    if(savedData.status){
        return (
            <Box className='event-confirmed'>
                <Box sx={{display:"flex", width:1, alignItems:'center'}}>
                    <CheckCircleOutlineIcon sx={{paddingRight:2}} style={{color:'#10AB55'}} fontSize='medium'/>
                    <Typography component='h3' variant='h3'>Event Confirmed!</Typography>
                </Box>
                {
                    template  &&
                    <Box className="event-details-user-event">
                        <Box className='additional-details'>
                            <CalendarTodayIcon sx={{paddingRight:1}} />
                            <Typography component='h4' variant='h5'>{dateForDetails.format('DD/MM/YYYY')}</Typography>
                        </Box>
                        <Box className='additional-details'>
                            <AccessAlarmIcon sx={{paddingRight:1}} />
                            <Typography component='h4' variant='h5'>{dateForDetails.format('HH:mm')} - {dateForDetailsEnd.format('HH:mm')}</Typography>
                        </Box>
                        <Box className='additional-details'>
                            <LanguageIcon sx={{paddingRight:1}} />
                            <Typography component='h4' variant='h5'>{selectedTimezone}</Typography>
                        </Box>
                    </Box>
                }
                <Typography variant='p' component='p'>
                    Congratulations, {name}! Your event has been successfully scheduled. The {title} is confirmed for {date.format('DD/MM/YYYY')} at {date.format('HH:mm')}.
                </Typography>
                <br/>
                <Typography variant='p' component='p'>
                    Please mark your calendar and make sure to save the event details provided above. We will be meeting virtually using {microsoft ? 'Microsoft Meeting' : 'Google Meet'}. You will receive a separate email closer to the event date with a link to join the meeting.
                </Typography>


                <Box className='submit-button-holder'>
                    <Button variant="contained" type="button" className='submit-button'onClick={close}>Finish</Button>
                </Box>
            </Box>
        )
    } else {
        return (
            <Box className='event-confirmed'>
                <Box sx={{display:"flex", width:1, alignItems:'center', marginY:2}}>
                    <ErrorOutlineIcon sx={{paddingRight:2}} style={{color:'#FF3333'}} fontSize='medium'/>
                    <Typography component='h3' variant='h3'>Event Not Confirmed!</Typography>
                </Box>

                <Typography variant='p' component='p'>
                    {savedData?.message ? savedData.message : savedData?.err}
                </Typography>

                <Box className='submit-button-holder' height={170}>
                    <Button variant="contained" type="button" className='submit-button'onClick={tryAgain}>Change Time</Button>
                </Box>
            </Box>
        )
    }


}
