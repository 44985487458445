import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import {Box, CircularProgress, Typography} from "@mui/material";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';


const UserImage = ({src, name, changeImage, waiting, width, height, alwaysShowOverlay, disableChanging}) => {

    const [image, setImage] = useState(src);

    const [showChangeImage, setShowChangeImage] = useState(false);

    useEffect(() => {
        setImage(src);
    }, [src]);

    const handleImageChange = async (event) => {
        const file = event.target.files[0];

        // Check file size
        if (file.size > 1 * 1024 * 1024) {
            alert('Oops! It looks like the image you\'re trying to upload is too large. Please choose a file that is smaller than 1MB and try again.');
            return;
        }

        // Check file type
        if (!/\.jpg|\.jpeg|\.png/i.test(file.name)) {
            alert('Sorry, we only accept files in .jpg, .jpeg, or .png format. Please choose a valid image file and try again.');
            return;
        }

        // Convert file to Data URI format
        const dataUri = await convertToDataUri(file);

        const formData = new FormData();
        formData.append('avatar', dataUri);
        const fileType = getFileType(file.name);

        try {
            const response = await changeImage(dataUri, fileType);
            if (response.state && response.status === 200) {
                setImage(response.src);
            } else {
                alert(`Error: ${response.error}`);
            }
        } catch (error) {
            alert('An error occurred while updating the image.');
        }
    };

    function trimAll(str) {
        return str.replace(/\s+/g, '');
    }

    const convertToDataUri = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result.split(',')[1]); // Extract the base64 data part
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png'].includes(extension)) {
            return extension;
        }
        return null;
    };

    return (
        <Box sx={{width, height}}>
            {
                !disableChanging &&
                <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleImageChange}
                    style={{display: 'none'}}
                    id={"image-input" + name}
                />
            }

            <label htmlFor={"image-input" + name} className="module-edit-profile-image-label">
                <Box sx={{position: 'relative', width, height}} onMouseEnter={() => setShowChangeImage(true)} onMouseLeave={() => setShowChangeImage(false)}>
                    <img src={image} style={{width, height, objectFit:'cover'}} alt={name}/>
                    {
                        !disableChanging &&
                        <>
                            <WaitingOverlay show={waiting}/>
                            <ImageOverlay text={`Update`} show={!image}/>
                            <ImageOverlay text={`Change`} show={image && (showChangeImage || alwaysShowOverlay)} disableBackground={alwaysShowOverlay}/>
                        </>
                    }
                </Box>
            </label>
        </Box>
    );
};

function WaitingOverlay({show}){
    if(show){
        return(
            <Box
                sx={{
                    position: 'absolute',
                    top: '-5%',
                    left: '-5%',
                    width: '110%',
                    height: '110%',
                    background:'rgba(0,0,0,0.1)',
                    backdropFilter:'blur(3px)',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    borderRadius:'8px'
                }}
            >
                <CircularProgress />
            </Box>

        )
    }
}

function ImageOverlay({text, show, disableBackground}) {
    if(show) {
        return (
            <Box sx={{
                cursor: 'pointer',
                background: disableBackground ? 'none' : 'rgba(3,38,83,0.5)',
                color: '#FFF',
                width: '110%',
                height: '110%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: "center",
                flexDirection: 'column',
                position: 'absolute',
                top: '-5%',
                left:'-5%',
                borderRadius:"8px"
            }}>
                <CameraAltOutlinedIcon fontSize={'medium'}/>
                <Typography sx={{textAlign:'center'}}>{text}</Typography>
            </Box>
        )
    }
}

export default UserImage;
