import {Box, Typography} from "@mui/material";

export default function ClearCookies() {
    return (
        <Box sx={{height:'100vh', width:1, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', gap:2}}>
            <Typography variant={'h3'}>Microsoft Login error.</Typography>
            <Typography variant={'h4'}>Clear Microsoft cookies and site data.</Typography>
            <a href="/login">Go back</a>
        </Box>
    )
}
