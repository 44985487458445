import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
//import Textarea from '@mui/joy/Textarea';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddIcon from "@mui/icons-material/Add";
import {Box, TextField} from "@mui/material";
import BasicSelect from "../Inputs/BasicSelect";
import {useEffect, useState} from "react";
import ColorPicker from "../Inputs/ColorPicker";
import LoginToken from "../../../../local_storage/LoginToken";

async function getTypes(google_user) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/templates/types?google_user=${google_user ? 1 : 0}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json())
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewTemplate2({saveUserTemplates, login_token, addNew, group_id, group_name}) {
    const [open, setOpen] = React.useState(false);
    const [selectTypes, setSelectTypes] = useState([]);
    const [firstRender, setFirstRender] = useState(true);
    const [selectedColor, setSelectedColor] = useState(3);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [newName, setNewName] = useState('');
    const [newDesc, setNewDesc] = useState('');
    const { tokenData } = LoginToken();

    const colors = [
        {id: 0, color: '#ff4f00'},
        {id: 1, color: '#ff758e'},
        {id: 2, color: '#e55cff'},
        {id: 3, color: '#8247f5'},
        {id: 4, color: '#0099ff'},
        {id: 5, color: '#0ae8f0'},
        {id: 6, color: '#17e885'},
        {id: 7, color: '#ccf000'},
        {id: 8, color: '#f8e436'},
        {id: 9, color: '#ffa600'},
    ]
    const selectDurations = [
        {id: 30, name: '30 min'},
        {id: 60, name: '60 min'},
        {id: 90, name: '90 min'},
        {id: 120, name: '120 min'},
        {id: 240, name: '240 min'},
        {id: 480, name: '480 min'},
    ]

    useEffect(() => {
        if(firstRender){
            //console.log(tokenData());
            getTypes(!!(tokenData().google_id)).then(types => {
                setSelectTypes(types.map(type => {
                    return {
                        id: type.template_type_id,
                        name: type.name,
                        icon: type.icon
                    }
                }))
            })
        }
        setFirstRender(false);
    });

    const handleNewName = (e) => {
        setNewName(e.target.value)
    };

    const handleNewDesc = (e) => {
        setNewDesc(e.target.value)
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        if(selectedType && selectedColor && selectedDuration && newName){
            let new_template = {
                template_type_id: selectedType,
                description: newDesc ? newDesc : '',
                duration: selectedDuration * 1000 * 60,
                colour: colors.find(col => col.id === selectedColor).color,
                icon: selectTypes.find(type => type.id === selectedType).icon,
                name: newName,
                event_group: group_id
            }
            saveUserTemplates(login_token, new_template).then(inserted => {
                addNew(inserted);
                setOpen(false);
                setNewName('');
                setNewDesc('');
                setSelectedColor(3);
                setSelectedType(null);
                setSelectedDuration(null);
            })
        }

    };

    return (
        <div>
            <Button variant="outlined" color='success' size={'small'} sx={{borderRadius:2, fontWeight:600, textTransform:'none'}} startIcon={<AddIcon/>} onClick={handleClickOpen}>
                Add Event...
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Create New Event Type for {group_name}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        '& > :not(style)': { m: 1, width: 500 },
                        textAlign:'center',
                        display:'flex',
                        flexDirection:'column',
                        margin:'40px auto',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Typography component='h2' variant='h4'>Add New Event</Typography>
                    </Box>
                    <TextField value={newName} onChange={handleNewName} id="name" label="Event Name" variant="outlined" required/>
                    <BasicSelect items={selectTypes} label={"Type"} setSelected={setSelectedType}/>
                    {/*<Textarea
                        value={newDesc}
                        onChange={handleNewDesc}
                        disabled={false}
                        minRows={5}
                        placeholder="Write a summary and any details your invitee should know about the event."
                        size="lg"
                    />*/}
                    <TextField
                        value={newDesc}
                        onChange={handleNewDesc}
                        disabled={false}
                        maxRows={5}
                        minRows={5}
                        placeholder="Write a summary and any details your invitee should know about the event."
                        size="lg"
                    />
                    <BasicSelect items={selectDurations} label={"Duration"} setSelected={setSelectedDuration}/>
                    <ColorPicker selectedColor={selectedColor} setSelectedColor={setSelectedColor} colors={colors}/>
                    <Button type={'submit'} variant='contained' onClick={handleSave}>Save</Button>
                </Box>
            </Dialog>
        </div>
    );
}
