import {Box, FormControl, FormControlLabel, IconButton, Typography} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import googleCalendarIcon from '../../../../media/Google_Calendar_icon_(2020).svg.png';
import Checkbox from '@mui/material/Checkbox';
import {useEffect, useState} from "react";
import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

export default function EditCalendarComponent( {calendar, changedDefault, changedAutoAccept, deleteCalendar, refresh, setRefresh, dbDefault, logoutUser, changeCalendarsToUse} ){

    const [checked, setChecked] = useState(calendar.default);
    const [checkedAutoAccept, setCheckedAutoAccept] = useState(calendar.auto_accept);
    const [calendarList, setCalendarList] = useState(calendar?.calendar_list ? calendar.calendar_list.map(cl => {
        cl['checked'] = calendar?.calendars_to_use ? !!(calendar.calendars_to_use.find(ctu => ctu === cl.id)) : false;
        return cl;
    }) : []);
    const [openAlert, setOpenAlert] = useState(false);
    //console.log({calendar});

    useEffect(() => {
        if(refresh){
            setChecked(calendar.default);
            setCheckedAutoAccept(calendar.auto_accept);
            setCalendarList(calendar?.calendar_list ? calendar.calendar_list.map(cl => {
                cl['checked'] = calendar?.calendars_to_use ? !!(calendar.calendars_to_use.find(ctu => ctu === cl.id)) : false;
                return cl;
            }) : []);
            setRefresh(false);
        }
    });

    const clickedOnDelete = (calendar_id) => {
        setOpenAlert(true)
    }

    const handleDelete = (calendar_id) => {

        deleteCalendar(calendar_id);
        if(calendar.calendar_id === dbDefault) {
            logoutUser();
        }
        setOpenAlert(false)
    }

    return (
        <Box sx={{width:1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom:'1px dotted #999', paddingX:2}}>
            <Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <img src={calendar.google_id ? 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Calendar_icon_%282020%29.svg' : 'https://res.cdn.office.net/owamail/hashed-v1/griffel/resources/images/Calendar_22px-hash-4b5e2360.m.svg'} style={{width:25}} alt='calendar icon'/>
                    <Box sx={{marginLeft:2, width:400}}>
                        <Typography variant='h4' component='p' sx={{color: '#131313', fontSize:16}}>{calendar.google_id ? 'Google' : 'Microsoft'}</Typography>
                        <Typography variant='h4' component='p' sx={{color: '#131313', fontSize:14}}>{calendar.name}</Typography>
                    </Box>
                    <FormControl component="fieldset">
                        <FormControlLabel
                            sx={{color: '#131313'}}
                            value="end"
                            checked={checked}
                            control={<Checkbox sx={{color: '#131313'}} />}
                            label="Default"
                            labelPlacement="end"
                            onClick={() => changedDefault(calendar.calendar_id)}
                        />
                    </FormControl>
                    <FormControl component="fieldset">
                        <FormControlLabel
                            sx={{color: '#131313'}}
                            value="end"
                            checked={checkedAutoAccept}
                            control={<Checkbox sx={{color: '#131313'}} />}
                            label="Auto Accept"
                            labelPlacement="end"
                            onClick={() => changedAutoAccept(calendar.calendar_id)}
                        />
                    </FormControl>
                    {
                        !dbDefault &&
                        <IconButton sx={{color: '#131313'}} onClick={() => clickedOnDelete(calendar.calendar_id)}>
                            <DeleteForeverIcon />
                        </IconButton>
                    }

                </Box>
                <CalendarList calendar={calendar} calendarList={calendarList} setCalendarList={setCalendarList} changeCalendarsToUse={changeCalendarsToUse}/>
            </Box>
            <CalendarDialog calendar={calendar} handleDelete={handleDelete} dbDefault={dbDefault} openAlert={openAlert} setOpenAlert={setOpenAlert}/>
        </Box>
    )
}

function CalendarList ({calendar, calendarList, setCalendarList, changeCalendarsToUse}) {

    const handleChange = () => {
        setCalendarList([...calendarList]);
        let newCalendarsToUse = [];
        calendarList.forEach(item => {
            if(item?.checked) newCalendarsToUse.push(item.id);
        })
        calendar.calendars_to_use = newCalendarsToUse?.length > 0 ? newCalendarsToUse : null;
        //setCalendarsToUse(newCalendarsToUse?.length > 0 ? newCalendarsToUse : null);
        console.log(newCalendarsToUse?.length > 0 ? newCalendarsToUse : null);
        changeCalendarsToUse(calendar.calendar_id);
        //console.log(checked, calendar);
    }

    return (
        <Box my={1}>
            <Typography sx={{fontWeight:600}}>Calendars to check:</Typography>
            <Typography sx={{fontSize:14, fontStyle:'italic'}}>(If nothing is checked only primary calendar will be used)</Typography>
            {
                calendarList && calendarList.length > 0 &&
                calendarList.map(calendar => <SingleCalendarInList key={calendar.id} calendar={calendar} handleChange={handleChange}/> )
            }
        </Box>
    )
}

function SingleCalendarInList({calendar, handleChange}) {
    console.log(calendar.checked)
    const [checked, setChecked] = useState(calendar?.checked);

    const onChange = () => {
        setChecked(!checked);
        calendar['checked'] = !checked;
        handleChange();
    }

    return (
        <Box sx={{display:'flex', alignItems:'center'}}>
            <Checkbox value={checked} defaultChecked={checked} sx={{color:calendar.backgroundColor, '&.Mui-checked': {color: calendar.backgroundColor}}} onChange={onChange}/>
            <Typography >{calendar.summary}</Typography>
        </Box>

    )
}

function CalendarDialog ({calendar, openAlert, setOpenAlert, dbDefault, handleDelete}) {
    return (
        <Dialog
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                Are you sure you want to delete {calendar.name} calendar?
            </DialogTitle>


            {
                (calendar.calendar_id === dbDefault) &&
                <DialogContent>
                    <Typography color={'error'}>This is default calendar!</Typography>
                    <Typography>After delete you will be logged out.</Typography>
                </DialogContent>
            }

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This action cannot be undone!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenAlert(false)} autoFocus>Cancel</Button>
                <Button onClick={() => handleDelete(calendar.calendar_id)}>
                    Remove
                </Button>
            </DialogActions>
        </Dialog>
    )
}
