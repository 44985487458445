import {Avatar, AvatarGroup, Box, Grid, Tooltip, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from "react";
import LoadingComponent from "./LoadingComponent";
import '../style/ScheduleEventTypes.css'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import {Helmet} from "react-helmet";

async function getUserTemplates(path,eventGroup) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/templates_for_user/${path}/${eventGroup}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json())
}

export default function ScheduleEventTypes({path, setEvent, eventGroup, customLogo, showLogo, showName, customText, isMobile}){
    const [firstRender, setFirstRender] = useState(true);
    const [userTemplates, setUserTemplates] = useState(null);
    const [userName, setUserName] = useState("");
    const [eventName, setEventName] = useState("");
    const [userEmail, setUserEmail] = useState(null);
    const [userPicture, setUserPicture] = useState(null);
    const [userBrand, setUserBrand] = useState(null);
    const [error, setError] = useState(null);
    const [multipleUsers, setMultipleUsers] = useState(false);
    const [otherUsers, setOtherUsers] = useState(null);


    useEffect(() => {
        if(firstRender){
            getUserTemplates(path, eventGroup).then(templates => {
                console.log({templates});
                if(templates.status){
                    setUserTemplates(templates.templates);
                    setUserName(templates.user);
                    setEventName(templates?.multiple_users ? templates.event_name : templates.user);
                    setUserEmail(templates.email)
                    setUserPicture(templates.picture)
                    setUserBrand(templates.brand_image);
                    setMultipleUsers(templates.multiple_users);
                    setOtherUsers(templates.users)
                } else {
                    setError(templates.message)
                }
            })
            setFirstRender(false)
        }
    });

    if(!userTemplates && !error){
        return (
            <Box className='wrapper'>
                {/*<Box className='circle-1'/>
                <Box className='circle-2'/>
                <Box className='circle-3'/>*/}
                <Box className="loading-box">
                    <Box className='blur-effect'/>
                    <LoadingComponent width={1} height={670}/>
                </Box>
            </Box>
        )
    }

    if(error){
        return (
            <Box className='wrapper'>
                {/*<Box className='circle-1'/>
                <Box className='circle-2'/>
                <Box className='circle-3'/>*/}
                <Box className="event-types">
                <Box className='blur-effect'/>
                <Typography variant='h5'>{error}</Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Box className='wrapper'>
            <Helmet>
                <meta property="og:title" content={`Calendar - ${path}`}/>
                <title>Calendar - {path}</title>
            </Helmet>
            {/*<Box className='circle-1'/>
            <Box className='circle-2'/>
            <Box className='circle-3'/>*/}
            <Box className="event-types">
                <Box className='blur-effect'/>
                <Box className='header' >
                    {
                        !(showLogo) &&
                        <img src={customLogo ? customLogo : userBrand} alt="brand-pic" style={{cursor: 'pointer', height: 50, objectFit: 'contain'}}/>
                    }
                    {
                        !(showName) &&
                        <Box className='user-details'>
                            {
                                multipleUsers ?
                                    <AvatarGroup spacing={"small"} sx={{marginBlock:isMobile ? 0 : -1}} max={ isMobile ? 3 : 10} >
                                        <Tooltip title={userName}>
                                            <Avatar src={userPicture} name={userName}  sx={{width:isMobile ? 40 : 60, height:isMobile ? 40 : 60, zIndex:otherUsers.length}}/>
                                        </Tooltip>
                                        {
                                            otherUsers.map((user, index) =>
                                                <Tooltip title={user.name} key={user.user_id}>
                                                    <Avatar src={user.picture} name={user.name} sx={{width:isMobile ? 40 : 60, height:isMobile ? 40 : 60, zIndex:otherUsers.length-index}}/>
                                                </Tooltip>
                                            )
                                        }
                                    </AvatarGroup>
                                    :
                                    <Avatar src={userPicture} name={userName} sx={{width:60, height:60}}/>
                            }
                            <Typography sx={{color:'#FFF', fontSize:isMobile ? 18 : 24, fontFamily:'Poppins', zIndex:1000, width: isMobile ? 180 : 1, textAlign:'center'}} pl={2} pr={4}>{eventName}</Typography>
                        </Box>
                    }

                    <Typography component="h6" variant='p'>{(customText || customText!=='') ? customText : 'Welcome to my scheduling page. Please follow the instructions to add an event to my calendar.'}</Typography>
                </Box>


                <Grid container className='grid-container' spacing={1}>
                    {
                        userTemplates
                        &&
                        userTemplates.map(template => {
                            return (
                                <Grid item xs={12} sm={12} md={6} lg={4} key={template.template_id}>
                                    <Box className="event-paper" onClick={() => setEvent(template.template_id)}>
                                        <Box className='event-color' sx={{background:template.colour}}></Box>
                                        <Box className="event-details-user" >
                                            <Box sx={{display:"flex", justifyContent:"space-between", alignItems:'center', width:1, marginY:2, paddingTop:1}}>
                                                <Box sx={{display:"flex", width:1, alignItems:'center', marginY:1}}>
                                                    <AccessAlarmIcon sx={{paddingRight:1}} />
                                                    <Typography component='h4' variant='h5'>{template.duration / 1000/60} mins</Typography>
                                                </Box>
                                                {template.icon ? <img src={template.icon} alt={'icon'} width={38} height={26}/> : <Typography component='h4' variant='h4'>{template.type_name}</Typography>}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>

                <Box mt={5} className='bottom-text-holder'>
                    <Typography className='bottom-text' component="h6" variant='p'>Have any questions before you schedule a meeting?</Typography>
                    <Typography className='bottom-text' component="h6" variant='p'>Feel free to ask me anything. <a href={`mailto:${userEmail}`}>Write an Email.</a></Typography>
                </Box>

                </Box>
        </Box>
    )
}
