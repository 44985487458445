import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddIcon from "@mui/icons-material/Add";
import {
    Box,
    LinearProgress,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from "@mui/material";
import {useState} from "react";
import LoginToken from "../../../../local_storage/LoginToken";

async function addNewOrganizationTeam(accessToken, team_name, team_limit, organization_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/organization_teams`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        body: JSON.stringify({team_name, team_limit, organization_id})
    }).then(data => data.json())
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddTeam({organization_id, organization, teams, setTeams, rerender}) {
    const [open, setOpen] = useState(false);
    const [newName, setNewName] = useState('');
    const [newTeamLimit, setNewTeamLimit] = useState(null);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    let {getToken} = LoginToken();

    const handleNewName = (e) => {
        setNewName(e.target.value);
    };

    const handleTeamLimitChange = (e) => {
        setNewTeamLimit(parseInt(e.target.value));
    };

    const handleSave = () => {
        if (!newName || newName === '') {
            setError('Add Name');
        } else if(teams.find(t => t.team_name === newName)){
            setError('Email already exists.');
        } else {
            setSaving(true);
            addNewOrganizationTeam(getToken()?.token, newName, newTeamLimit, organization.organization_id).then(result => {
                console.log(result);
                if(result?.state) {
                    let newArray = [...teams];
                    newArray.push(result.added);
                    setTeams(newArray);
                    setError(null);
                    setOpen(false);
                    setSaving(false);
                    rerender(true);
                } else {
                    setError(result?.error ? result.error : 'Error on adding user.');
                    setSaving(false);
                }
            })

            // Save logic
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setNewName('');
        setNewTeamLimit(null);
        setError(null);
        setSaving(false);
        setOpen(false);
    };

    return (
        <Box>
            <Button startIcon={<AddIcon/>} size={'small'} color={'success'} onClick={handleClickOpen}>
                add new team
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>

                    { saving && <LinearProgress/> }

                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Add new team
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        '& > :not(style)': { m: 1, width: 500 },
                        textAlign:'center',
                        display:'flex',
                        flexDirection:'column',
                        margin:'40px auto',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography color={'error'}>{error}</Typography>
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Typography component='h2' variant='h4'>Add New Email</Typography>
                    </Box>
                    <TextField
                        disabled={saving}
                        type='text'
                        value={newName}
                        onChange={handleNewName}
                        error={error}
                        helperText={error ? "Please enter a valid name" : ""}
                        id="name"
                        label="Name"
                        variant="outlined"
                        required
                    />
                    <TextField
                        disabled={saving}
                        type='number'
                        value={newTeamLimit}
                        onChange={handleTeamLimitChange}
                        id="name"
                        label="User Limit"
                        variant="outlined"
                    />
                    <Button type='submit' variant='contained' onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Dialog>
        </Box>
    );
}


