import {Button, Box, Typography, Grid} from "@mui/material";
import '../style/TimePicker.css'
import '../style/ScheduleTimePickerMobile.css'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {useEffect, useRef, useState} from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function ScheduleTimePickerMobile({times, rerenderComponent, setRerenderComponent, setRerender, setTimes,selectedDate, path, event, duration, selectedTimeZone, eventGroup}){

    const [firstRender, setFirstRender] = useState(rerenderComponent);
    let key = 0;

    const scrollRef = useRef(null);
    const handleScroll = () => {
        scrollRef.current.scrollBy(107, 0);
    };

    useEffect(() => {
        if(firstRender || rerenderComponent){
            for (let i = 0; i < times.length; i++){
                if(times[i].occupied){
                    let go_back = i - (duration/1000/60/30 -1);
                    //console.log(go_back)
                    while (go_back !== i && i >= 0){
                        let time = times[go_back++];
                        if(time) time.occupied = true;
                    }
                }
            }
            setFirstRender(false)
            setRerenderComponent(false)
        }
    });

    const setTime = (time) => {
        let hrMin = time.split(":")
        window.location.pathname = `${path}/${eventGroup}/${event}/${hrMin[0]}/${hrMin[1]}/${selectedDate.year()}/${selectedDate.month()}/${selectedDate.date()}/${selectedTimeZone}`
    }

    const selectTime = (time) => {
        console.log(time)
        times.forEach(t => {
            if(time.start === t.start){
                t['selected'] = true;
            } else {
                t['selected'] = false;
            }
        })
        setRerender(true)
        setTimes(times)
    }

    let i = 0;
    return (
        <Grid container className='time-arrow-holder' sx={{textAlign:"center"}}>
            <Grid item xs={10} sm={10}>
                <Box className="buttons_holder" ref={scrollRef}>
                    {
                        times.map((time, index) => {
                            if(index === 0) {
                                return (
                                    <Box key={key++}>
                                        <Button className={time?.selected ? 'small-time-button' : 'time-button'}
                                                variant="outlined" disabled={time.occupied}
                                                onClick={() => selectTime(time)}>
                                            {time.start}
                                        </Button>
                                        {time?.selected &&
                                            <Button className='select-time-button' variant="outlined"
                                                    onClick={() => setTime(time.start)}>
                                                <ArrowForwardIosIcon sx={{width: 15}}/>
                                            </Button>
                                        }
                                    </Box>
                                )
                            } else {
                                if(time.start !== times[index+1]?.start) {
                                    return (
                                        <Box key={key++}>
                                            <Button className={time?.selected ? 'small-time-button' : 'time-button'}
                                                    variant="outlined" disabled={time.occupied}
                                                    onClick={() => selectTime(time)}>
                                                {time.start}
                                            </Button>
                                            {time?.selected &&
                                                <Button className='select-time-button' variant="outlined"
                                                        onClick={() => setTime(time.start)}>
                                                    <ArrowForwardIosIcon sx={{width: 15}}/>
                                                </Button>
                                            }
                                        </Box>
                                    )
                                }
                            }
                        })
                    }
                </Box>
            </Grid>
            <Grid item xs={2} sm={2} className='icon-holder'>
                <KeyboardDoubleArrowRightIcon sx={{color:'#D9D9D9', marginTop:3, cursor:"pointer"}} onClick={handleScroll}/>
            </Grid>
        </Grid>
    )
}
