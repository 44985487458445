import {Box, Button, Typography} from "@mui/material";
import LoginToken from "../../local_storage/LoginToken";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import TimeZoneSelect from "../../UserScheduleComponents/TimeZoneSelect";
import * as React from "react";
import dayjs from "dayjs";


import '../../style/NewCalendar.css'


async function newCalendar(credentials) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/new`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(data => data.json())
}

export default function NewCalendar({ setReloadCalendars }){
    const { tokenData, isTokenExpired, removeToken, getToken } = LoginToken();
    const [initialRender, setInitialRender] = useState(true);
    const [access_token, setAccess_token] = useState(null);
    const [refresh_token, setRefresh_token] = useState(null);
    const [expiry, setExpiry] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [user_id, setUser_id] = useState(tokenData() ? tokenData().user_id: null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [defaultCalendar, setDefaultCalendar] = useState(true);
    const [selectedTimezone, setSelectedTimezone] = useState({})

    useEffect(() => {
        if(initialRender){
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            if(!urlParams.get('access_token')) {
                navigate("/dashboard");
            } else {
                setAccess_token(urlParams.get('access_token'));
                setRefresh_token(urlParams.get('refresh_token'));
                setExpiry(urlParams.get('expiry'));
                setName(urlParams.get('name'));
                setEmail(urlParams.get('email'));
                //window.history.pushState(null, 'New Calendar', 'http://localhost:3000/new_calendar');
            }
            setInitialRender(false);
        }
    });

    const goBack = () => {
        navigate("/dashboard");
    }

    const addCalendar = () => {
        newCalendar({access_token, refresh_token, expiry, name: `${name} - ${email}`, user_id, defaultCalendar, timezone: (selectedTimezone && selectedTimezone?.value) ? selectedTimezone?.value : dayjs.tz.guess()}).then(result => {
            if(result.status){
                setReloadCalendars(true);
                navigate("/dashboard");
            } else {
                setError([result.message, result.new_calendar.message]);
            }
            console.log(result);
        })
    }

    return(
        <Box sx={{display:"flex", justifyContent:'center', alignItems:'center', height:"100vh", backgroundColor:"#414A4C"}}>
            <Box sx={{width:400}}>
                {error && error.map(err => <Typography sx={{color:"#cc3f25", marginLeft:2, marginBottom:1}} key = {err}>{err}</Typography>)}
                <Box sx={{textAlign:"start", color:'#FFF'}}>
                    <Typography component="h2" variant="h5" px={2}>New Calendar</Typography>
                    <Typography px={2}>Name: {name}</Typography>
                    <Typography px={2}>Email: {email}</Typography>
                    <Typography px={1}><Checkbox sx={{color:'#FFF'}} checked={defaultCalendar} value={defaultCalendar} onChange={() => {setDefaultCalendar(!defaultCalendar)}}/> Save events to this calendar </Typography>
                    <TimeZoneSelect selectedTimezone={selectedTimezone} setSelectedTimezone={setSelectedTimezone} changedTimezone={() => {}}/>
                </Box>

                <Box sx={{display:"flex", alignItems:"center", justifyContent:"start", marginTop:5, marginLeft:2}}>
                    <Button variant='outlined' onClick={addCalendar} sx={{width:160, marginRight:1, color:"#FFF", borderColor:'#FFF'}}>Add calendar</Button>
                    <Button variant='outlined' onClick={goBack} sx={{width:120, color:"#FFF", borderColor:'#FFF'}}>Go back</Button>
                </Box>
                </Box>

        </Box>
    )

}
