import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import LoginToken from './local_storage/LoginToken';
import {useEffect, useState} from "react";
import Login from "./components/login_register/Login";
import Dashboard from "./components/dashboard/Dashboard";
import NotFound from "./components/404/NotFound";
import NewCalendar from "./components/dashboard/NewCalendar";

import {LogoutButton, logoutUser} from "./components/login_register/LogoutButton";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";
import * as React from "react";
import ScheduleCalendar from "./UserScheduleComponents/ScheduleCalendar";
import RenderScheduleComponent from "./UserScheduleComponents/RenderScheduleComponent";
import UserSchedule from "./UserScheduleComponents/UserSchedule";
import PrivacyPolicy from "./components/OtherPages/PrivacyPolicy";
import ConnectCalendars from "./microsoft/ConnectCalendars";
import NotAllowedEmail from "./microsoft/NotAllowedEmail";
import Homepage from "./components/Homepage/Homepage";
import Terms from "./components/OtherPages/Terms";
import ClearCookies from "./microsoft/ClearCookies";
import ConnectStripe from "./components/dashboard/Components/ConnectStripe";

async function userCalendars(user_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars?login_token=${user_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(data => data.json())
}

function App() {
    const navigate = useNavigate();
    const { tokenData, isTokenExpired, removeToken, getToken } = LoginToken();
    const [token, setLoginToken] = useState(false);
    const [haveCalendars, setHaveCalendars] = useState(false);
    const [pathName, setPathName] = useState(window.location.pathname[window.location.pathname.length-1] === '/' ? window.location.pathname.substring(0, window.location.pathname.length-1) : window.location.pathname);
    const [reloadCalendars, setReloadCalendars] = useState(false);


    useEffect(() => {
        if(reloadCalendars){
            let token_data = getToken();
            if(token_data){
                connectedCalendars(token_data.token).then(connected => {
                    setHaveCalendars(connected);
                })
            }
            setReloadCalendars(false)
        }
    });

    useEffect( () => {
        if(!token) {
            if(getToken() && isTokenExpired()) {
                logoutUser({ login_token: getToken().token }).then( (result) => {
                    removeToken();
                    setLoginToken(false);
                    navigate("/login");
                    //window.location.reload(false);
                });
            }
            else {
                let token_data = getToken();
                if(token_data){
                    connectedCalendars(token_data.token).then(connected => {
                        setHaveCalendars(connected);
                    })
                }
                setLoginToken(tokenData());
            }
        }

    }, [token, getToken, isTokenExpired, removeToken, tokenData]);

    const logout = () => {
        logoutUser({ login_token: getToken().token }).then( (result) => {
            removeToken();
            setLoginToken(false);
            navigate("/login");
            //window.location.reload(false);
        });
    }

    const connectedCalendars = (login_token) => {
        return userCalendars(login_token).then(result => {
            return result && result.length > 0;
        })

    }

  return (
      <Routes>
          <Route path="/microsoft" element={<ConnectCalendars setReloadCalendars={setReloadCalendars} setLoginToken={setLoginToken}/>} />
          <Route path="/microsoft_now_allowed" element={<NotAllowedEmail/>} />
          <Route path="/microsoft_clear_cookies" element={<ClearCookies/>} />
          <Route path="*" element={<UserSchedule path={pathName}/>} />
          <Route path="/" element={<Homepage setLoginToken={setLoginToken} login_token={token}/>} />
          <Route path="/login" element={<Login setLoginToken={setLoginToken} login_token={token}/>} />
          {/*<Route path="/microsoft" element={<SignedIn/>} />*/}
          <Route path="/privacy_policy" element={<PrivacyPolicy/>} />
          <Route path="/terms_and_conditions" element={<Terms/>} />
          <Route element={<PrivateRoutes token={tokenData()}/>}>
              <Route path="/dashboard" element={<Dashboard setLoginToken={ setLoginToken } logoutUser={logout} haveCalendars={haveCalendars}/>} />
              <Route path="/new_calendar" element={<NewCalendar setReloadCalendars={setReloadCalendars}/>} />
              <Route path="/connect-stripe" element={<ConnectStripe login_token={token}/>} />
          </Route>
      </Routes>
  );
}

export default App;
