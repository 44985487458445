import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../media/Zingley-Logo-Dark.png'
import {useNavigate} from "react-router-dom";

export default function Header() {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/login')
    }

    return (
        <Box sx={{ flexGrow: 1, position:'fixed', width:1, zIndex:10}}>
            <AppBar position="static" sx={{background: 'white', color:'#333', display:'flex', justifyContent:'center', alignItems:'center', width:1}}>
                <Toolbar sx={{display:'flex', justifyContent:'space-between', alignItems:'center', maxWidth: 1440, width:1, padding:'0 !important'}}>
                    <a style={{display:'flex', alignItems:'center'}} href={"/"}>
                        <img src={logo} alt={'Logo image'} width={150} />
                    </a>

                    <Box sx={{ flexGrow: 1, display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {/*<Typography variant="h6">Product</Typography>
                        <Typography variant="h6">News</Typography>
                        <Typography variant="h6">News</Typography>*/}
                    </Box>

                    <Button color="inherit" sx={{textTransform:'none', fontSize: 18, paddingX:3}} onClick={handleLoginClick}>Log In</Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
