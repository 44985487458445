import React from 'react';

export default function NotFound( ) {
    return(
        <div>
            <h1>Oops! You seem to be lost.</h1>
        </div>

    );
}
