import {Button, Box, Typography} from "@mui/material";
import '../style/TimePicker.css'
import '../style/ScheduleTimePicker.css'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {useEffect, useRef, useState} from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function ScheduleTimePicker({times, rerenderComponent, setRerenderComponent, setRerender, setTimes,selectedDate, path, event, duration, selectedTimeZone, eventGroup, adminTimezone, timesDayBefore, timesDayAfter}){

    const [firstRender, setFirstRender] = useState(rerenderComponent);
    let key = 0;

    const scrollRef = useRef(null);
    const handleScroll = () => {
        scrollRef.current.scrollBy(0, 44);
    };

    useEffect(() => {
        if(firstRender || rerenderComponent){

            for (let i = 0; i < times.length; i++){
                let start = times[i].start;
                //let start_hours = parseInt(start.substr(0, 2));

                //console.log({start_hours});
                if(times[i].occupied){
                    let go_back = i - (duration/1000/60/30 -1);
                    //console.log(go_back)
                    while (go_back !== i && i >= 0){
                        let time = times[go_back++];
                        if(time) time.occupied = true;
                    }
                }
            }

            const adminTime = dayjs().tz(adminTimezone);
            const selectedTime = dayjs().tz(selectedTimeZone);
            let admin_offset = adminTime.utcOffset();
            let selected_offset = selectedTime.utcOffset();
            let difference = (admin_offset - selected_offset) / 60;
            //console.log({difference})

            if(difference < 0) {
                //console.log("difference < 0")
                //console.log({timesDayBefore});
                let start_hour_today = parseInt(times[0].start.substr(0, 2));
                //console.log({start_hour_today});
                let num_of_steps = 0;
                for (let i = 0; i < times.length; i++) {
                    let start = times[i].start;
                    let start_hours = parseInt(start.substr(0, 2));
                    if((start_hours + difference) < 0) {
                        num_of_steps++;
                    }
                    //console.log(start_hours + difference);
                }
                let lastHours = 24 - num_of_steps/2;
                //console.log({lastHours});
                if(timesDayBefore?.length > 0 && parseInt(timesDayBefore[timesDayBefore.length -1].start.substr(0, 2)) > lastHours) {
                    let lastTimesBeforeHour = parseInt(timesDayBefore[timesDayBefore.length -1].start.substr(0, 2));
                    if(lastTimesBeforeHour !== 24){
                        num_of_steps -= 2*(24-lastTimesBeforeHour);
                        times.splice(0, 2*(24-lastTimesBeforeHour));
                    }
                    let j = 0;
                    //if there is some times find free and set times to not occupied
                    for(let i = timesDayBefore.length -1; i > timesDayBefore.length - num_of_steps-1; i--) {
                        let hours = parseInt(timesDayBefore[i].start.substr(0, 2));
                        times[j++].occupied = timesDayBefore[i].occupied;
                        //console.log(timesDayBefore[i].occupied)
                        //console.log(hours);
                    }
                }
                //console.log({num_of_steps});
            }

            if(difference > 0) {

            }
            //console.log({adminTime, selectedTime, admin_offset, selected_offset, difference})


            setFirstRender(false)
            setRerenderComponent(false)
        }
    });

    const setTime = (time) => {
        let hrMin = time.split(":")
        window.location.pathname = `${path}/${eventGroup}/${event}/${hrMin[0]}/${hrMin[1]}/${selectedDate.year()}/${selectedDate.month()}/${selectedDate.date()}/${selectedTimeZone}`
    }

    const selectTime = (time) => {
        //console.log(time)
        times.forEach(t => {
            if(time.start === t.start){
                t['selected'] = true;
            } else {
                t['selected'] = false;
            }
        })
        setRerender(true)
        setTimes(times)
    }

    return (
        <Box sx={{textAlign:"center"}}>
            <Box className="buttons_holder" ref={scrollRef}>
                {
                    times.map((time, index) => {
                        if(index === 0) {
                            return (
                                <Box key={key++}>
                                    <Button  className={time?.selected ? 'small-time-button' : 'time-button'} variant="outlined" disabled={time.occupied}
                                             onClick={() => selectTime(time)}>
                                        {time.start}
                                    </Button>
                                    {time?.selected &&
                                        <Button className='select-time-button' variant="outlined"
                                                onClick={() => setTime(time.start)}>
                                            <ArrowForwardIosIcon sx={{width:15}}/>
                                        </Button>
                                    }
                                </Box>
                            )
                        } else {
                            if(time.start !== times[index+1]?.start){
                                return (
                                    <Box key={key++}>
                                        <Button  className={time?.selected ? 'small-time-button' : 'time-button'} variant="outlined" disabled={time.occupied}
                                                 onClick={() => selectTime(time)}>
                                            {time.start}
                                        </Button>
                                        {time?.selected &&
                                            <Button className='select-time-button' variant="outlined"
                                                    onClick={() => setTime(time.start)}>
                                                <ArrowForwardIosIcon sx={{width:15}}/>
                                            </Button>
                                        }
                                    </Box>
                                )
                            }
                        }

                    })
                }
            </Box>
            <KeyboardDoubleArrowDownIcon sx={{color:'#D9D9D9', marginTop:3, cursor:"pointer"}} onClick={handleScroll}/>


        </Box>
    )
}
