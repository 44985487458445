import React, {useEffect, useState} from "react"
import TimezoneSelect, {allTimezones } from "react-timezone-select"
import {Box, Typography} from "@mui/material";
import dayjs from "dayjs";

export default function TimeZoneSelect({selectedTimezone, setSelectedTimezone, changedTimezone}) {

    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if(firstRender){
            setSelectedTimezone(dayjs.tz.guess())
            setFirstRender(false)
        }
    },[firstRender]);

    return (
        <Box className='timezone'>
            <Typography variant='h6' component='p'>Time zone</Typography>
            <Box className="tz-select-wrapper" sx={{maxWidth:280}} >
                <TimezoneSelect
                    value={selectedTimezone}
                    onChange={(e) => {
                        setSelectedTimezone(e)
                        changedTimezone(e);
                    }}
                />
            </Box>
        </Box>
    )
}
