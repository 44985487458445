import {Button, IconButton, Snackbar} from '@mui/material'
import { useState } from 'react'
import LinkIcon from '@mui/icons-material/Link';

export default function CopyToClipboardButton ({text, height, variant, name, color}){
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(true)
        navigator.clipboard.writeText(text);
    }

    return (
        <>
            <Button size={'small'} variant={variant} color={color} sx={{height, borderRadius:'5px', textAlign: 'center'}} onClick={handleClick} >{name}<LinkIcon sx={{ml:1}}/></Button>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Copied to clipboard"
            />
        </>
    )
}
