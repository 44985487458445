import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddIcon from "@mui/icons-material/Add";
import {
    Box,
    LinearProgress,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from "@mui/material";
import {useState} from "react";

async function addUserEmail(email, is_admin, added_by, organization_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/allowed_emails`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email, is_admin, added_by, organization_id})
    }).then(data => data.json())
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddAllowedEmail({userEmail, allowedEmails, setAllowedEmails, organization, rerender}) {
    const [open, setOpen] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [userType, setUserType] = useState(''); // State for radio button selection
    const [emailError, setEmailError] = useState(false); // State for email validation error
    const [userTypeError, setUserTypeError] = useState(false); // State for radio button selection error
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const handleNewEmail = (e) => {
        setNewEmail(e.target.value);
        setEmailError(false); // Reset error when typing
    };

    const handleUserTypeChange = (e) => {
        setUserType(e.target.value);
        setUserTypeError(false); // Reset error when selecting
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
        return emailRegex.test(email);
    };

    const handleSave = () => {
        if (!validateEmail(newEmail)) {
            setEmailError(true);
        } else if (!userType) {
            setUserTypeError(true);
        } else if(allowedEmails.find(user => user.email === newEmail)){
            setError('Email already exists.');
        } else {
            setSaving(true);
            addUserEmail(newEmail, userType === 'admin', userEmail, organization.organization_id).then(result => {
                //console.log(result);
                if(result?.state) {
                    let newArray = [...allowedEmails];
                    newArray.push(result.added);
                    setAllowedEmails(newArray);
                    setError(null);
                    setOpen(false);
                    setSaving(false);
                    rerender(true);
                } else {
                    setError(result?.error ? result.error : 'Error on adding user.');
                    setSaving(false);
                }
            })

            // Save logic
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setNewEmail('');
        setUserType('');
        setError(null);
        setSaving(false);
        setOpen(false);
    };

    return (
        <Box>
            <Button startIcon={<AddIcon/>} size={'small'} color={'success'} onClick={handleClickOpen}>
                add new email
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>

                    { saving && <LinearProgress/> }

                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Add new user email
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        '& > :not(style)': { m: 1, width: 500 },
                        textAlign:'center',
                        display:'flex',
                        flexDirection:'column',
                        margin:'40px auto',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Typography color={'error'}>{error}</Typography>
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Typography component='h2' variant='h4'>Add New Email</Typography>
                    </Box>
                    <TextField
                        disabled={saving}
                        type='email'
                        value={newEmail}
                        onChange={handleNewEmail}
                        error={emailError}
                        helperText={emailError ? "Please enter a valid email" : ""}
                        id="name"
                        label="Email"
                        variant="outlined"
                        required
                    />
                    <RadioButtons userType={userType} onUserTypeChange={handleUserTypeChange} userTypeError={userTypeError} disabled={saving}/>
                    <Button type='submit' variant='contained' onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Dialog>
        </Box>
    );
}

function RadioButtons({ userType, onUserTypeChange, userTypeError, disabled }) {
    return (
        <FormControl sx={{display:'flex', alignItems:'center'}} error={userTypeError} disabled={disabled}>
            <FormLabel id="demo-row-radio-buttons-group-label">User type*</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={userType}
                onChange={onUserTypeChange}
            >
                <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                <FormControlLabel value="user" control={<Radio />} label="User" />
            </RadioGroup>
            {userTypeError && <Typography color="error">Please select a user type</Typography>}
        </FormControl>
    );
}


