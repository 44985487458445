import React from 'react';
import LoginToken from '../../local_storage/LoginToken';
import {Box, Button, IconButton, Typography} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import '../../style/LogoutButton.css'



async function logoutUser(credentials) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(data => data.json())
}

function LogoutButton({ setLoginToken }) {
    const { getToken, removeToken,  } = LoginToken();

    const handleClick = async e => {
        await logoutUser({ login_token: getToken().token }).then( (result) => {
            console.log(result)
            removeToken();
            setLoginToken(false);
            window.location.reload(false);
        });
    }
    return(
        <Button className='logout-button' variant="outlined" endIcon={<LogoutIcon />}
             onClick={handleClick}>
            Sign Out
        </Button>

    );
}

export {
    LogoutButton,
    logoutUser
}
