import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {
    Avatar,
    Box,
    LinearProgress,
    Tooltip
} from "@mui/material";
import {useState} from "react";
import LoginToken from "../../../../local_storage/LoginToken";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import {useEffect} from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GoogleCalendarImage from '../../../../media/Google_Calendar_icon_(2020).svg.png';
import MicrosoftCalendarImage from '../../../../media/MicrosoftCalendarIcon.png';

async function addNewTeamUser(accessToken, user_priority, is_team_manager, user_id, team_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/team_users`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        body: JSON.stringify({user_priority, is_team_manager, user_id, team_id})
    }).then(data => data.json())
}

async function removeTeamUser(accessToken, team_users_id) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/team_users`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        body: JSON.stringify({team_users_id})
    }).then(data => data.json())
}



async function getRegisteredUsers(accessToken) {
    return fetch(process.env.REACT_APP_SERVER_URL + '/organization/registered_users', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
    }).then(data => data.json());
}

async function getTeamDetails(accessToken, team_id) {
    return fetch(process.env.REACT_APP_SERVER_URL + `/team_users/for_team/${team_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
    }).then(data => data.json());
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditTeamUsers({organization, selectedTeam, teams, setTeams, rerender}) {
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [registeredUsers, setRegisteredUsers] = useState([]);
    const [teamDetails, setTeamDetails] = useState([]);
    let {getToken} = LoginToken();
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if(firstRender) {
            setSaving(true);
            getRegisteredUsers(getToken().token).then(ru_result => {
                if(ru_result.status) {
                    getTeamDetails(getToken().token, selectedTeam.organization_teams_id).then(result => {
                        console.log({team_details: result});
                        if(result.status) {
                            result.teams.forEach(item => {
                                let userId = ru_result.users.findIndex(u => u.user_id === item.user_id);
                                ru_result.users.splice(userId, 1);
                            })
                            setTeamDetails(result.teams);
                        }
                        setSaving(false);
                    })
                    setRegisteredUsers(ru_result.users);
                } else {
                    setError(ru_result?.message ? ru_result.message : "Error on getting users");
                }

                setSaving(false);
            })
            setFirstRender(false);
        }
    }, [firstRender]);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setError(null);
        setSaving(false);
        setOpen(false);
    };

    return (
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'end'}} pr={1}>
            <Tooltip title={`Edit team ${selectedTeam.team_name} users`}>
                <PeopleAltIcon sx={{fontSize:16}} cursor={'pointer'} onClick={handleClickOpen}/>
            </Tooltip>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>

                    { saving && <LinearProgress/> }

                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Edit team users
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box p={5}>
                    <Typography variant={'h4'} pb={4}>Team: {selectedTeam.team_name}</Typography>
                    {
                        !firstRender &&
                        <Details registeredUsers={registeredUsers} setRegisteredUsers={setRegisteredUsers} teamDetails={teamDetails} setTeamDetails={setTeamDetails} selectedTeam={selectedTeam} accessToken={getToken().token}/>
                    }
                </Box>
            </Dialog>
        </Box>
    );
}

function Details ({registeredUsers, setRegisteredUsers, teamDetails, setTeamDetails, selectedTeam, accessToken}) {

    const [limit, setLimit] = useState(selectedTeam.team_limit);
    const [limitExceeded, setLimitExceeded] = useState(limit && teamDetails.length >= limit);

    const handleAddUser = (user) => {
        let copyOfTeamDetails = [...teamDetails];
        let copyOfRegisteredUsers = [...registeredUsers];
        let indexToRemove = registeredUsers.findIndex(u => u.user_id === user.user_id);
        if(indexToRemove !== -1) {
            addNewTeamUser(accessToken, null, false, user.user_id, selectedTeam.organization_teams_id).then(result => {
                if(result.state) {
                    copyOfRegisteredUsers.splice(indexToRemove, 1);
                    setRegisteredUsers(copyOfRegisteredUsers);
                    copyOfTeamDetails.push({team_users_id: result.added.team_users_id, user_priority: null, is_team_manager: false, user_id: user.user_id, team_id: selectedTeam.organization_teams_id, ...user});
                    setTeamDetails(copyOfTeamDetails);
                    setLimitExceeded(limit && copyOfTeamDetails.length >= limit);
                }
            })

        }
    }

    const handleRemoveUser = (teamUser) => {
        let {user_id, name, email, google_id, path, picture, brand_image, background_image, mobile_background_image, microsoft_id, organization_id, role_id} = teamUser;
        let copyOfTeamDetails = [...teamDetails];
        let copyOfRegisteredUsers = [...registeredUsers];
        let indexToRemove = copyOfTeamDetails.findIndex(tu => tu.team_users_id === teamUser.team_users_id);
        if(indexToRemove !== -1) {
            removeTeamUser(accessToken, teamUser.team_users_id).then(result => {
                if(result) {
                    copyOfTeamDetails.splice(indexToRemove, 1);
                    setTeamDetails(copyOfTeamDetails);
                    copyOfRegisteredUsers.push({user_id, name, email, google_id, path, picture, brand_image, background_image, mobile_background_image, microsoft_id, organization_id, role_id});
                    setRegisteredUsers(copyOfRegisteredUsers);
                    setLimitExceeded(limit && copyOfTeamDetails.length >= limit);
                }
            })

        }
    }

    return (
        <Box sx={{display:'flex', justifyContent:'space-between', gap:2}}>
            <Box p={3} sx={{width:0.5, borderRadius:2, boxShadow:'0 0 30px 0 rgba(0,0,0,0.1)', display:'flex', gap:1, flexDirection:'column'}}>
                <Typography variant={'h6'} pb={2}>Other users</Typography>
                {
                    registeredUsers.map((user, index) => <SingleRegisteredUsers key={index} user={user} handleAddUser={handleAddUser} limitExceeded={limitExceeded}/>)
                }
            </Box>
            <Box p={3} sx={{width:0.5, borderRadius:2, boxShadow:'0 0 30px 0 rgba(0,0,0,0.1)', display:'flex', gap:1, flexDirection:'column'}}>
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}} pb={2}>
                    <Typography variant={'h6'}>Users in teams</Typography>
                    {limit && <Typography>Max users ({limit})</Typography>}
                </Box>
                {
                    teamDetails.map((tu, index) => <SingleTeamUsers key={index} teamUser={tu} handleRemoveUser={handleRemoveUser}/>)
                }
            </Box>

        </Box>
    )
}

function SingleRegisteredUsers ({user, handleAddUser, limitExceeded}) {

    const addUserToTeam = () => {
        if(!limitExceeded) {
            handleAddUser(user);
        }
    }

    return (
        <Box p={1} sx={{display:'flex', alignItems:'center', borderRadius:2, boxShadow:'0 0 30px 0 rgba(0,0,0,0.1)', gap:1, justifyContent:'space-between'}}>
            <Box sx={{display:'flex', alignItems:'center', gap:1}}>
                <Avatar src={user.picture} sx={{height:30, width:30}}/>
                <Typography>{user.name}</Typography>
                <Typography sx={{fontSize:12}}>({user.email})</Typography>
                <img src={user.google_id ? GoogleCalendarImage : MicrosoftCalendarImage} width={15} height={15}/>
            </Box>

            <Tooltip title={limitExceeded ? 'Limit Exceeded' : `Add ${user.name} to team`}>
                <ArrowForwardIcon cursor={!limitExceeded && 'pointer'} sx={{color:limitExceeded && 'gray'}} onClick={addUserToTeam}/>
            </Tooltip>

        </Box>
    )
}

function SingleTeamUsers ({teamUser, handleRemoveUser}) {

    const removeFromTeam = () => {
        handleRemoveUser(teamUser);
    }

    return (
        <Box p={1} sx={{display:'flex', alignItems:'center', borderRadius:2, boxShadow:'0 0 30px 0 rgba(0,0,0,0.1)', gap:1, justifyContent:'space-between'}}>
            <Tooltip title={`Remove ${teamUser.name} from team`}>
                <ArrowBackIcon cursor={'pointer'} onClick={removeFromTeam}/>
            </Tooltip>

            <Box sx={{display:'flex', alignItems:'center', gap:1}}>
                <Avatar src={teamUser.picture} sx={{height:30, width:30}}/>
                <Typography>{teamUser.name}</Typography>
                <Typography sx={{fontSize:12}}>({teamUser.email})</Typography>
                <img src={teamUser.google_id ? GoogleCalendarImage : MicrosoftCalendarImage} width={15} height={15}/>
            </Box>
        </Box>
    )
}


