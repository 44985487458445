import {Box, CssBaseline, ThemeProvider, Typography} from "@mui/material";
import Header from "./Header";
import * as React from "react";
import Theme from "../../Theme";
import Footer from "./Footer";
import screenshot1 from '../../media/Screenshot_1.jpg'
import screenshot2 from '../../media/Screenshot_2.jpg'
import conflictimage from '../../media/Group 21277.png'
import {useEffect, useState} from "react";


export default function Homepage() {
    const {theme} = Theme();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header/>
            <Box sx={{width:1, display:'flex', justifyContent:'center', paddingTop: "100px"}}>
                <Box sx={{maxWidth:1440, width:1, paddingY:4}}>
                    <HeroSection />
                    <KeyFeaturesSection />
                    <AdditionalFeaturesSection />
                    <Footer/>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

function HeroSection () {
    return (
        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:4}}>
            <Box sx={{width:0.4, display:'flex', flexDirection:'column', gap:3}}>
                <Typography variant={'h3'}>Effortlessly Manage and Schedule Your Events</Typography>
                <Typography>Seamlessly integrate with Google and Microsoft calendars, customize your event types, and avoid scheduling conflicts. Empower your team with personalized scheduling links and global customization options, all in one intuitive platform.</Typography>
                <LoginButtons />
            </Box>
            <ImageRotator screenshot1={screenshot1} screenshot2={screenshot2}/>
        </Box>
    )
}

function KeyFeaturesSection () {
    return (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', gap:4, marginTop: 8}}>
            <Typography variant={'h3'}>Key Features</Typography>
            <Typography sx={{maxWidth:700, textAlign:'center'}}>Integrate multiple Google and Microsoft calendars, customize and manage event types, avoid scheduling conflicts, and personalize your scheduling experience with seamless connectivity and intuitive tools.</Typography>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:2, width:0.8}}>
                <Box sx={{width:0.5, display:'flex', flexDirection:'column', gap:1, justifyContent:'center', alignItems:'start'}}>
                    <Typography variant={'h4'} mb={2}>Calendar Integration</Typography>
                    <Typography>Connect Multiple Calendars: Explain how users can connect Google or Microsoft calendars.</Typography>
                    <Typography>Conflict Checking: Describe the process of selecting calendars to avoid scheduling conflicts.</Typography>
                    <Typography>Default Calendar: Outline how to set a default calendar for storing meetings.</Typography>
                </Box>
                <Box sx={{width:0.5, display:'flex', flexDirection:'column', gap:3, justifyContent:'center', alignItems:'center'}}>
                    <img src={conflictimage} alt={'conflict image'} height={"250px"}/>
                </Box>
            </Box>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:2, width:0.8}} mt={4}>
                <Box sx={{width:0.5, display:'flex', flexDirection:'column', gap:1, justifyContent:'center', alignItems:'start'}}>
                    <Typography variant={'h4'} mb={2}>Event Management</Typography>
                    <Typography>Event Types: Detail the creation of event types with names, user groups, and working hours.</Typography>
                    <Typography>Event Customization: Describe options for embedding, customization, and notifications.</Typography>
                </Box>
                <Box sx={{width:0.5, display:'flex', flexDirection:'column', gap:1, justifyContent:'center', alignItems:'start'}}>
                    <Typography variant={'h4'} mb={2}>Scheduling Links</Typography>
                    <Typography>Copy and Embed: Explain how users can copy scheduling links and embed calendars on their websites.</Typography>
                    <Typography>Customization Options: Provide details on how users can personalize event appearances, backgrounds, and logos.</Typography>
                </Box>
            </Box>
        </Box>
    )
}

function AdditionalFeaturesSection () {
    return (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', gap:1, marginTop: 8}} mb={8}>
            <Typography variant={'h3'} mb={3} mt={2}>Additional Features</Typography>
            <Typography sx={{textAlign:'center'}}>Auto Accept Meetings: Highlight the option to automatically accept meetings when enabled.</Typography>
            <Typography sx={{textAlign:'center'}}>Non-Working Dates: Explain how to add non-working dates and specific non-working hours.</Typography>
            <Typography sx={{textAlign:'center'}}>Global Customization: Showcase options for global personalization, including backgrounds, logos, and avatars.</Typography>
        </Box>
    )
}

function LoginButtons () {
    return (
        <Box sx={{alignItems:'center', display:'flex', gap:2}} className='login-buttons'>
            <a href={'/login'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" fill="none" viewBox="0 0 40 41"><rect width="40" height="40" y="0.5" fill="#fff" rx="4"></rect><path fill="#4285F4" d="M31.766 20.776c0-.815-.066-1.635-.207-2.438H20.24v4.621h6.482a5.554 5.554 0 0 1-2.399 3.647v2.998h3.867c2.271-2.09 3.576-5.177 3.576-8.828Z"></path><path fill="#34A853" d="M20.24 32.5c3.237 0 5.966-1.062 7.955-2.896l-3.867-2.998c-1.076.731-2.465 1.146-4.084 1.146-3.13 0-5.784-2.112-6.737-4.952h-3.99v3.091a12.002 12.002 0 0 0 10.723 6.61Z"></path><path fill="#FBBC04" d="M13.503 22.8a7.187 7.187 0 0 1 0-4.594v-3.091H9.517a12.01 12.01 0 0 0 0 10.776l3.986-3.09Z"></path><path fill="#EA4335" d="M20.24 13.25a6.52 6.52 0 0 1 4.603 1.799l3.427-3.426A11.533 11.533 0 0 0 20.24 8.5a11.998 11.998 0 0 0-10.723 6.614l3.986 3.09c.948-2.843 3.607-4.955 6.737-4.955Z"></path><defs><path fill="#fff" d="M8 8.5h24v24H8z"></path></defs></svg>
                <span style={{marginLeft:'5px'}}>Continue With Google</span>
            </a>
            <a href={`/login`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" fill="none" viewBox="0 0 40 41"><rect width="40" height="40" y="0.5" fill="#fff" rx="4"></rect><path fill="#FEBA08" d="M21.023 21.523H31.25V31.75H21.023z"></path><path fill="#05A6F0" d="M8.75 21.523h10.227V31.75H8.75z"></path><path fill="#80BC06" d="M21.023 9.25H31.25v10.227H21.023z"></path><path fill="#F25325" d="M8.75 9.25h10.227v10.227H8.75z"></path></svg>
                <span style={{marginLeft:'5px'}}>Continue With Microsoft</span>
            </a>
        </Box>
    )
}

function ImageRotator({ screenshot1, screenshot2 }) {
    const [isFront, setIsFront] = useState(true);

    const handleOnHover = () => {
        setIsFront((prev) => !prev);
    }

    // Automatically toggle every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setIsFront((prev) => !prev);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    // Alternatively, toggle on click
    // const handleClick = () => {
    //   setIsFront((prev) => !prev);
    // };

    return (
        <Box
            onMouseEnter={handleOnHover}
            onMouseLeave={handleOnHover}
            sx={{
                width: 0.6,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <img
                src={screenshot1}
                alt={'screenshot of scheduler'}
                style={{
                    opacity: isFront ? "100%" : "70%",
                    borderRadius: '16px',
                    position: 'absolute',
                    top: !isFront ? -190 : -150,
                    transition: 'width 0.5s, z-index 0.5s, top 0.5s, box-shadow 0.5s, opacity 0.5s',
                    zIndex: isFront ? 2 : 1,
                    width: isFront ? '70%' : '65%',
                    boxShadow: isFront
                        ? '0 4px 8px rgba(0, 0, 0, 0.2)' // Shadow for the front image
                        : '0 2px 4px rgba(0, 0, 0, 0.1)', // Shadow for the back image
                }}
            />
            <img
                src={screenshot2}
                alt={'screenshot 2 of scheduler'}
                style={{
                    opacity: !isFront ? "100%" : "70%",
                    borderRadius: '16px',
                    position: 'absolute',
                    transition: 'width 0.5s, z-index 0.5s, top 0.5s, box-shadow 0.5s, opacity 0.5s',
                    top: isFront ? -190 : -150,
                    zIndex: isFront ? 1 : 2,
                    width: isFront ? '65%' : '70%',
                    boxShadow: isFront
                        ? '0 2px 4px rgba(0, 0, 0, 0.1)' // Shadow for the back image
                        : '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for the front image
                }}
            />
        </Box>
    );
}
