import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddIcon from "@mui/icons-material/Add";
import {
    Avatar,
    AvatarGroup,
    Box,
    Checkbox,
    DialogContent,
    DialogTitle,
    LinearProgress,
    TextField,
    Tooltip
} from "@mui/material";
import {useEffect, useState} from "react";
import LoginToken from "../../../../local_storage/LoginToken";

async function getOtherCalendars(token) {
    return fetch(`${process.env.REACT_APP_SERVER_URL}/calendars/get_all_other_calendars?login_token=${token}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data.json())
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewGroup({saveUserGroup, saveUserGroupWithCalendars}) {
    const { getToken, tokenData } = LoginToken();
    const [open, setOpen] = useState(false);
    const [newName, setNewName] = useState('');
    const [addedCalendars, setAddedCalendars] = useState([]);
    const [openAddCalendars, setOpenAddCalendars] = useState(false);
    const [firstRender, setFirstRender] = useState(true);
    const [otherCalendars, setOtherCalendars] = useState(null);

    useEffect(() => {
        if(firstRender && open) {
            getOtherCalendars(getToken().token).then(result => {
                setOtherCalendars(result)
            })
            setFirstRender(false)
        }
    }, [firstRender, open]);

    const handleNewName = (e) => {
        setNewName(e.target.value)
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        if(newName){
            if(addedCalendars.length > 0) {
                saveUserGroupWithCalendars(newName, addedCalendars);
                setOpen(false);
                setNewName('');
                setAddedCalendars([]);
                setOtherCalendars([...otherCalendars].map(cal => {
                    cal.checked = false;
                    return cal;
                }))
                console.log("Save with calendars")
            } else {
                saveUserGroup(newName)
                setOpen(false);
                setNewName('');
            }

        }

    };

    const handleToggleAddGroup = () => {
        setOpenAddCalendars(!openAddCalendars);
    };

    return (
        <Box >
            <Button variant="outlined" color='success' sx={{borderRadius:2, marginLeft:2}} startIcon={<AddIcon/>} onClick={handleClickOpen}>
                New Group
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Create New Group
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        '& > :not(style)': { m: 1, width: 500 },
                        textAlign:'center',
                        display:'flex',
                        flexDirection:'column',
                        margin:'40px auto',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <Typography component='h2' variant='h4'>Add New Group</Typography>
                    </Box>
                    <TextField value={newName} onChange={handleNewName} id="name" label="Event Name" variant="outlined" required/>
                    <AvatarGroup style={{justifyContent:'center'}}>
                        {addedCalendars.map((user) =>
                            <Tooltip title={user.name} key={user.user_id}>
                                <Avatar src={user.picture} alt={user.name}/>
                            </Tooltip>
                        )}
                    </AvatarGroup>
                    <Button variant='contained' onClick={handleToggleAddGroup}>{addedCalendars.length > 0 ? "Edit Added Users" : "Add Other Users"}</Button>
                    <Button type={'submit'} variant='contained' onClick={handleSave}>Save</Button>
                </Box>
                <AddOtherCalendars openAddCalendars={openAddCalendars} handleToggleAddGroup={handleToggleAddGroup} otherCalendars={otherCalendars} setOtherCalendars={setOtherCalendars} setAddedCalendars={setAddedCalendars}/>
            </Dialog>
        </Box>
    );
}

function AddOtherCalendars({openAddCalendars, handleToggleAddGroup, otherCalendars, setOtherCalendars, setAddedCalendars}) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(!(otherCalendars));
    }, [otherCalendars]);

    const changeChecked = (user) => {
        user.checked = !user.checked;
        setOtherCalendars(otherCalendars);
        setAddedCalendars([...otherCalendars].filter(oc => oc.checked));
    }

    if(openAddCalendars){
        return (
            <Dialog
                fullWidth
                open={openAddCalendars}
                onClose={handleToggleAddGroup}
            >
                <DialogTitle>
                    Add other users calendars
                </DialogTitle>
                {
                    loading ? <LinearProgress/>
                        :
                        <DialogContent>
                            {otherCalendars.map((user, index) =>
                                <SingleUserCalendar key={index} user={user} changeChecked={changeChecked}/>
                            )}
                        </DialogContent>
                }
            </Dialog>
        )
    }

}

function SingleUserCalendar ({user, changeChecked}) {
    const [checked, setChecked] = useState(user.checked);
    const [disabled, setDisabled] = useState(user.disabled);
    const [calendarsVerified, setCalendarsVerified] = useState(!(user.calendars.find(c => !c.verified)));

    useEffect(() => {
        setChecked(user.checked);
    }, [user]);

    const handleChange = () => {
        setChecked(!checked);
        changeChecked(user);
    }

    return (
        <Box sx={{display:'flex', alignItems:'center', borderBottom:'1px dotted #999'}}>
            <Checkbox disabled={!calendarsVerified || disabled} checked={checked} onChange={handleChange}/>
            <Avatar src={user.picture} alt={user.name} sx={{width:32, height:32}}/>
            <Tooltip title={user.email} >
                <Typography ml={1}>{user.name}</Typography>
            </Tooltip>
            <Typography ml={1} sx={{fontSize:12, color:'#999'}}> {disabled ? '(different calendar type)' : ''}</Typography>
            {(!calendarsVerified) && <Typography color={'error'} sx={{fontSize:12}} ml={1}>(User calendars tokens expired, can`t be used.)</Typography> }
        </Box>
    )
}
