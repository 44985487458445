import {Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import AddAllowedEmail from "./AddAllowedEmail";
import {useEffect, useState} from "react";
import DeleteAlertDialog from "./DeleteAlertDialog";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import DeleteTeamAlertDialog from "./DeleteTeamAlertDialog";
import AddTeam from "./AddTeam";
import LoginToken from "../../../../local_storage/LoginToken";
import EditTeamUsers from "./EditTeamUsers";

async function updateTeam(organization_teams_id, team_name, team_limit, organization_id, accessToken) {
    return fetch(process.env.REACT_APP_SERVER_URL + '/organization_teams', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        },
        body: JSON.stringify({organization_teams_id, team_name, team_limit, organization_id})
    }).then(data => data.json());
}

export default function OrganizationTeams({organization, teams, setTeams, rerender}) {

    return (
        <Box>

            <Grid container spacing={2} mb={2}>
                <Grid item xs={1} >ID</Grid>
                <Grid item xs={8} >Name</Grid>
                <Grid item xs={2} >User Limit</Grid>
                <Grid item xs={0.5} ></Grid>
                <Grid item xs={0.5} ></Grid>
            </Grid>
            <Box sx={{overflow: 'auto', height:460}}>
                {
                    teams.map((item, index) =>
                        <SingleAllowedEmail index={index} item={item} key={index} setTeams={setTeams} teams={teams} organization={organization} rerender={rerender} organization_id={organization.organization_id}/>
                    )
                }
                <Box>
                    <AddTeam setTeams={setTeams} teams={teams} organization={organization} rerender={rerender}/>
                </Box>
            </Box>
        </Box>
    )
}

function SingleAllowedEmail({item, teams, setTeams, organization, rerender, organization_id, index}) {

    const [id, setId] = useState(item.organization_teams_id);
    const [name, setName] = useState(item.team_name);
    const [teamLimit, setTeamLimit] = useState(item.team_limit);
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState(null);
    let {getToken} = LoginToken();

    useEffect(() => {
        setId(item.organization_teams_id);
        setName(item.team_name);
        setTeamLimit(item.team_limit);
    }, [item]);

    useEffect(() => {
        if(update) {
            updateTeam(id, name, teamLimit, item.organization_id, getToken()?.token).then(result => {
                if(result.state) {
                    item.team_name = name;
                    item.team_limit = teamLimit;
                    setError(null);
                } else {
                    setError(result.error);
                }
            }).catch(() => {
                setError("Error on email update");
            })
            setUpdate(false);

        }

    }, [update]);

    const updateOrganizationTeamLimit = (new_limit) => {
        console.log({new_limit})
        updateTeam(id, name, new_limit !== '' ? new_limit : null, item.organization_id, getToken()?.token).then(result => {
            if(result.state) {
                item.team_limit = (new_limit !== '' ? new_limit : null);
                let newArray = [...teams];
                let found = newArray.find(t => t.organization_teams_id === id);
                found.team_limit = (new_limit !== '' ? new_limit : null);
                setTeams(newArray)
            }
        })
    }

    return (
        <Grid container spacing={0} my={1} py={0.5} sx={{fontSize:14, position:'relative', background: index % 2 === 0 ? 'rgba(0,0,0,0.1)' : 'none'}}>
            {
                error && <Grid item xs={12} sx={{fontSize:10, color:'red'}}>{error}</Grid>
            }

            <Grid item xs={1} pl={1} my={'auto'}>{id}</Grid>
            <Grid item xs={8} pl={1} my={'auto'}><Name name={name} setName={setName} setUpdate={setUpdate}/> </Grid>
            <Grid item xs={2} pl={2} pr={1} my={'auto'}><Limit updateLimit={updateOrganizationTeamLimit} setLimit={setTeamLimit} limit={teamLimit}/> </Grid>
            <Grid item xs={0.5} my={'auto'}>{<DeleteTeamAlertDialog teamToRemove={item} setTeams={setTeams} teams={teams} rerender={rerender}/>}</Grid>
            <Grid item xs={0.5} my={'auto'}>{<EditTeamUsers selectedTeam={item} setTeams={setTeams} teams={teams} rerender={rerender} organization={organization}/>}</Grid>

        </Grid>
    )
}

function Name({name, setName, disable, setUpdate}) {

    const [edit, setEdit] = useState(false);
    const [textValue, setTextValue] = useState(name);

    const resetText = () => {
        setEdit(!edit);
        setTextValue(name);
    }

    const saveEmail = () => {
        setUpdate(true);
        setName(textValue);
        setEdit(!edit);
    }

    return (
        <Box sx={{display:'flex', position:'relative', alignItems:'center'}}>
            {
                edit ?
                    <TextField size='small' variant="standard" sx={{fontSize:14, padding:0}} value={textValue} onChange={(e) => setTextValue(e.target.value)}/>
                    :
                    <Typography sx={{fontSize:14}}>{name}</Typography>
            }

            {
                !disable ?
                    !edit ?
                        <EditIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                        :
                        <Box sx={{display: 'flex', alignItems:'center'}}>
                            <CheckIcon onClick={saveEmail} sx={{fontSize:14, position: 'absolute', right:20, cursor:'pointer'}}/>
                            <ClearIcon onClick={resetText} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                        </Box>
                    :
                    ""
            }

        </Box>
    )
}

function Limit({limit, setLimit, disable, updateLimit}) {

    const [edit, setEdit] = useState(false);
    const [textValue, setTextValue] = useState(limit);

    const resetText = () => {
        setEdit(!edit);
        setTextValue(limit);
    }

    const saveEmail = () => {
        setLimit(textValue);
        setEdit(!edit);
        updateLimit(textValue);
    }

    return (
        <Box sx={{display:'flex', position:'relative', alignItems:'center'}}>
            {
                edit ?
                    <TextField size='small' variant="standard" type={'number'} sx={{fontSize:14, padding:0}} value={textValue} onChange={(e) => setTextValue(e.target.value)}/>
                    :
                    <Typography sx={{fontSize:14}}>{limit ? limit : '∞'}</Typography>
            }

            {
                !disable ?
                    !edit ?
                        <EditIcon onClick={() => setEdit(!edit)} sx={{fontSize:14, position: 'absolute', right:0, cursor:'pointer'}}/>
                        :
                        <Box sx={{display: 'flex', alignItems:'center'}}>
                            <CheckIcon onClick={saveEmail} sx={{fontSize:14, position: 'absolute', right:40, cursor:'pointer'}}/>
                            <ClearIcon onClick={resetText} sx={{fontSize:14, position: 'absolute', right:20, cursor:'pointer'}}/>
                        </Box>
                    :
                    ""
            }

        </Box>
    )
}

